import classNames from 'classnames';
import React from 'react';

import { As, AsComponent, AsProps } from './As';

type Props<T extends AsComponent> = AsProps<T> & {
  size: 'h1';
};

export const Title = React.forwardRef(
  <T extends AsComponent>({ size, className, ...props }: Props<T>, ref: React.Ref<HTMLElement>) => (
    <As
      {...{ ref }}
      className={classNames('leading-tight', { 'text-lg font-medium md:text-2xl': size === 'h1' }, className)}
      {...props}
    />
  )
);
