import { User } from '@binhatch/servicetale-api';
import { default as classNames, default as classnames } from 'classnames';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { translations } from '@/locales';

import { Button } from '@/components/Button';
import { InstanceProps, ModalHeader, ModalLayout, ModalSecondaryButton } from '@/components/Modal';

import { CarImageTile } from './CarImageTile';
import { DragAndDropFile } from './DragAndDropFile';
import { FileInput } from './FileInput';
import { FileUploadProgress } from './FileUploadProgress';

export interface Props extends InstanceProps<User, Pick<React.ComponentProps<typeof FileUploadProgress>, 'onUpload'>> {}

export const UploadModal = React.forwardRef<HTMLDivElement, Props>(
  ({ data: { onUpload }, initialFocus, className, onClose }, ref) => {
    const [files, setFiles] = React.useState<File[]>([]);

    return (
      <div {...{ ref }} className={classnames(className, 'max-w-xl')}>
        <ModalLayout>
          <ModalHeader {...{ onClose }}>
            <FormattedMessage id={translations.modals.upload.title} />
          </ModalHeader>

          <ul className="grid grid-cols-6 gap-4">
            {files.map((file, index) => (
              <li key={index}>
                <FileUploadProgress {...{ file, onUpload }}>
                  {({ value, url }) => (
                    <div className="space-y-2">
                      <div
                        className={classNames(
                          'h-1 w-full origin-left rounded-full transition-transform',
                          value === 1 ? 'bg-teal-500' : value >= 0 ? 'bg-blue-700' : 'bg-red-500'
                        )}
                        style={{ transform: `scale(${Math.max(value, 0)}, 1)` }}
                      />

                      <div className="relative aspect-square">
                        {url ? (
                          <CarImageTile className="w-full" src={url} />
                        ) : (
                          <div className="absolute inset-0 rounded-lg border border-gray-200 bg-gray-100" />
                        )}
                      </div>
                    </div>
                  )}
                </FileUploadProgress>
              </li>
            ))}
          </ul>

          <DragAndDropFile onChange={(files) => setFiles((f) => [...f, ...files])}>
            {({ dragging }) => (
              <FileInput
                accept="image/*,video/*"
                capture="environment"
                multiple
                ref={initialFocus}
                onChange={(files) => setFiles((f) => [...f, ...files])}
              >
                <div
                  className={classNames(
                    'flex h-48 cursor-pointer flex-col items-center justify-center space-y-4 rounded-lg border border-dashed text-center',
                    dragging ? 'border-[#52BFEF] bg-[#52BFEF40]' : 'hover:bg-gray-50'
                  )}
                >
                  <div className="text-sm">
                    <FormattedMessage id={translations.modals.upload.message} />
                  </div>

                  <Button appearance="primary" as="div" className="h-10 px-4">
                    <FormattedMessage id={translations.modals.upload.cta} />
                  </Button>
                </div>
              </FileInput>
            )}
          </DragAndDropFile>

          <div className="flex flex-row-reverse space-x-2">
            <ModalSecondaryButton {...{ onClose }}>
              <FormattedMessage id={translations.buttons.back} />
            </ModalSecondaryButton>
          </div>
        </ModalLayout>
      </div>
    );
  }
);
