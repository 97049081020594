import { AxiosError } from 'axios';
import React from 'react';

import { translations } from '@/locales';
import { ApiErrorResult, isApiError } from '@/utils/apiError';

import { FormattedValidationMessage } from '@/components/Form/FormattedValidationMessage';

import { CognitoError } from './CognitoError';

interface Props {
  error: Error;
  className?: string;
}

const getMessageId = (error: AxiosError<ApiErrorResult>) => {
  return error?.response?.data.key && translations.errors.api[error?.response?.data.key];
};

export const SubmitError: React.FC<Props> = ({ error, className }) => {
  if (!error) return null;

  const id = isApiError(error) ? getMessageId(error) : undefined;

  if (id) return <FormattedValidationMessage {...{ id, className }} values={{}} visible />;

  return <CognitoError {...{ error, className }} />;
};
