import React from 'react';
import { FormattedMessage } from 'react-intl';

import { translations } from '@/locales';

import { Loading, LoadingIndicator } from '@/components/Loading';
import { Title } from '@/components/Title';

interface Props {
  loading?: boolean;
}

export const WorkHeading: React.FC<React.PropsWithChildren<Props>> = ({ loading, children }) => {
  return (
    <div className="sticky top-20 z-10 -mt-2 flex items-center justify-between border-b border-gray-200 bg-[#F2F2F2] py-2 md:top-0">
      <header className="flex h-10 items-center gap-4">
        <Title as="h1" size="h1">
          <FormattedMessage id={translations.pages.workDetail.title} />
        </Title>

        <Loading visible={!!loading}>
          <LoadingIndicator className="h-5 w-5" />
        </Loading>
      </header>

      {children}
    </div>
  );
};
