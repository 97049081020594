import classNames from 'classnames';
import React from 'react';

import { Avatar } from './Avatar';

interface Props extends React.ComponentProps<typeof Avatar> {}

export const BlueAvatar: React.FC<Props> = ({ className, ...props }) => {
  return (
    <Avatar
      {...props}
      className={classNames('text flex items-center justify-center bg-[#52BFEF] text-white', className)}
    />
  );
};
