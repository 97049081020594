import { pushToDataLayer } from '@/integrations/hubspot';

import { EventProperties, EventType } from '../event-properties';
import { Analytics } from '../types';
import { AnalyticsUser } from '../user';
import { UserTrackerCallback } from '../user-tracker';

import { HubSpottUserTracker } from './hubspot-user-tracker';

export class HubSpotAnalytics implements Analytics {
  private tracker?: HubSpottUserTracker;

  constructor() {}

  trackPage(path: string): void {
    pushToDataLayer('setPath', path);
    pushToDataLayer('trackPageView');
  }

  trackEvent<E extends EventType>(type: E, event: EventProperties[E]): void {
    pushToDataLayer(type, event);
  }

  trackUser(callback: UserTrackerCallback) {
    if (!this.tracker) return;

    pushToDataLayer('identify', callback(this.tracker).toIdentify());
  }

  setCurrentUser(user?: AnalyticsUser): void {
    this.tracker = user ? new HubSpottUserTracker(user) : undefined;
    pushToDataLayer('identify', this.tracker?.toIdentify());
  }
}
