import classNames from 'classnames';
import React from 'react';

import { Loading, LoadingIndicator } from '@/components/Loading';

interface Props {
  loading: boolean;
}

export const LoadingState: React.FC<React.PropsWithChildren<Props>> = ({ loading, children }) => (
  <div className={classNames('relative transition-opacity', loading ? 'opacity-50' : 'opacity-100')}>
    <Loading className="absolute inset-0 flex items-center justify-center" visible={loading}>
      <LoadingIndicator className="h-6 w-6" />
    </Loading>

    {children}
  </div>
);
