import React from 'react';
import { useIntl } from 'react-intl';

import { ValidationMessage } from './ValidationMessage';

export interface Props extends React.ComponentProps<typeof ValidationMessage> {
  id: string;
  values: any;
}

const valueMapper: Record<string, Function> = {
  // 'validation.basic.date.min': (values) => ({ ...values, value: 'value', min: 'min' }),
  // 'validation.basic.date.max': (values) => ({ ...values, value: 'value', max: 'max' })
};

const capitalize = (value: string) => {
  return value[0].toUpperCase() + value.slice(1);
};

export const FormattedValidationMessage: React.FC<React.PropsWithChildren<Props>> = ({ id, values, ...props }) => {
  const intl = useIntl();

  const formattedValues = values
    ? { ...values, path: intl.formatMessage({ id: values.label || id }).toLowerCase() }
    : {};

  const message = intl.formatMessage({ id }, valueMapper[id]?.(formattedValues) ?? formattedValues);

  return <ValidationMessage {...props}>{!!id && capitalize(message)}</ValidationMessage>;
};
