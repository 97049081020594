import { UsersResponse } from '@binhatch/servicetale-api';

import { workshopApi } from '@/integrations/api';
import { PaginatedOptions } from './pagination';

export const findClients = async ({
  workshopId,
  clientIds,
  search,
  token
}: { workshopId?: string; clientIds?: string[]; search?: string } & PaginatedOptions): Promise<UsersResponse> => {
  if (!workshopId) throw new Error(`The workshop is missing.`);

  const result = await workshopApi.listWorkshopUsers(workshopId, token, search, clientIds && clientIds.join(','));

  return result.data;
};
