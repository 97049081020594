import classNames from 'classnames';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { translations } from '@/locales';

import { Price } from './Price';

interface Props extends React.ComponentProps<typeof Price> {
  className?: string;
}

export const PriceWithVAT: React.FC<Props> = ({ className, ...props }) => (
  <div className={classNames('text-right', className)}>
    <FormattedMessage
      id={translations.components.priceWithVAT}
      values={{
        price: (
          <span className="font-medium">
            <Price {...props} />
          </span>
        ),
        small: (children) => <div className="text-sm text-gray-400">{children}</div>
      }}
    />
  </div>
);
