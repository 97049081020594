import classNames from 'classnames';
import React from 'react';

interface Props {
  first?: boolean;
}

export const OfferTabPanel: React.FC<React.PropsWithChildren<Props>> = ({ first, ...props }) => (
  <div
    {...props}
    className={classNames('border-4 border-[#D6F2FF] p-2', first ? 'rounded-b-lg rounded-tr-lg' : 'rounded-lg')}
  />
);
