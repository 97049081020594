import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Navigate } from 'react-router';

import { translations } from '@/locales';

import { useSubscription } from '@/hooks/useSubscription';

import { Loading, LoadingIndicator, LoadingMessage } from '@/components/Loading';
import { QueryParamConfig, decodeSingleQueryParam, useQueryParams } from '@/hooks/useQueryParams';

const redirect: QueryParamConfig<string> = {
  decode: (value) => decodeSingleQueryParam(value, '/'),
  encode: (value) => (value ? value : undefined)
};

const config = { redirect };

export const WorkshopSubscriptionConfirmationPage: React.FC = () => {
  const [{ redirect }] = useQueryParams({ config });
  const { data } = useSubscription({ revalidateOnFocus: true, refreshInterval: 1000 });

  if (data) return <Navigate replace to={redirect} />;

  return (
    <div className="flex flex-1 items-center justify-center">
      <Loading visible={true}>
        <LoadingMessage>
          <LoadingIndicator className="h-5 w-5" />

          <div>
            <FormattedMessage id={translations.pages.workshop.subscription.confirmation.loading} />
          </div>
        </LoadingMessage>
      </Loading>
    </div>
  );
};
