import classNames from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';

import logo from './logo.svg';

interface Props {
  size: 'small' | 'large';
  className?: string;
}

export const Logo: React.FC<Props> = ({ size, className }) => (
  <Link
    aria-label="Navigate to the main page"
    className={classNames('block h-10 uppercase', { 'w-48': size === 'small', 'w-52': size === 'large' }, className)}
    to="/"
  >
    <img alt="The logo of Service Tale" src={logo} />
  </Link>
);
