import { Car } from '@binhatch/servicetale-api';
import classnames from 'classnames';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import useMutation from 'swr/mutation';
import * as yup from 'yup';

import { carApi, vinApi } from '@/integrations/api';
import { translations } from '@/locales';

import { useCar } from '@/hooks/useCar';

import { Form } from '@/components/Form';
import { InputWithLabel } from '@/components/Form/InputWithLabel';
import { ValidatedField } from '@/components/Form/ValidatedField';
import { Loading, LoadingIndicator } from '@/components/Loading';
import { InstanceProps, ModalHeader, ModalLayout, ModalPrimaryButton, ModalSecondaryButton } from '@/components/Modal';
import { SubmitError } from '@/components/SubmitError';

export interface Props extends InstanceProps<Car, { clientId: string; carId?: string }> {}

export const schema = yup
  .object({
    vin: yup.string().length(17).uppercase().required().label(translations.fields.vin.label),
    make: yup.string().uppercase().required().label(translations.fields.make.label),
    model: yup.string().uppercase().required().label(translations.fields.model.label),
    year: yup.number().min(1981).required().max(new Date().getFullYear()).label(translations.fields.year.label),
    registrationNumber: yup.string().uppercase().required().label(translations.fields.license.label)
  })
  .required();

export const UpdateCarModal = React.forwardRef<HTMLDivElement, Props>(
  ({ data: { clientId, carId }, initialFocus, className, onAction, onClose }, ref) => {
    const intl = useIntl();

    const car = useCar({ clientId, carId });

    const vin = useMutation('verifyVin', (_: string, { arg: { vin } }: { arg: { vin: string } }) =>
      vinApi.getCarInfoByVIN(vin.toUpperCase()).then((r) => r.data)
    );

    const initialValues = React.useMemo<yup.InferType<typeof schema>>(
      () => ({
        ...vin.data,
        ...car.data,
        vin: vin.data?.vin ?? car.data?.vin ?? '',
        make: vin.data?.make ?? car.data?.make ?? '',
        model: vin.data?.model ?? car.data?.model ?? '',
        year: (vin.data?.year ?? car.data?.year) as number,
        registrationNumber: car.data?.registrationNumber ?? ''
      }),
      [car.data, vin]
    );

    const onSubmit = React.useCallback(
      async (values: yup.InferType<typeof schema>) => {
        const car = await carApi.createCar({ owner: clientId, ...values }).then((r) => r.data);

        return onAction(car);
      },
      [clientId, onAction]
    );

    return (
      <div {...{ ref }} className={classnames(className, 'max-w-sm')}>
        <ModalLayout>
          <ModalHeader {...{ onClose }}>
            <FormattedMessage id={carId ? translations.modals.updateCar.title : translations.modals.createCar.title} />
          </ModalHeader>

          <Form {...{ schema, initialValues, onSubmit }}>
            {({ submitting, submitError, handleSubmit }) => (
              <form className="m-0 grid gap-4" onSubmit={handleSubmit}>
                <div className="relative">
                  <ValidatedField
                    disabled={!!car.data}
                    field={InputWithLabel}
                    id="vin"
                    inputClassName="pr-10"
                    label={<FormattedMessage id={translations.fields.vin.label} />}
                    maxLength="17"
                    name="vin"
                    parse={(value?: string) => (value ? value.toUpperCase() : value)}
                    placeholder={intl.formatMessage({ id: translations.fields.vin.placeholder })}
                    readOnly={!!submitting}
                    ref={initialFocus}
                    type="text"
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      if (event.target.value?.length !== 17) return;
                      vin.trigger({ vin: event.target.value }).catch(() => null);
                    }}
                  />

                  <Loading
                    className="absolute top-6 right-0 flex h-10 w-10 items-center justify-center"
                    visible={vin.isMutating}
                  >
                    <LoadingIndicator className="h-5 w-5" />
                  </Loading>
                </div>

                <ValidatedField
                  disabled={!!car.data?.make || vin.data?.make}
                  field={InputWithLabel}
                  id="make"
                  label={<FormattedMessage id={translations.fields.make.label} />}
                  name="make"
                  parse={(value?: string) => (value ? value.toUpperCase() : value)}
                  placeholder={intl.formatMessage({ id: translations.fields.make.placeholder })}
                  readOnly={!!submitting}
                  type="text"
                />

                <ValidatedField
                  disabled={!!car.data?.model || vin.data?.model}
                  field={InputWithLabel}
                  id="model"
                  label={<FormattedMessage id={translations.fields.model.label} />}
                  name="model"
                  parse={(value?: string) => (value ? value.toUpperCase() : value)}
                  placeholder={intl.formatMessage({ id: translations.fields.model.placeholder })}
                  readOnly={!!submitting}
                  type="text"
                />

                <ValidatedField
                  description={<FormattedMessage id={translations.fields.optional} />}
                  disabled={!!car.data?.year || vin.data?.year}
                  field={InputWithLabel}
                  id="year"
                  label={<FormattedMessage id={translations.fields.year.label} />}
                  name="year"
                  placeholder={intl.formatMessage({ id: translations.fields.year.placeholder })}
                  readOnly={!!submitting}
                />

                <ValidatedField
                  description={<FormattedMessage id={translations.fields.optional} />}
                  disabled={!!car.data?.registrationNumber || vin.data?.registrationNumber}
                  field={InputWithLabel}
                  id="registrationNumber"
                  label={<FormattedMessage id={translations.fields.license.label} />}
                  name="registrationNumber"
                  parse={(value?: string) => (value ? value.toUpperCase().replace(/[^A-Z0-9]/, '') : value)}
                  placeholder={intl.formatMessage({ id: translations.fields.license.placeholder })}
                  readOnly={!!submitting}
                />

                <SubmitError error={submitError} />

                <div className="flex flex-row-reverse space-x-2">
                  <ModalPrimaryButton onAction={() => handleSubmit()}>
                    <FormattedMessage id={carId ? translations.buttons.edit : translations.buttons.add} />
                  </ModalPrimaryButton>

                  <ModalSecondaryButton {...{ onClose }}>
                    <FormattedMessage id={translations.buttons.back} />
                  </ModalSecondaryButton>
                </div>
              </form>
            )}
          </Form>
        </ModalLayout>
      </div>
    );
  }
);
