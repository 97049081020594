import { User } from '@binhatch/servicetale-api';
import classNames from 'classnames';
import React from 'react';

import { NicknameAvatar } from './NicknameAvatar';
import { Skeleton } from './Skeleton';

interface Props {
  client?: User;
  className?: string;
}

export const ClientIdentifier: React.FC<Props> = ({ client, className }) => {
  const name = client ? [client.firstName, client.lastName].filter(Boolean).join(' ') : undefined;

  return (
    <div className={classNames('flex items-center', className)}>
      <NicknameAvatar className="mr-4 shrink-0" {...{ name }} />

      <div>
        <div className="font-medium">
          <Skeleton className="mb-2 w-32" size="h-5" visible={!client}>
            {name}
          </Skeleton>
        </div>

        <div className="text-sm">
          <Skeleton className="w-20" visible={!client}>
            {client?.email}
          </Skeleton>
        </div>
      </div>
    </div>
  );
};
