import React from 'react';
import { createContainer } from 'unstated-next';

import { analytics } from '@/analytics';
import { workshopApi } from '@/integrations/api';

import { useRemoteData } from '@/hooks/useRemoteData';
import { useWorkshopId } from '@/hooks/useWorkshopId';

export const useWorkshop = () => {
  const workshopId = useWorkshopId();

  const workshop = useRemoteData(
    { key: 'getWorkshop', workshopId, skip: !workshopId },
    ({ workshopId }) => workshopApi.getWorkshopByID(workshopId!).then((r) => r.data),
    { revalidateOnFocus: false, shouldRetryOnError: false }
  );

  React.useEffect(() => {
    const data = workshop.data;

    if (!data) return;

    analytics.trackUser((tracker) => tracker.set('tenantId', data.id));
  }, [workshop]);

  return React.useMemo(
    () => ({
      loading: workshop.isLoading,
      workshop: workshop.data,
      reload: () => workshop.mutate()
    }),
    [workshop]
  );
};

export const Workshop = createContainer(useWorkshop);
