import { ChapterKind, ChapterState, TaleStatus } from '@binhatch/servicetale-api';
import React from 'react';

import { useTale } from '@/hooks/useTale';

import { Modal, useModal } from '@/components/Modal';

import { SelectedChapter } from './useSelectedChapter';

import { CanceledForm } from './CanceledForm';
import { CarReceptionForm } from './CarReceptionForm';
import { CompleteForm } from './CompleteForm';
import { ConfirmCompleteModal } from './ConfirmCompleteModal';
import { HandoverForm } from './HandoverForm';
import { InspectionForm } from './InspectionForm';
import { OfferForm } from './OfferForm';
import { RejectedForm } from './RejectedForm';
import { WorkForm } from './WorkForm';

interface Props {
  taleId: string;
  address?: string;
  status?: TaleStatus;
  onReload(): Promise<void>;
}

export const WorkSelectedChapter: React.FC<React.PropsWithChildren<Props>> = ({
  taleId,
  address,
  status,
  children,
  onReload
}) => {
  const { selected: chapter, current } = SelectedChapter.useContainer();

  const tale = useTale({ taleId });

  const confirmModal = useModal(ConfirmCompleteModal);

  return (
    <React.Fragment>
      {chapter === 'canceled' ? (
        <CanceledForm {...{ taleId }} reason={tale.data?.closeReason}>
          {children}
        </CanceledForm>
      ) : chapter === 'completed' ? (
        <CompleteForm>{children}</CompleteForm>
      ) : chapter === 'refused' ? (
        <RejectedForm {...{ taleId }}>{children}</RejectedForm>
      ) : typeof chapter === 'object' ? (
        <React.Fragment>
          {chapter?.kind === ChapterKind.CarReception ? (
            <CarReceptionForm
              {...{ taleId, chapter }}
              readOnly={status !== TaleStatus.InProgress || chapter?.state !== ChapterState.Pending}
            >
              {children}
            </CarReceptionForm>
          ) : chapter?.kind === ChapterKind.Inspection ? (
            <InspectionForm
              {...{ taleId, chapter }}
              readOnly={status !== TaleStatus.InProgress || chapter?.state !== ChapterState.Pending}
            >
              {children}
            </InspectionForm>
          ) : chapter?.kind === ChapterKind.Offer ? (
            <OfferForm
              {...{ taleId, chapter, onReload }}
              canProceed={typeof current === 'object' && chapter.id === current.id}
              readOnly={status !== TaleStatus.InProgress || chapter?.state !== ChapterState.Pending}
            >
              {children}
            </OfferForm>
          ) : chapter?.kind === ChapterKind.Work ? (
            <WorkForm
              {...{ taleId, chapter, onReload }}
              readOnly={status !== TaleStatus.InProgress || chapter?.state !== ChapterState.Pending}
            >
              {children}
            </WorkForm>
          ) : chapter?.kind === ChapterKind.Handover ? (
            <HandoverForm
              {...{ taleId, address, chapter, onReload }}
              readOnly={status !== TaleStatus.InProgress || chapter?.state !== ChapterState.Pending}
            >
              {children}
            </HandoverForm>
          ) : null}
        </React.Fragment>
      ) : null}

      <Modal {...confirmModal.props} />
    </React.Fragment>
  );
};
