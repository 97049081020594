import React from 'react';
import { useParams } from 'react-router';

import { taleApi } from '@/integrations/api';

import { useRemoteData } from '@/hooks/useRemoteData';

import { CarIdentifier } from '@/components/CarIdentifier';
import { Container } from '@/components/Container';
import { Timeline } from '@/components/Timeline';

import { Logo } from '@/components/Logo';
import { Card } from './WorkDetailPage/Card';
import { WorkSelectedChapter } from './WorkDetailPage/WorkSelectedChapter';
import { SelectedChapter } from './WorkDetailPage/useSelectedChapter';

export const WorkPreviewPage: React.FC = () => {
  const { taleId } = useParams();

  const tale = useRemoteData({ key: 'getWorkByPreviewId', taleId, skip: !taleId }, async ({ taleId }) =>
    taleApi.getTalePreviewByPublicID(taleId!).then((r) => r.data)
  );

  if (!taleId) throw new Error(`No tale id is provided.`);

  const address = [
    tale.data?.workshop.address?.addressLine1,
    tale.data?.workshop.address?.addressLine2,
    tale.data?.workshop.address?.city,
    tale.data?.workshop.address?.state,
    tale.data?.workshop.address?.country,
    tale.data?.workshop.address?.postalCode
  ]
    .filter(Boolean)
    .join(', ');

  return (
    <div className="flex w-full flex-1 flex-col bg-[#F2F2F2]">
      <header className="sticky top-0 z-10 flex h-20 items-center justify-center bg-white py-2">
        <Logo size="large" />
      </header>

      <SelectedChapter.Provider
        initialState={{
          incomplete: tale.data?.incomplete,
          completed: tale?.data?.completed,
          status: tale.data?.status
        }}
      >
        <React.Fragment>
          <div className="flex w-full flex-col gap-4 p-4 md:flex-row md:gap-8 md:p-8">
            {!!tale.data && (
              <div className="flex flex-col gap-4 md:gap-8">
                <Card className="flex items-center justify-between">
                  <CarIdentifier car={tale.data?.car} className="w-full max-w-sm" />
                </Card>

                <Timeline
                  completed={tale.data?.completed}
                  incompleted={tale.data?.incomplete}
                  status={tale.data?.status}
                />
              </div>
            )}

            <Container className="flex-1 space-y-6">
              <WorkSelectedChapter
                {...{ taleId, address }}
                onReload={async () => {
                  await tale.mutate();
                }}
              />
            </Container>
          </div>
        </React.Fragment>
      </SelectedChapter.Provider>
    </div>
  );
};
