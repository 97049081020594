import React from 'react';

import { UploadFileOptions, getPercentFromProgressEvent } from '@/utils/uploadFile';

type ProgressProps = { value: number; url?: string; error?: Error };

interface OnUploadOptions extends Pick<UploadFileOptions, 'onUploadProgress'> {
  file: File;
}

interface Props {
  file: File;
  onUpload(o: OnUploadOptions): Promise<string>;
  children(props: ProgressProps): React.ReactElement;
}

export const FileUploadProgress: React.FC<Props> = ({ file, children, onUpload }) => {
  const [progress, setProgress] = React.useState<ProgressProps>({ value: 0 });

  React.useEffect(() => {
    const onUploadProgress: UploadFileOptions['onUploadProgress'] = (event) =>
      setProgress((p) => ({ ...p, error: undefined, value: getPercentFromProgressEvent(event) }));

    onUpload({ file, onUploadProgress })
      .then((url) => setProgress((p) => ({ ...p, error: undefined, value: 1, url })))
      .catch((error) => setProgress((p) => ({ ...p, error, value: 1 })));
  }, [file, onUpload]);

  return children(progress);
};
