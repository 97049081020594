import { useWorkshopId } from '@/hooks/useWorkshopId';
import { Chapter, ChapterState, TaleStatus } from '@binhatch/servicetale-api';
import React from 'react';
import { useLocation, useNavigate } from 'react-router';
import { createContainer } from 'unstated-next';

interface UseChapterOptions {
  incomplete?: Chapter[];
  completed?: Chapter[];
  status?: TaleStatus;
}

const isStatus = (status?: unknown): status is TaleStatus => Object.values(TaleStatus).includes(status as TaleStatus);

const useSelectedChapter = ({ incomplete, completed, status }: UseChapterOptions = {}) => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const workshopId = useWorkshopId();

  const chapters = React.useMemo(() => [...(completed ?? []), ...(incomplete ?? [])], [completed, incomplete]);
  const currentId =
    status !== TaleStatus.InProgress
      ? status
      : (workshopId
          ? incomplete?.[0]?.id
          : chapters
              .slice()
              .reverse()
              .find((c) =>
                ([ChapterState.Final, ChapterState.WaitingUserIntervention] as ChapterState[]).includes(c.state)
              )?.id) ?? status;

  const selectedId = state?.chapterId;

  return React.useMemo(() => {
    const getValueFromId = (id: string) => (isStatus(id) ? id : chapters?.find((c) => c.id === id));
    const getIdFromValue = (value: Chapter | TaleStatus) => (isStatus(value) ? value : value.id);

    const current = currentId && getValueFromId(currentId);
    const selected = getValueFromId(selectedId) ?? current;

    return {
      current,
      selected,
      select: (chapter: Chapter | TaleStatus) =>
        navigate(
          {},
          {
            state: {
              chapterId:
                current && getIdFromValue(chapter) === getIdFromValue(current) ? undefined : getIdFromValue(chapter)
            }
          }
        )
    };
  }, [chapters, currentId, selectedId, navigate]);
};

export const SelectedChapter = createContainer(useSelectedChapter);
