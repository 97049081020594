import React, { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { IntlProvider } from 'react-intl';
import { BrowserRouter } from 'react-router-dom';

import 'tailwindcss/tailwind.css';

import { Auth } from '@/containers/useAuth';
import { getTranslationMessages, Language } from '@/locales';

import { AnalyticsTracker } from '@/components/AnalyticsTracker';

import { App } from './App';

const root = createRoot(document.getElementById('app')!);

root.render(
  <StrictMode>
    <IntlProvider
      defaultRichTextElements={{ br: (<br />) as any }}
      locale={Language.Romanian}
      messages={getTranslationMessages(Language.Romanian)}
    >
      <BrowserRouter>
        <Auth.Provider>
          <AnalyticsTracker />
          <App />
        </Auth.Provider>
      </BrowserRouter>
    </IntlProvider>
  </StrictMode>
);
