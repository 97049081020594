import { Time } from '@binhatch/servicetale-api';
import classNames from 'classnames';
import React from 'react';

import { Price } from '@/components/Price';

interface Props {
  time: Time;
  index: number;
  className?: string;
}

export const TimeItem: React.FC<Props> = ({ time, index, className }) => (
  <div className={classNames('flex gap-2', className)}>
    <div className="h-6 w-6 rounded bg-[#F2F2F2] text-center text-sm leading-6">{index + 1}</div>

    <div className="flex-1">{time.name}</div>

    <div>{time.quantity}</div>

    <div>x</div>

    <div className="w-32 text-right">
      <Price amount={time.rate} currency="RON" />
    </div>
  </div>
);
