import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import * as yup from 'yup';

import { Container } from '@/components/Container';
import { Form } from '@/components/Form';
import { InputWithLabel } from '@/components/Form/InputWithLabel';
import { ValidatedField } from '@/components/Form/ValidatedField';
import { LoadingButton } from '@/components/LoadingButton';
import { PageHeading } from '@/components/PageHeading';
import { SubmitError } from '@/components/SubmitError';
import { useWorkshopId } from '@/hooks/useWorkshopId';
import { workshopApi } from '@/integrations/api';
import { translations } from '@/locales';
import { CheckCircleIcon } from '@heroicons/react/24/outline';
import { Card } from './WorkDetailPage/Card';

const schema = yup
  .object({
    promoCode: yup.string().required().label(translations.fields.promoCode.label)
  })
  .required();

export const WorkshopSettingsPage: React.FC = () => {
  const workshopId = useWorkshopId();
  const intl = useIntl();

  const onSubmit = React.useCallback(
    async (values: yup.InferType<typeof schema>) => {
      if (!workshopId) throw new Error(`Workshop not found.`);
      await workshopApi.redeemCode(workshopId, { code: values.promoCode });
    },
    [workshopId]
  );

  return (
    <Container className="space-y-6">
      <PageHeading title={<FormattedMessage id={translations.navigation.settings.service} />} />

      <Form {...{ schema, onSubmit }}>
        {({ submitting, submitError, submitSucceeded, handleSubmit }) => (
          <Card className="max-w-md">
            <form className="m-0 flex flex-col flex-wrap gap-4 md:flex-row" onSubmit={handleSubmit}>
              <ValidatedField
                className="flex-1"
                field={InputWithLabel}
                id="promoCode"
                label={<FormattedMessage id={translations.fields.promoCode.label} />}
                name="promoCode"
                placeholder={intl.formatMessage({ id: translations.fields.promoCode.placeholder })}
                readOnly={submitting || submitSucceeded}
                type="text"
              />

              <SubmitError className="order-last" error={submitError} />

              {!!submitSucceeded && (
                <div className="order-last flex flex-1 items-center rounded bg-teal-100 p-2 text-sm text-teal-700">
                  <CheckCircleIcon className="mr-2 h-5 w-5" />
                  <FormattedMessage id={translations.pages.workshop.settings.success} />
                </div>
              )}

              <LoadingButton
                appearance="primary"
                className="h-10 px-4 md:mt-6"
                disabled={submitSucceeded}
                loading={submitting}
                type="submit"
              >
                <FormattedMessage id={translations.pages.workshop.settings.addPromoCode} />
              </LoadingButton>
            </form>
          </Card>
        )}
      </Form>
    </Container>
  );
};
