import React from 'react';
import { useLocation } from 'react-router';

import { analytics } from '@/analytics';

import { Auth } from '@/containers/useAuth';

export const AnalyticsTracker: React.FC = () => {
  const { user } = Auth.useContainer();
  const { pathname, search } = useLocation();

  // Track user
  React.useEffect(() => {
    if (!user) return analytics.setCurrentUser();

    const { id, email, firstName, lastName } = user;

    return analytics.setCurrentUser({ id, email, firstName, lastName });
  }, [user]);

  // Track page views upon navigation
  React.useEffect(() => {
    analytics.trackPage(`${pathname}${search}`);
  }, [pathname, search]);

  return null;
};
