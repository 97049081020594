import { User } from '@binhatch/servicetale-api';
import { PlusSmallIcon } from '@heroicons/react/24/outline';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { translations } from '@/locales';

import { useClients } from '@/hooks/useClients';
import { QueryParamConfig, decodeSingleQueryParam, useQueryParams } from '@/hooks/useQueryParams';

import { Button } from '@/components/Button';
import { ClientIdentifier } from '@/components/ClientIdentifier';
import { Container } from '@/components/Container';
import { SearchInput } from '@/components/Form/SearchInput';
import { PageLoaderHeading } from '@/components/PageLoaderHeading';
import { Pagination } from '@/components/Pagination';

const search: QueryParamConfig<string | undefined> = {
  decode: (value) => decodeSingleQueryParam(value, undefined),
  encode: (value) => (value ? value : undefined)
};

const page: QueryParamConfig<string | undefined> = {
  decode: (value) => decodeSingleQueryParam(value, undefined),
  encode: (value) => value
};

const config = { search, page };

interface Props {
  onCreate?(): Promise<unknown>;
  onSelect?(client: User): Promise<unknown>;
}

export const ClientList: React.FC<Props> = ({ onCreate, onSelect }) => {
  const intl = useIntl();
  const [query, updateQuery] = useQueryParams({ config });

  const items = useClients({ search: query.search, token: query.page });

  return (
    <Container className="space-y-6">
      <PageLoaderHeading loading={items.isLoading || items.isValidating}>
        <FormattedMessage id={translations.navigation.clients} />
      </PageLoaderHeading>

      <div className="flex items-center justify-between space-x-4">
        <SearchInput
          className="w-full md:w-72"
          placeholder={intl.formatMessage({ id: translations.pages.clientList.search })}
          value={query.search}
          onChange={(search: string) => updateQuery({ search })}
        />

        <Button
          appearance="primary"
          className="h-10 space-x-2 px-3 md:px-8"
          onClick={() => onCreate?.().then(() => items.mutate())}
        >
          <PlusSmallIcon className="h-5 w-5" />
          <div className="hidden md:block">
            <FormattedMessage id={translations.pages.clientList.cta} />
          </div>
        </Button>
      </div>

      <ul className="rounded-lg bg-white">
        {items.data?.users.map((item) => (
          <li className="group" key={item.id}>
            <button
              className="relative flex w-full items-center space-x-6 p-6 text-left hover:bg-[#F0FAFF] group-first:rounded-t-lg group-last:rounded-b-lg"
              onClick={() => onSelect?.(item).then(() => items.mutate())}
            >
              <ClientIdentifier client={item} />
            </button>
          </li>
        ))}

        {!items.data?.users.length && (
          <li className="p-6">
            <FormattedMessage id={translations.pages.clientList.empty} />
          </li>
        )}
      </ul>

      <Pagination
        hasNext={!!items.data?.nextToken}
        // hasPrevious={!!items.data?.previousToken}
        onNext={() => updateQuery({ page: items.data?.nextToken })}
        // onPrevious={() => updateQuery({ page: items.data?.previousToken })}
      />
    </Container>
  );
};
