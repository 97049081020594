import { delay } from './delay';
import { fuzzySearch } from './fuzzySearch';

export const operations: string[] = [
  'Inspecție generală',
  'Schimb de ulei și filtre',
  'Sistemul de frânare',
  'Verificat/completat nivel lichide',
  'Igienizare climă',
  'Diagnoză',
  'Încărcare freon',
  'I.T.P'
];

export const findOperations = async ({ search, page }: { search?: string; page: number }) => {
  await delay(100);

  const items = search ? fuzzySearch(operations, (item) => [item], search) : operations;

  return { data: items.slice((page - 1) * 10, page * 10), page: { total: items.length } };
};
