import React from 'react';

import { findOperations } from '@/data/operation';
import { useRemoteData } from '@/hooks/useRemoteData';
import classNames from 'classnames';
import { UseComboboxState, UseComboboxStateChangeOptions, useCombobox } from 'downshift';
import { Loading, LoadingIndicator } from '../Loading';
import { Props as InputProps } from './Input';
import { Item, Select } from './Select';

export type Props = Omit<InputProps<'input'>, 'value' | 'onChange'> & {
  id?: string;
  value?: string;
  onChange?(value?: string): void;
};

const enterId = -1;

const getItemFromName = (item?: string | Item<number>): Item<number> | undefined => {
  if (!item) return;
  if (typeof item === 'string') return { value: enterId, name: item };
  return item;
};

export const WorkItemSelect = React.forwardRef(function WorkItemSelect(
  //@ts-ignore
  { value, className, onChange, ...props }: Props,
  ref: React.Ref<HTMLInputElement>
) {
  const data = useRemoteData({ key: 'useSearchWorkItem', value }, ({ value }) =>
    findOperations({ search: value, page: 1 })
  );

  const items = React.useMemo(() => data.data?.data.map((item) => ({ value: item, name: item })) ?? [], [data]);

  const stateReducer = React.useCallback(
    (state: UseComboboxState<Item<number>>, { type, changes }: UseComboboxStateChangeOptions<Item<number>>) => {
      const selectedItem = getItemFromName(changes.selectedItem ?? state.inputValue);

      const inputValue = state.inputValue ?? '';

      switch (type) {
        case useCombobox.stateChangeTypes.InputChange:
          return changes;
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
          return { ...changes, isOpen: false, inputValue, selectedItem };
        case useCombobox.stateChangeTypes.InputBlur:
          return { ...changes, isOpen: false, inputValue, selectedItem };
        default:
          return { ...changes, inputValue };
      }
    },
    []
  );

  return (
    <div className={classNames('relative', className)}>
      <Select
        {...props}
        {...{ ref, value, stateReducer, items }}
        className="pr-14"
        getInputValue={(_, inputValue) => inputValue}
        getValue={(item) => item.name}
        inputValue={value}
        onChange={(item?: string) => onChange?.(item)}
        onInputValueChange={onChange}
      />

      <Loading className="absolute right-9 top-2.5" visible={data.isLoading || data.isValidating}>
        <LoadingIndicator className="h-5 w-5" />
      </Loading>
    </div>
  );
});
