import { XMarkIcon } from '@heroicons/react/20/solid';
import { PhotoIcon, PlayIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import React from 'react';

import { Image, isImage } from './Image';
import { VideoThumbnail } from './VideoThumbnail';

interface Props {
  src: string;
  className?: string;
  onRemove?(): void;
}

export const CarImageTile: React.FC<Props> = ({ src, className, onRemove }) => {
  const image = React.useMemo(() => isImage(src), [src]);

  return (
    <div className={classNames('group relative', className)}>
      <a className="relative block overflow-hidden rounded-lg" href={src} rel="noreferrer" target="_blank">
        <div className="relative aspect-square">
          {image ? <Image {...{ src }} quality={80} width={200} /> : <VideoThumbnail {...{ src }} />}

          <div className="absolute inset-0 bg-gray-900/50" />

          <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-white">
            {image ? <PhotoIcon className="h-10 w-10" /> : <PlayIcon className="h-10 w-10" />}
          </div>
        </div>
      </a>

      {!!onRemove && (
        <button
          className="absolute top-0 right-0 hidden h-10 w-10 items-center justify-center text-white group-hover:flex"
          type="button"
          onClick={onRemove}
        >
          <XMarkIcon className="h-6 w-6 drop-shadow-[0px_1px_0px_black]" />
        </button>
      )}
    </div>
  );
};
