import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Navigate, Route, Routes } from 'react-router-dom';

import { translations } from '@/locales';
import * as urls from '@/utils/url';

import { SideNavigation } from '@/containers/useSideNavigation';
import { Workshop } from '@/containers/useWorkshop';

import { Loading, LoadingIndicator } from '@/components/Loading';
import { Navigation } from '@/components/Navigation';

import { ClientListPage } from '@/pages/ClientListPage';
import { HelpPage } from '@/pages/HelpPage';
import { SkeletonPage } from '@/pages/SkeletonPage';
import { WorkDetailPage } from '@/pages/WorkDetailPage';
import { CreateWorkPage } from '@/pages/WorkDetailPage/CreateWorkPage';
import { WorkListPage } from '@/pages/WorkListPage';
import { WorkPreviewPage } from '@/pages/WorkPreviewPage';
import { WorkshopSettingsPage } from '@/pages/WorkshopSettingsPage';
import { WorkshopSubscriptionConfirmationPage } from '@/pages/WorkshopSubscriptionConfirmationPage';
import { WorkshopSubscriptionPage } from '@/pages/WorkshopSubscriptionPage';

export const WorkshopApp = React.memo(() => {
  const { workshop, loading } = Workshop.useContainer();

  if (!workshop || loading)
    return (
      <div className="flex flex-1 flex-col items-center justify-center">
        <Loading visible>
          <LoadingIndicator className="h-5 w-5" />
        </Loading>
      </div>
    );

  return (
    <Routes>
      <Route
        element={
          <div className="flex flex-1 flex-col lg:flex-row">
            <SideNavigation.Provider>
              <Navigation />

              <div className="flex flex-1 flex-col bg-[#F2F2F2] p-4 md:px-12 md:py-6">
                <Routes>
                  <Route
                    element={
                      <SkeletonPage>
                        <FormattedMessage id={translations.navigation.dashboard} />
                      </SkeletonPage>
                    }
                    path={urls.dashboard}
                  />
                  <Route
                    element={
                      <SkeletonPage>
                        <FormattedMessage id={translations.navigation.appointments} />
                      </SkeletonPage>
                    }
                    path={urls.appointments}
                  />

                  <Route element={<WorkListPage />} path={urls.work.root} />
                  <Route element={<CreateWorkPage />} path={urls.work.new} />
                  <Route element={<WorkDetailPage />} path={urls.work.one} />
                  <Route element={<WorkPreviewPage />} path={urls.work.preview} />

                  <Route element={<ClientListPage />} path={urls.clients.root} />

                  <Route element={<WorkshopSettingsPage />} path={urls.settings.service} />
                  <Route
                    element={
                      <SkeletonPage>
                        <FormattedMessage id={translations.navigation.settings.application} />
                      </SkeletonPage>
                    }
                    path={urls.settings.application}
                  />
                  <Route element={<HelpPage />} path={urls.help} />

                  <Route element={<Navigate replace to={urls.work.root} />} path="*" />
                </Routes>
              </div>
            </SideNavigation.Provider>
          </div>
        }
        path="*"
      />

      <Route element={<WorkshopSubscriptionPage />} path={urls.workshop.subscription.root} />
      <Route element={<WorkshopSubscriptionConfirmationPage />} path={urls.workshop.subscription.validate} />
    </Routes>
  );
});
