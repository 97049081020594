import axios, { AxiosResponse } from 'axios';

export type OnUploadProgress = (event: ProgressEvent | AxiosResponse) => void;

export interface UploadFileOptions {
  file: File;
  url: string;
  formData: Record<string, string>;
  onUploadProgress?: OnUploadProgress;
}

export const uploadFile = async ({ url, file, formData, onUploadProgress }: UploadFileOptions) => {
  await axios.post(url, createFormData({ ...formData, file }), { onUploadProgress });
};

const createFormData = (fields: Record<string, any>) => {
  const form = new FormData();

  Object.entries(fields).forEach(([key, value]) => form.append(key, value));

  return form;
};

export const isProgressEvent = (event: any): event is ProgressEvent => event?.type === 'progress';
export const getPercentFromProgressEvent = (event: any) => (isProgressEvent(event) ? event.loaded / event.total : 1);

export const getContentTypeFromFile = (file: File): 'image' | 'video' => {
  if (file.type.startsWith('image/')) return 'image';
  if (file.type.startsWith('video/')) return 'video';
  throw new Error(`Couldn't determine content type: ${file.type}`);
};
