import useSWR, { BareFetcher, SWRConfiguration, SWRResponse } from 'swr';

interface UseRemoteDataOptions {
  key: string;
  skip?: boolean;
}

const defaultConfig = {
  revalidateOnMount: true,
  revalidateIfStale: true,
  revalidateOnFocus: true,
  revalidateOnReconnect: false,
  keepPreviousData: true
};

export const useRemoteData = <O extends UseRemoteDataOptions, T>(
  { key, skip, ...o }: O,
  callback: (o: Omit<O, keyof UseRemoteDataOptions>) => Promise<T>,
  config: SWRConfiguration<T, Error, BareFetcher<T>> = defaultConfig
): SWRResponse<T, Error> => {
  return useSWR<T>(!skip ? { key, ...o } : null, callback, { ...defaultConfig, ...config });
};
