import classNames from 'classnames';
import React from 'react';

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  selected?: boolean;
}

export const OfferTab = React.forwardRef<HTMLButtonElement, React.PropsWithChildren<Props>>(
  ({ selected, className, ...props }, ref) => (
    <button
      {...{ ref }}
      {...props}
      className={classNames(
        'relative flex min-h-[110px] min-w-[144px] flex-col items-center justify-center space-y-1 border p-4 text-sm',
        selected ? 'rounded-t-lg border-transparent bg-[#D6F2FF]' : 'mb-1 rounded-lg border-gray-200 hover:bg-gray-50',
        className
      )}
      type="button"
    />
  )
);
