import classNames from 'classnames';
import React from 'react';

import { Button } from './Button';
import { Loading, LoadingIndicator } from './Loading';

export interface Props extends React.ComponentProps<typeof Button> {
  loading: boolean;
}

export const LoadingButton = React.forwardRef(function LoadingButton(
  { loading, children, ...props }: Props & React.ComponentProps<typeof Button>,
  ref: React.Ref<HTMLElement>
) {
  return (
    <Button {...props} {...{ ref }} className={classNames('relative', props.className)}>
      <Loading className="absolute inset-0 flex items-center justify-center" visible={loading}>
        <LoadingIndicator className="h-5 w-5" />
      </Loading>

      <div className={classNames('transition-opacity', { 'opacity-0': loading })}>{children}</div>
    </Button>
  );
});
