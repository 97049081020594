import React from 'react';
import { useParams } from 'react-router';

import { useTale } from '@/hooks/useTale';

import { Button } from '@/components/Button';
import { Container } from '@/components/Container';
import { Modal, useModal } from '@/components/Modal';
import { Timeline } from '@/components/Timeline';
import { taleApi } from '@/integrations/api';
import { translations } from '@/locales';
import { TaleStatus } from '@binhatch/servicetale-api';
import { FormattedMessage } from 'react-intl';
import { CloseWorkModal } from './CloseWorkModal';
import { WorkDependency } from './WorkDependency';
import { WorkSelectedChapter } from './WorkSelectedChapter';
import { SelectedChapter } from './useSelectedChapter';

export const WorkDetailPage: React.FC = () => {
  const { taleId } = useParams();
  const closeModal = useModal(CloseWorkModal);

  const tale = useTale({ taleId });

  return (
    <SelectedChapter.Provider
      initialState={{ incomplete: tale.data?.incomplete, completed: tale.data?.completed, status: tale.data?.status }}
    >
      <div className="flex flex-col gap-8 lg:flex-row">
        {!!taleId && (
          <div className="flex flex-col gap-8">
            {(!!taleId || !!tale.data) && (
              <Timeline
                completed={tale.data?.completed}
                incompleted={tale.data?.incomplete}
                lastUpdatedAt={tale.data?.updatedAt ? new Date(tale.data?.updatedAt) : undefined}
                status={tale.data?.status}
              />
            )}

            {tale.data?.status === TaleStatus.InProgress && (
              <div className="mx-4">
                <Button
                  appearance="transparent"
                  className="h-10 px-10"
                  type="button"
                  onClick={() =>
                    closeModal
                      .open({})
                      .then(async ({ reason }) => {
                        await taleApi.updateTale(taleId, { status: TaleStatus.Canceled, closeReason: reason });
                        await tale.mutate();
                      })
                      .catch(() => null)
                  }
                >
                  <FormattedMessage id={translations.pages.workDetail.cta.close} />
                </Button>
              </div>
            )}
          </div>
        )}

        <Container className="flex-1 space-y-6">
          {!!taleId && !!tale.data && (
            <WorkSelectedChapter
              {...{ taleId }}
              status={tale.data?.status}
              onReload={async () => {
                await tale.mutate();
              }}
            >
              <WorkDependency {...{ taleId }} />
            </WorkSelectedChapter>
          )}
        </Container>
      </div>

      <Modal {...closeModal.props} />
    </SelectedChapter.Provider>
  );
};
