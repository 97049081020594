import React from 'react';
import { FormattedMessage } from 'react-intl';

import { translations } from '@/locales';

import { Price } from '@/components/Price';

import { OfferTab } from './OfferTab';

interface Props extends React.ComponentProps<typeof OfferTab> {
  total: number;
  recommended?: boolean;
}

export const OfferSolutionTab = React.forwardRef<HTMLButtonElement, React.PropsWithChildren<Props>>(
  ({ selected, total, recommended, children, ...props }, ref) => (
    <OfferTab {...{ ref, selected }} {...props}>
      {children}

      <div className="font-medium">
        <Price amount={total} currency="RON" />
      </div>

      {recommended && (
        <div className="rounded bg-[#52BFEF] px-2 py-1 text-sm">
          <FormattedMessage id={translations.pages.workDetail.offer.recommended} />
        </div>
      )}
    </OfferTab>
  )
);
