import React from 'react';

interface RenderProps<T> {
  state: T;
  setState(state: T): void;
}

interface Props<T> {
  state: T;
  children(props: RenderProps<T>): React.ReactElement;
}

export const StatefulContainer = <T extends any>({ state: defaultState, children }: Props<T>) => {
  const [state, setState] = React.useState(defaultState);

  return children({ state, setState });
};
