export enum CarElement {
  None = 'none',
  FrontBumper = 'front-bumper',
  Hood = 'hood',
  FrontWindow = 'front-window',
  FrontLeftFender = 'front-left-fender',
  FrontRightFender = 'front-right-fender',
  FrontLeftDoor = 'front-left-door',
  FrontRightDoor = 'front-right-door',
  FrontLeftTire = 'front-left-tire',
  FrontRightTire = 'front-right-tire',
  Top = 'top',
  RearLeftFender = 'rear-left-fender',
  RearRightFender = 'rear-right-fender',
  RearLeftDoor = 'rear-left-door',
  RearRightDoor = 'rear-right-door',
  RearLeftTire = 'rear-left-tire',
  RearRightTire = 'rear-right-tire',
  RearWindow = 'rear-window',
  Trunk = 'trunk',
  RearBumper = 'rear-bumper'
}
