import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import React from 'react';

interface PaginationButtonProps {
  className?: string;
  active?: boolean;
  disabled?: boolean;
  onClick?(): void;
}

const PaginationButton: React.FC<React.PropsWithChildren<PaginationButtonProps>> = ({ active, ...props }) => (
  <button
    type="button"
    {...props}
    {...(active && { 'aria-current': 'page' })}
    className={classNames(
      'relative inline-flex min-h-[40px] min-w-[40px] flex-1 items-center justify-center border border-solid border-gray-200 text-sm font-medium',
      {
        'bg-white text-gray-300': props.disabled,
        'bg-white hover:bg-[#F0FAFF] focus:z-20': !props.disabled && !active,
        'z-10 bg-[#2E4C86] text-white': !props.disabled && active
      },
      props.className
    )}
  />
);

interface PaginationProps {
  hasPrevious?: boolean;
  hasNext?: boolean;
  className?: string;
  onPrevious?(): void;
  onNext(): void;
}

export const Pagination: React.FC<PaginationProps> = ({ hasPrevious, hasNext, className, onPrevious, onNext }) => (
  <div className={classNames('flex w-auto items-center justify-end', className)}>
    <div className="flex-none">
      <nav aria-label="Pagination" className="isolate inline-flex w-full -space-x-px rounded">
        <PaginationButton className="rounded-l" disabled={!hasPrevious} onClick={onPrevious}>
          <span className="sr-only">Previous</span>
          <ChevronLeftIcon aria-hidden="true" className="h-4 w-4" />
        </PaginationButton>

        <PaginationButton className="rounded-r" disabled={!hasNext} onClick={onNext}>
          <span className="sr-only">Next</span>
          <ChevronRightIcon aria-hidden="true" className="h-4 w-4" />
        </PaginationButton>
      </nav>
    </div>
  </div>
);
