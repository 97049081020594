import { CheckIcon, XMarkIcon } from '@heroicons/react/20/solid';
import classNames from 'classnames';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { rules } from '@/schemas/password';

interface Props {
  password?: string;
  className?: string;
}

export const PasswordValidity: React.FC<Props> = ({ password, className }) => (
  <ul className={classNames('space-y-1 text-sm', className)}>
    {rules.map(([validate, message, params], index) => {
      const valid = validate(password);

      const Icon = valid ? CheckIcon : XMarkIcon;

      return (
        <li className={classNames('flex', { 'text-gray-400': !valid })} key={index}>
          <Icon className={classNames('mr-2 h-5 w-5', valid ? 'text-teal-600' : 'text-red-400')} />
          <FormattedMessage id={message} values={params} />
        </li>
      );
    })}
  </ul>
);
