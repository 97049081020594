import React from 'react';

import { PageHeading } from '@/components/PageHeading';
import { Skeleton } from '@/components/Skeleton';

import { Card } from './WorkDetailPage/Card';

export const SkeletonPage: React.FC<React.PropsWithChildren> = ({ children }) => (
  <div className="flex flex-1 flex-col space-y-6">
    <PageHeading title={children} />

    <Card className="flex justify-between ">
      <Skeleton className="w-40" />

      <div className="flex gap-4">
        <Skeleton className="w-4" />
        <Skeleton className="w-4" />
        <Skeleton className="w-10" />
      </div>
    </Card>

    <Card className="flex flex-1 items-center justify-center text-gray-200">
      <div className="font-medium tracking-tight xl:text-7xl">Under construction</div>
    </Card>
  </div>
);
