import { ChapterKind, ChapterStateRequest, HandoverChapter } from '@binhatch/servicetale-api';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { taleApi } from '@/integrations/api';
import { translations } from '@/locales';

import { useWorkshopId } from '@/hooks/useWorkshopId';

import { Form } from '@/components/Form';
import { LoadingButton } from '@/components/LoadingButton';

import { Card } from './Card';
import { WorkHeading } from './WorkHeading';

interface TaleChapter extends HandoverChapter {
  kind: (typeof ChapterKind)['Handover'];
}

interface Props {
  taleId: string;
  chapter: TaleChapter;
  loading?: boolean;
  address?: string;
  readOnly: boolean;
  onReload(): Promise<void>;
}

export const HandoverForm: React.FC<React.PropsWithChildren<Props>> = ({
  taleId,
  chapter,
  address,
  readOnly,
  loading,
  children,
  onReload
}) => {
  const workshopId = useWorkshopId();

  const onSubmit = React.useCallback(async () => {
    await taleApi.updateTaleChapter(taleId, {
      ...chapter,
      kind: ChapterKind.Handover,
      state: ChapterStateRequest.Final
    });
    await onReload();
  }, [taleId, chapter, onReload]);

  return (
    <React.Fragment>
      <Form {...{ onSubmit }}>
        {({ submitting, handleSubmit }) => (
          <React.Fragment>
            <WorkHeading loading={loading || submitting} />

            {children}

            <form className="" onSubmit={handleSubmit}>
              <Card className="flex flex-col items-center justify-center space-y-4">
                <div className="h-40 w-40 rounded-full bg-gray-100" />

                <div className="text-center font-medium">
                  <FormattedMessage id={translations.pages.workDetail.handover.title} />
                </div>

                {!workshopId && <div className="text-center text-sm text-gray-400">{address}</div>}

                {!readOnly && (
                  <React.Fragment>
                    <div className="text-center">
                      <FormattedMessage id={translations.pages.workDetail.handover.message} />
                    </div>

                    <div className="flex gap-2">
                      <LoadingButton appearance="primary" className="h-10 px-4" loading={submitting} type="submit">
                        <FormattedMessage id={translations.pages.workDetail.handover.continue} />
                      </LoadingButton>
                    </div>
                  </React.Fragment>
                )}
              </Card>
            </form>
          </React.Fragment>
        )}
      </Form>
    </React.Fragment>
  );
};
