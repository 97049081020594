import React from 'react';

import { Loading, LoadingIndicator } from '@/components/Loading';

import { PageHeading } from './PageHeading';

interface Props {
  loading: boolean;
}

export const PageLoaderHeading: React.FC<React.PropsWithChildren<Props>> = ({ loading, children }) => (
  <PageHeading title={children}>
    <Loading visible={loading}>
      <LoadingIndicator className="h-5 w-5" />
    </Loading>
  </PageHeading>
);
