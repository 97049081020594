import {
  ChapterKind,
  ChapterState,
  ChapterStateRequest,
  Solution,
  WorkChapter,
  WorkChapterRequest,
  WorkItem
} from '@binhatch/servicetale-api';
import { BellIcon } from '@heroicons/react/24/outline';
import React from 'react';
import { Field } from 'react-final-form';
import { FormattedMessage } from 'react-intl';
import * as yup from 'yup';

import { taleApi } from '@/integrations/api';
import { translations } from '@/locales';

import { AsyncButton } from '@/components/AsyncButton';
import { Button } from '@/components/Button';
import { Form } from '@/components/Form';
import { Modal, useModal } from '@/components/Modal';
import { DeleteConfirmationModal } from '@/modals/DeleteConfirmationModal';

import { useWorkshopId } from '@/hooks/useWorkshopId';
import { getPartSubtotal, getTimeSubtotal } from '@/utils/price';
import { Autosave } from './Autosave';
import { CarImageTile } from './CarImageTile';
import { Card } from './Card';
import { ConfirmCompleteModal } from './ConfirmCompleteModal';
import { OfferSolutionTab } from './OfferSolutionTab';
import { OfferTabPanel } from './OfferTabPanel';
import { PartList } from './PartList';
import { RejectWorkModal } from './RejectWorkModal';
import { SolutionListGroup } from './SolutionListGroup';
import { TimeList } from './TimeList';
import { WorkHeading } from './WorkHeading';
import { WorkItemIndex } from './WorkItemIndex';
import { WorkItemListItem } from './WorkItemListItem';

const schema = yup
  .object({
    repairs: yup
      .array(
        yup.object({
          item: yup.mixed<WorkItem>().required(),
          solution: yup.mixed<Solution>().required()
        })
      )
      .min(1)
      .required()
  })
  .required();

interface TaleChapter extends WorkChapter {
  kind: (typeof ChapterKind)['Work'];
}

interface Props {
  taleId: string;
  chapter: TaleChapter;
  loading?: boolean;
  readOnly: boolean;
  onReload(): Promise<void>;
}

const convertFormToChapter = ({ repairs }: yup.InferType<typeof schema>): WorkChapterRequest => ({
  kind: ChapterKind.Work,
  state: ChapterStateRequest.Draft,
  repairs
});

export const WorkForm: React.FC<React.PropsWithChildren<Props>> = ({
  taleId,
  chapter,
  readOnly,
  loading,
  children,
  onReload
}) => {
  const workshopId = useWorkshopId();

  const deleteModal = useModal(DeleteConfirmationModal);
  const rejectModal = useModal(RejectWorkModal);
  const confirmModal = useModal(ConfirmCompleteModal);

  const initialValues = React.useMemo<yup.InferType<typeof schema>>(() => ({ repairs: chapter.repairs }), [chapter]);

  const onSubmit = React.useCallback(
    async (values: yup.InferType<typeof schema>) => {
      await taleApi.updateTaleChapter(taleId, { ...convertFormToChapter(values), kind: ChapterKind.Work });
    },
    [taleId]
  );

  return (
    <React.Fragment>
      <Form {...{ schema, initialValues, onSubmit }}>
        {({ values, submitting, handleSubmit }) => (
          <React.Fragment>
            <WorkHeading loading={loading || submitting}>
              {chapter.state === ChapterState.Pending && !!workshopId && (
                <Button
                  appearance="primary"
                  className="h-10 px-4"
                  type="button"
                  onClick={() => {
                    confirmModal
                      .open({
                        ...translations.pages.workDetail.confirmWorkModal,
                        callback: async () => {
                          await taleApi.updateTaleChapter(taleId, {
                            ...convertFormToChapter(values),
                            kind: ChapterKind.Work,
                            state: ChapterStateRequest.Final
                          });

                          await onReload();
                        }
                      })
                      .catch(() => null);
                  }}
                >
                  <BellIcon className="mr-2 h-4 w-4" />
                  <FormattedMessage id={translations.pages.workDetail.cta.work} />
                </Button>
              )}
            </WorkHeading>

            {children}

            <form className="m-0 grid grid-cols-2 gap-6" onSubmit={handleSubmit}>
              {!readOnly && <Autosave />}

              {!!workshopId && (
                <Card className="col-span-full flex flex-col items-center justify-center space-y-4">
                  <div className="h-40 w-40 rounded-full bg-gray-100" />

                  <div className="text-center font-medium">
                    <FormattedMessage id={translations.pages.workDetail.work.progress.title} />
                  </div>

                  <div className="text-center">
                    <FormattedMessage id={translations.pages.workDetail.work.progress.message} />
                  </div>

                  <div className="flex gap-2">
                    <AsyncButton
                      appearance="outline"
                      className="h-10 px-4"
                      type="button"
                      onClick={async () => {
                        await taleApi.addTaleAdditionalChapters(taleId, {
                          chapters: [
                            { state: ChapterStateRequest.Draft, kind: ChapterKind.Inspection },
                            { state: ChapterStateRequest.Draft, kind: ChapterKind.Offer },
                            { state: ChapterStateRequest.Draft, kind: ChapterKind.Work }
                          ]
                        });

                        await onReload();
                      }}
                    >
                      <FormattedMessage id={translations.pages.workDetail.work.progress.add} />
                    </AsyncButton>
                  </div>
                </Card>
              )}

              <Card className="col-span-2 space-y-4">
                <h2 className="font-medium">
                  <FormattedMessage id={translations.pages.workDetail.work.list} />
                </h2>

                <Field name="repairs" render={() => null} />

                {!!values.repairs?.length && (
                  <ol className="grid gap-6">
                    {values.repairs?.map(({ item, solution }, problemIndex) => (
                      <li className="group flex gap-2" key={problemIndex}>
                        <WorkItemIndex>{problemIndex + 1}</WorkItemIndex>

                        <div className="flex-1 space-y-4">
                          <div>
                            <WorkItemListItem {...{ item }} />
                          </div>

                          {!!item.media?.length && (
                            <ul className="flex flex-wrap gap-4">
                              {item.media?.map((media, index) => (
                                <li key={index}>
                                  <CarImageTile className="w-16" src={media} />
                                </li>
                              ))}
                            </ul>
                          )}

                          <div>
                            <div className="flex gap-2">
                              <OfferSolutionTab
                                recommended={solution.recommended}
                                selected
                                total={getTimeSubtotal(solution.time) + getPartSubtotal(solution.parts)}
                              >
                                <FormattedMessage id={translations.pages.workDetail.work.solutionName} />
                              </OfferSolutionTab>
                            </div>

                            <OfferTabPanel first>
                              <div className="space-y-4">
                                <SolutionListGroup amount={getTimeSubtotal(solution.time)}>
                                  <FormattedMessage
                                    id={translations.pages.workDetail.offer.time.title}
                                    values={{ count: solution.time.length }}
                                  />
                                </SolutionListGroup>

                                <TimeList readOnly times={solution.time} />

                                <SolutionListGroup amount={getPartSubtotal(solution.parts)}>
                                  <FormattedMessage
                                    id={translations.pages.workDetail.offer.part.title}
                                    values={{ count: solution.parts.length }}
                                  />
                                </SolutionListGroup>

                                <PartList parts={solution.parts} readOnly />
                              </div>
                            </OfferTabPanel>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ol>
                )}
              </Card>
            </form>
          </React.Fragment>
        )}
      </Form>

      <Modal {...deleteModal.props} />
      <Modal {...rejectModal.props} />
      <Modal {...confirmModal.props} />
    </React.Fragment>
  );
};
