import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import { translations } from '@/locales';
import * as urls from '@/utils/url';

import { Auth } from '@/containers/useAuth';

import { CognitoErrorCode, isCognitoError } from '@/components/CognitoError';
import { Form } from '@/components/Form';
import { InputWithLabel } from '@/components/Form/InputWithLabel';
import { ValidatedField } from '@/components/Form/ValidatedField';
import { Link } from '@/components/Link';
import { LoadingButton } from '@/components/LoadingButton';
import { SubmitError } from '@/components/SubmitError';

export const schema = yup
  .object({
    email: yup.string().email().required().label(translations.fields.email.label),
    password: yup.string().required().label(translations.fields.password.label)
  })
  .required();

export const LoginPage: React.FC = () => {
  const intl = useIntl();
  const { login } = Auth.useContainer();
  const { state } = useLocation();
  const navigate = useNavigate();

  const initialValues = React.useMemo(() => ({ email: state?.email, password: '' }), [state]);

  const onSubmit = React.useCallback(
    async ({ email, password }: yup.InferType<typeof schema>) => {
      await login({ email, password }).catch((error) => {
        if (!isCognitoError(error)) throw error;
        if (error.code !== CognitoErrorCode.UserNotConfirmedException) throw error;

        navigate(urls.confirmation, { state: { email, password } });
      });
    },
    [login, navigate]
  );

  return (
    <Form {...{ initialValues, schema, onSubmit }}>
      {({ values, submitting, submitError, handleSubmit }) => (
        <form className="m-0 w-full max-w-sm" onSubmit={handleSubmit}>
          <div className="space-y-6">
            <h1 className="text-xl font-medium">
              <FormattedMessage id={translations.pages.login.title} />
            </h1>

            <div className="space-y-6">
              <ValidatedField
                field={InputWithLabel}
                id="email"
                label={<FormattedMessage id={translations.fields.email.label} />}
                name="email"
                placeholder={intl.formatMessage({ id: translations.fields.email.placeholder })}
                readOnly={submitting}
                type="email"
              />

              <ValidatedField
                field={InputWithLabel}
                id="password"
                label={<FormattedMessage id={translations.fields.password.label} />}
                name="password"
                placeholder={intl.formatMessage({ id: translations.fields.password.placeholder })}
                readOnly={submitting}
                type="password"
              />

              <div className="text-right">
                <Link as={RouterLink} state={{ email: values.email }} to={urls.forgotPassword.send}>
                  <FormattedMessage id={translations.pages.login.forgot} />
                </Link>
              </div>

              <SubmitError error={submitError} />

              <LoadingButton appearance="primary" className="h-10 w-full" loading={submitting} type="submit">
                <FormattedMessage id={translations.pages.login.cta} />
              </LoadingButton>

              <div className="text-center">
                <FormattedMessage
                  id={translations.pages.login.register}
                  values={{
                    a: (children) => (
                      <Link as={RouterLink} to={urls.register}>
                        {children}
                      </Link>
                    )
                  }}
                />
              </div>
            </div>
          </div>
        </form>
      )}
    </Form>
  );
};
