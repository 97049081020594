import classNames from 'classnames';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { As, AsComponent, AsProps } from './As';

export const Link = React.forwardRef(
  <T extends AsComponent>(
    // @ts-ignore
    { as = RouterLink, ...props }: AsProps<T>,
    ref: React.Ref<HTMLElement>
  ) => <As {...{ as, ref }} {...props} className={classNames('text-sm leading-5 underline', props.className)} />
);
