import { Subscription } from '@binhatch/servicetale-api';

import { workshopApi } from '@/integrations/api';

import { BareFetcher, SWRConfiguration } from 'swr';
import { useRemoteData } from './useRemoteData';
import { useWorkshopId } from './useWorkshopId';

export const useSubscription = (
  config?: SWRConfiguration<Subscription | undefined, Error, BareFetcher<Subscription | undefined>>
) => {
  const workshopId = useWorkshopId();

  return useRemoteData(
    { key: 'getSubscription', workshopId, skip: !workshopId },
    ({ workshopId }) => {
      if (!workshopId) throw new Error('No workshop found.');
      return workshopApi
        .getWorkshopSubscription(workshopId)
        .then((r) => r.data)
        .catch(() => undefined);
    },
    config
  );
};
