import {
  CarReceptionChapter,
  CarReceptionChapterRequest,
  ChapterKind,
  ChapterState,
  ChapterStateRequest,
  Damage,
  Priority,
  WorkType
} from '@binhatch/servicetale-api';
import { CheckIcon } from '@heroicons/react/20/solid';
import { CameraIcon, PlusSmallIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import * as yup from 'yup';

import { taleApi } from '@/integrations/api';
import { translations } from '@/locales';
import { uploadFile } from '@/utils/uploadFile';

import { CarElement } from '@/data/carElement';
import { useTale } from '@/hooks/useTale';

import { Button } from '@/components/Button';
import { Form } from '@/components/Form';
import { Checkbox } from '@/components/Form/Checkbox';
import { FuelInput } from '@/components/Form/FuelInput';
import { InputWithLabel } from '@/components/Form/InputWithLabel';
import { NumberInput } from '@/components/Form/NumberInput';
import { Toggle } from '@/components/Form/Toggle';
import { ValidatedField } from '@/components/Form/ValidatedField';
import { WorkItemSelect } from '@/components/Form/WorkItemSelect';
import { Modal, useModal } from '@/components/Modal';
import { DeleteConfirmationModal } from '@/modals/DeleteConfirmationModal';

import { useWorkshopId } from '@/hooks/useWorkshopId';
import { replaceItemAtIndex } from '@/utils/array';
import { AddCarImageButton } from './AddCarImageButton';
import { Autosave } from './Autosave';
import { CarElementSelector } from './CarElementSelector';
import { CarImageTile } from './CarImageTile';
import { Card } from './Card';
import { ConfirmCompleteModal } from './ConfirmCompleteModal';
import { UploadModal } from './UploadModal';
import { WorkHeading } from './WorkHeading';

export enum MissingItemKind {
  ReflectiveTriangles = 'reflectiveTriangles',
  MedicalKit = 'medicalKit',
  SpareTire = 'spareTire'
}

export enum RegisteredItemKind {
  CarKey = 'carKey',
  RegistrationCertificate = 'registrationCertificate',
  AntiTheftKey = 'antiTheftKey',
  ServiceCard = 'serviceCard'
}

const operationSchemam = yup.object({
  id: yup.string(),
  name: yup.string().required().label(translations.fields.operations.label),
  media: yup.array().of(yup.string().required()).min(0).required(),
  priority: yup.mixed<Priority>(),
  type: yup.mixed<WorkType>().required()
});

const restSchema = yup
  .object({
    damages: yup
      .array()
      .of(yup.mixed<Damage>().required())
      .min(0)
      .required()
      .label(translations.fields.visibleDamage.label),
    providedParts: yup
      .array(yup.string().required().label(translations.fields.providedParts.label))
      .min(0)
      .required()
      .label(translations.fields.providedParts.label),
    registeredItems: yup
      .array(yup.string().required())
      .min(0)
      .required()
      .label(translations.fields.providedParts.label),
    missingItems: yup.array(yup.string().required()).min(0).required().label(translations.fields.visibleDamage.label),
    comments: yup.string().label(translations.fields.notes.label),
    providedPartsVisible: yup.boolean(),
    damageVisible: yup.boolean()
  })
  .required();

const requiredSchema = yup
  .object({
    operations: yup.array(operationSchemam.required()).min(1).required().label(translations.fields.operations.label),
    mileage: yup.number().integer().positive().required().label(translations.fields.mileage.label),
    fuelLevel: yup.number().min(0).max(100).required().label(translations.fields.fuelLevel.label)
  })
  .required()
  .concat(restSchema);

const schema = yup
  .object({
    operations: yup.array(operationSchemam.required()).min(0).label(translations.fields.operations.label),
    mileage: yup.number().integer().positive().label(translations.fields.mileage.label),
    fuelLevel: yup.number().min(0).max(100).label(translations.fields.fuelLevel.label)
  })
  .required()
  .concat(restSchema);

interface TaleChapter extends CarReceptionChapter {
  kind: (typeof ChapterKind)['CarReception'];
}

interface Props {
  taleId: string;
  chapter: TaleChapter;
  loading?: boolean;
  readOnly?: boolean;
}

const convertChapterToForm = (chapter: TaleChapter): yup.InferType<typeof schema> => ({
  damages: chapter.carStatus.damages ?? [],
  mileage: chapter.carStatus.mileage,
  fuelLevel: chapter.carStatus.fuelLevel,
  operations:
    chapter.operations?.map((o) => ({ ...o, id: o.id, priority: o.priority, type: o.type ?? WorkType.Requested })) ??
    [],
  providedParts: chapter.providedParts ?? [],
  missingItems: chapter.missingItems ?? [],
  registeredItems: chapter.registeredItems ?? [],
  comments: chapter.comments,
  providedPartsVisible: chapter.providedParts?.length > 0,
  damageVisible: true
});

const convertFormToChapter = ({
  damages,
  mileage,
  fuelLevel,
  providedPartsVisible,
  damageVisible,
  ...values
}: yup.InferType<typeof schema>): CarReceptionChapterRequest => ({
  ...values,
  kind: ChapterKind.CarReception,
  state: ChapterStateRequest.Draft,
  operations: values.operations?.map((o) => ({ ...o, media: o.media ?? [] })) ?? [],
  receptionStatus: { mileage, fuelLevel, damages }
});

export const CarReceptionForm: React.FC<React.PropsWithChildren<Props>> = ({
  taleId,
  loading,
  chapter,
  readOnly,
  children
}) => {
  const intl = useIntl();

  const workshopId = useWorkshopId();

  const deleteModal = useModal(DeleteConfirmationModal);
  const uploadModalModal = useModal(UploadModal);
  const confirmModal = useModal(ConfirmCompleteModal);

  const tale = useTale({ taleId });

  const initialValues = React.useMemo<yup.InferType<typeof schema>>(() => convertChapterToForm(chapter), [chapter]);

  const onSubmit = React.useCallback(
    async (values: yup.InferType<typeof schema>) => {
      await taleApi.updateTaleChapter(taleId, { ...convertFormToChapter(values), kind: ChapterKind.CarReception });
    },
    [taleId]
  );

  return (
    <React.Fragment>
      <Form {...{ schema, initialValues, onSubmit }}>
        {({ values, submitting, handleSubmit, form }) => (
          <React.Fragment>
            <WorkHeading loading={loading || submitting}>
              {chapter.state === ChapterState.Pending && !!workshopId && (
                <Button
                  appearance="primary"
                  className="h-10 px-4"
                  disabled={!requiredSchema.isValidSync(values)}
                  type="button"
                  onClick={() => {
                    confirmModal
                      .open({
                        ...translations.pages.workDetail.confirmReceptionModal,
                        callback: async () => {
                          await taleApi.updateTaleChapter(taleId, {
                            ...convertFormToChapter(values),
                            kind: ChapterKind.CarReception,
                            state: ChapterStateRequest.Final
                          });

                          await tale.mutate();
                        }
                      })
                      .catch(() => null);
                  }}
                >
                  <CheckIcon className="mr-2 h-4 w-4" />
                  <FormattedMessage id={translations.pages.workDetail.cta.reception} />
                </Button>
              )}
            </WorkHeading>

            {children}

            <form className="m-0 grid gap-6 xl:grid-cols-2" onSubmit={handleSubmit}>
              {!readOnly && <Autosave />}

              <Card className="col-span-full space-y-4">
                <h2 className="font-medium">
                  <FormattedMessage id={translations.pages.workDetail.settings.title} />
                </h2>

                <ValidatedField
                  as="textarea"
                  field={InputWithLabel}
                  id="notes"
                  inputClassName="min-h-[6rem]"
                  label={<FormattedMessage id={translations.fields.notes.label} />}
                  name="comments"
                  placeholder={intl.formatMessage({ id: translations.fields.notes.placeholder })}
                  {...{ readOnly }}
                />
              </Card>

              <Card className="col-span-full space-y-4">
                <h2 className="font-medium">
                  <FormattedMessage id={translations.pages.workDetail.operation.title} />
                </h2>

                {!!values.operations?.length && (
                  <ol className="grid gap-2">
                    {values.operations?.map((item, index) => (
                      <li className="group flex justify-between gap-2" key={index}>
                        <div className="h-10 w-10 flex-shrink-0 rounded bg-[#F2F2F2] text-center font-bold leading-10">
                          {index + 1}
                        </div>

                        <ValidatedField
                          autoFocus={!item.id}
                          className="flex-1"
                          field={WorkItemSelect}
                          id={`operation-item-${index + 1}`}
                          name={`operations[${index}].name`}
                          placeholder={intl.formatMessage({ id: translations.fields.operations.placeholder })}
                          {...{ readOnly }}
                        />

                        {!readOnly && (
                          <Button
                            appearance="secondary"
                            className="hidden h-10 px-4 group-hover:block"
                            type="button"
                            onClick={() =>
                              deleteModal
                                .open({
                                  callback: async () => {
                                    form.change('operations', values.operations?.filter((o) => o !== item) ?? []);
                                  }
                                })
                                .catch(() => null)
                            }
                          >
                            <FormattedMessage id={translations.buttons.delete} />
                          </Button>
                        )}
                      </li>
                    ))}
                  </ol>
                )}

                {!readOnly && (
                  <div>
                    {!values.operations?.length && (
                      <div className="py-4 text-center">
                        <FormattedMessage id={translations.pages.workDetail.operation.empty} />
                      </div>
                    )}

                    <div className={classNames('flex', { 'justify-center': !values.operations?.length })}>
                      <Button
                        appearance="outline"
                        className="h-10 px-4"
                        type="button"
                        onClick={() => {
                          form.change('operations', [
                            ...(values.operations ?? []),
                            { id: undefined, name: '', priority: Priority.Low, media: [], type: WorkType.Requested }
                          ]);
                        }}
                      >
                        <PlusSmallIcon className="mr-2 h-5 w-5" />
                        <FormattedMessage id={translations.pages.workDetail.operation.add} />
                      </Button>
                    </div>
                  </div>
                )}
              </Card>

              {false && (
                <Card className="col-span-full space-y-4">
                  <h2 className="flex h-10 items-center font-medium">
                    <ValidatedField
                      direction="right"
                      disabled={!!values.providedParts?.length || readOnly}
                      field={Toggle}
                      id="provided-parts-visible"
                      name="providedPartsVisible"
                      type="checkbox"
                    >
                      <FormattedMessage id={translations.fields.providedParts.label} />
                    </ValidatedField>
                  </h2>

                  {!!values.providedPartsVisible && (
                    <React.Fragment>
                      {!!values.providedParts?.length && (
                        <ol className="grid gap-2">
                          {values.providedParts?.map((item, index) => (
                            <li className="group flex justify-between gap-2" key={index}>
                              <div className="h-10 w-10 flex-shrink-0 rounded bg-[#F2F2F2] text-center font-bold leading-10">
                                {index + 1}
                              </div>

                              <ValidatedField
                                autoFocus={!item}
                                className="flex-1"
                                field={WorkItemSelect}
                                id={`provided-part-${index + 1}`}
                                name={`providedParts[${index}]`}
                                placeholder={intl.formatMessage({ id: translations.fields.providedParts.placeholder })}
                                {...{ readOnly }}
                              />

                              {!readOnly && (
                                <Button
                                  appearance="secondary"
                                  className="hidden h-10 px-4 group-hover:block"
                                  type="button"
                                  onClick={() =>
                                    deleteModal
                                      .open({
                                        callback: async () => {
                                          form.change(
                                            'providedParts',
                                            values.providedParts?.filter((o) => o !== item) ?? []
                                          );
                                        }
                                      })
                                      .catch(() => null)
                                  }
                                >
                                  <FormattedMessage id={translations.buttons.delete} />
                                </Button>
                              )}
                            </li>
                          ))}
                        </ol>
                      )}

                      {!readOnly && (
                        <div>
                          {!values.providedParts?.length && (
                            <div className="py-4 text-center">
                              <FormattedMessage id={translations.pages.workDetail.parts.empty} />
                            </div>
                          )}

                          <div className={classNames('flex', { 'justify-center': !values.providedParts?.length })}>
                            <Button
                              appearance="outline"
                              className="h-10 px-4"
                              type="button"
                              onClick={() => {
                                form.change('providedParts', [...(values.providedParts ?? []), '']);
                              }}
                            >
                              <PlusSmallIcon className="mr-2 h-5 w-5" />
                              <FormattedMessage id={translations.pages.workDetail.parts.add} />
                            </Button>
                          </div>
                        </div>
                      )}
                    </React.Fragment>
                  )}
                </Card>
              )}

              <Card className="col-span-full space-y-4">
                <h2 className="font-medium">
                  <FormattedMessage id={translations.pages.workDetail.car.title} />
                </h2>

                <div className="grid gap-4 md:grid-cols-2">
                  <ValidatedField
                    as={NumberInput}
                    field={InputWithLabel}
                    id="mileage"
                    includeThousandsSeparator
                    label={<FormattedMessage id={translations.fields.mileage.label} />}
                    name="mileage"
                    placeholder={intl.formatMessage({ id: translations.fields.mileage.placeholder })}
                    {...{ readOnly }}
                  />

                  <div>
                    <ValidatedField
                      as={FuelInput}
                      disabled={readOnly}
                      field={InputWithLabel}
                      id="fuelLevel"
                      label={<FormattedMessage id={translations.fields.fuelLevel.label} />}
                      name="fuelLevel"
                    />
                  </div>
                </div>

                <ValidatedField
                  direction="right"
                  disabled={!!values.damages?.length || readOnly}
                  field={Toggle}
                  id="damageVisible"
                  name="damageVisible"
                  type="checkbox"
                >
                  <FormattedMessage id={translations.fields.visibleDamage.label} />
                </ValidatedField>

                {!!values.damageVisible && (
                  <React.Fragment>
                    <div className="flex-wrap md:flex">
                      <CarElementSelector
                        className="mx-auto w-64 flex-shrink-0 rotate-90 select-none px-10"
                        disabled={readOnly}
                        elements={values.damages?.map(({ part }) => part as CarElement) ?? []}
                        onClick={(element) =>
                          uploadModalModal
                            .open({
                              onUpload: ({ file, onUploadProgress }) => {
                                return taleApi
                                  .createUploadURL(taleId, { mimeType: file.type, contentLength: file.size })
                                  .then(async ({ data: { uploadURL, getURL, formData } }) => {
                                    await uploadFile({ url: uploadURL, formData, file, onUploadProgress });

                                    const damages = form.getFieldState('damages')?.value ?? [];

                                    const item: Damage = damages.find((d) => d.part === element) ?? {
                                      part: element,
                                      media: []
                                    };

                                    form.change('damages', [
                                      ...damages.filter((d) => d.part !== element),
                                      { ...item, media: [...item.media, getURL] }
                                    ]);

                                    return getURL;
                                  });
                              }
                            })
                            .catch(() => null)
                        }
                      />

                      <ul className="grid grid-cols-3 gap-4 md:pl-40">
                        {values.damages?.flatMap((damage) =>
                          damage.media.map((media, index) => (
                            <li key={media}>
                              <ValidatedField
                                field={() => (
                                  <CarImageTile
                                    src={media}
                                    onRemove={() => {
                                      deleteModal
                                        .open({})
                                        .then(() => {
                                          form.change(
                                            'damages',
                                            replaceItemAtIndex(values.damages.slice(), index, {
                                              ...damage,
                                              media: damage.media.filter((m) => m !== media)
                                            })
                                          );
                                        })
                                        .catch(() => null);
                                    }}
                                  />
                                )}
                                id="damages"
                                name="damages"
                              />

                              <div className="mt-2 text-sm font-medium">
                                <FormattedMessage id={translations.enum.carElement[damage.part as CarElement]} />
                              </div>
                            </li>
                          ))
                        )}

                        {!readOnly && (
                          <li>
                            <AddCarImageButton
                              className="flex-col p-4"
                              type="button"
                              onClick={() => {
                                uploadModalModal
                                  .open({
                                    onUpload: ({ file, onUploadProgress }) => {
                                      return taleApi
                                        .createUploadURL(taleId, { mimeType: file.type, contentLength: file.size })
                                        .then(async ({ data: { uploadURL, getURL, formData } }) => {
                                          await uploadFile({ url: uploadURL, formData, file, onUploadProgress });

                                          const damages = form.getFieldState('damages')?.value ?? [];

                                          const element = 'none';

                                          const item: Damage = damages.find((d) => d.part === element) ?? {
                                            part: element,
                                            media: []
                                          };

                                          form.change('damages', [
                                            ...damages.filter((d) => d.part !== element),
                                            { ...item, media: [...item.media, getURL] }
                                          ]);

                                          return getURL;
                                        });
                                    }
                                  })
                                  .catch(() => null);
                              }}
                            >
                              <CameraIcon className="mb-2 h-6 w-6" />
                              <FormattedMessage id={translations.pages.workDetail.car.addFile} />
                            </AddCarImageButton>
                          </li>
                        )}
                      </ul>
                    </div>
                  </React.Fragment>
                )}
              </Card>

              <Card className="space-y-4">
                <h2 className="font-medium">
                  <FormattedMessage id={translations.pages.workDetail.included.title} />
                </h2>

                <div>
                  <ul className="-my-1 -mx-2 flex flex-wrap">
                    {Object.values(RegisteredItemKind).map((item) => (
                      <li className="my-1 mx-2" key={item}>
                        <ValidatedField
                          disabled={readOnly}
                          field={Checkbox}
                          id={`registered-item-${item}`}
                          name="registeredItems"
                          type="checkbox"
                          value={item}
                        >
                          <FormattedMessage id={translations.fields[item].label} />
                        </ValidatedField>
                      </li>
                    ))}
                  </ul>
                </div>

                {/* <ValidatedField
                as="textarea"
                field={InputWithLabel}
                id="registered-item-comment"
                inputClassName="min-h-[6rem]"
                label={<FormattedMessage id={translations.fields.mention.label} />}
                name="registeredItemComment"
                placeholder={intl.formatMessage({ id: translations.fields.mention.placeholder })}
              /> */}
              </Card>

              <Card className="space-y-4">
                <h2 className="font-medium">
                  <FormattedMessage id={translations.pages.workDetail.excluded.title} />
                </h2>

                <div>
                  <ul className="-my-1 -mx-2 flex flex-wrap">
                    {Object.values(MissingItemKind).map((item) => (
                      <li className="my-1 mx-2" key={item}>
                        <ValidatedField
                          disabled={readOnly}
                          field={Checkbox}
                          id={`missing-item-${item}`}
                          name="missingItems"
                          type="checkbox"
                          value={item}
                          {...{ readOnly }}
                        >
                          <FormattedMessage id={translations.fields[item].label} />
                        </ValidatedField>
                      </li>
                    ))}
                  </ul>
                </div>

                {/* <ValidatedField
                as="textarea"
                field={InputWithLabel}
                id="missing-item-comment"
                inputClassName="min-h-[6rem]"
                label={<FormattedMessage id={translations.fields.mention.label} />}
                name="missingItemComment"
                placeholder={intl.formatMessage({ id: translations.fields.mention.placeholder })}
              /> */}
              </Card>
            </form>
          </React.Fragment>
        )}
      </Form>

      <Modal {...deleteModal.props} />
      <Modal {...uploadModalModal.props} />
      <Modal {...confirmModal.props} />
    </React.Fragment>
  );
};
