import { EmbeddedCheckout, EmbeddedCheckoutProvider } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import qs from 'query-string';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useLocation } from 'react-router';

import { STRIPE_API_KEY } from '@/config';
import { workshopApi } from '@/integrations/api';
import { translations } from '@/locales';
import * as urls from '@/utils/url';

import { useRemoteData } from '@/hooks/useRemoteData';
import { useWorkshopId } from '@/hooks/useWorkshopId';

import { Loading, LoadingIndicator, LoadingMessage } from '@/components/Loading';

const stripe = loadStripe(STRIPE_API_KEY);

export const WorkshopSubscriptionPage: React.FC = () => {
  const workshopId = useWorkshopId();
  const { state } = useLocation();

  const checkout = useRemoteData(
    { key: 'createCheckout', workshopId, skip: !workshopId, redirect: state?.redirect },
    ({ workshopId, redirect }) =>
      workshopApi
        .createCheckoutSession(workshopId!, {
          redirectURL: qs.stringifyUrl({
            url: `${location.origin}${urls.workshop.subscription.validate}`,
            query: { redirect }
          })
        })
        .then((r) => r.data)
  );

  return (
    <div className="flex flex-1 flex-col items-center justify-center pb-10 md:pb-16">
      {checkout.data?.clientSecret ? (
        <EmbeddedCheckoutProvider {...{ stripe }} options={{ clientSecret: checkout.data.clientSecret }}>
          <EmbeddedCheckout className="w-full" />
        </EmbeddedCheckoutProvider>
      ) : (
        <Loading visible>
          <LoadingMessage>
            <LoadingIndicator className="h-5 w-5" />

            <div>
              <FormattedMessage id={translations.pages.workshop.subscription.create.loading} />
            </div>
          </LoadingMessage>
        </Loading>
      )}
    </div>
  );
};
