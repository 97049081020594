declare const CONFIG: {
  VERSION: string;
  COGNITO_USER_POOL_ID: string;
  COGNITO_CLIENT_ID: string;
  API_URL: string;
  STRIPE_API_KEY: string;
  HUBSPOT_TRACKING_ID?: number;
};

export const { VERSION, COGNITO_USER_POOL_ID, COGNITO_CLIENT_ID, API_URL, STRIPE_API_KEY, HUBSPOT_TRACKING_ID } =
  CONFIG;
