import { TaleStatus } from '@binhatch/servicetale-api';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { taleApi } from '@/integrations/api';
import { translations } from '@/locales';

import { useTale } from '@/hooks/useTale';

import { Button } from '@/components/Button';
import { Modal, useModal } from '@/components/Modal';
import { Timeline } from '@/components/Timeline';

import { Container } from '@/components/Container';
import { CloseWorkModal } from './CloseWorkModal';

interface Props {
  taleId?: string;
}

export const WorkLayout: React.FC<React.PropsWithChildren<Props>> = ({ taleId, children }) => {
  const closeModal = useModal(CloseWorkModal);

  const tale = useTale({ taleId });

  return (
    <React.Fragment>
      <div className="flex gap-8">
        {!!taleId && (
          <div className="flex flex-col gap-8">
            {(!!taleId || !!tale.data) && (
              <Timeline
                completed={tale.data?.completed}
                incompleted={tale.data?.incomplete}
                lastUpdatedAt={tale.data?.updatedAt ? new Date(tale.data?.updatedAt) : undefined}
                status={tale.data?.status}
              />
            )}

            {tale.data?.status === TaleStatus.InProgress && (
              <div className="mx-4">
                <Button
                  appearance="transparent"
                  className="h-10 px-10"
                  type="button"
                  onClick={() =>
                    closeModal
                      .open({})
                      .then(async ({ reason }) => {
                        await taleApi.updateTale(taleId, { status: TaleStatus.Canceled, closeReason: reason });
                        await tale.mutate();
                      })
                      .catch(() => null)
                  }
                >
                  <FormattedMessage id={translations.pages.workDetail.cta.close} />
                </Button>
              </div>
            )}
          </div>
        )}

        <Container className="flex-1 space-y-6">{children}</Container>
      </div>

      <Modal {...closeModal.props} />
    </React.Fragment>
  );
};
