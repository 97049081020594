import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import { requestResetPassword } from '@/integrations/cognito';
import { translations } from '@/locales';
import * as urls from '@/utils/url';

import { Form } from '@/components/Form';
import { InputWithLabel } from '@/components/Form/InputWithLabel';
import { ValidatedField } from '@/components/Form/ValidatedField';
import { Link } from '@/components/Link';
import { LoadingButton } from '@/components/LoadingButton';
import { SubmitError } from '@/components/SubmitError';

export const schema = yup
  .object({
    email: yup.string().email().required().label(translations.fields.email.label)
  })
  .required();

export const ForgotPasswordPage: React.FC = () => {
  const navigate = useNavigate();
  const intl = useIntl();
  const { state } = useLocation();

  const initialValues = React.useMemo(() => ({ email: state?.email }), [state]);

  const onSubmit = React.useCallback(
    async ({ email }: yup.InferType<typeof schema>) => {
      await requestResetPassword({ email });
      navigate(urls.forgotPassword.reset, { state: { email } });
    },
    [navigate]
  );

  return (
    <Form {...{ initialValues, schema, onSubmit }}>
      {({ values, submitting, submitError, handleSubmit }) => (
        <form className="m-0 w-full max-w-sm" onSubmit={handleSubmit}>
          <div className="space-y-6">
            <h1 className="text-xl font-medium">
              <FormattedMessage id={translations.pages.forgotPassword.title} />
            </h1>

            <div>
              <FormattedMessage id={translations.pages.forgotPassword.message} />
            </div>

            <div className="space-y-6">
              <ValidatedField
                field={InputWithLabel}
                id="email"
                label={<FormattedMessage id={translations.fields.email.label} />}
                name="email"
                placeholder={intl.formatMessage({ id: translations.fields.email.placeholder })}
                readOnly={submitting}
                type="email"
              />

              <SubmitError error={submitError} />

              <LoadingButton appearance="primary" className="h-10 w-full" loading={submitting} type="submit">
                <FormattedMessage id={translations.pages.forgotPassword.cta} />
              </LoadingButton>

              <div className="text-center">
                <Link as={RouterLink} state={{ email: values.email }} to={urls.signIn}>
                  <FormattedMessage id={translations.pages.forgotPassword.remember} />
                </Link>
              </div>
            </div>
          </div>
        </form>
      )}
    </Form>
  );
};
