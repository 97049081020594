import { CheckCircleIcon } from '@heroicons/react/24/outline';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { translations } from '@/locales';

import { Card } from './Card';
import { WorkHeading } from './WorkHeading';

export const CompleteForm: React.FC<React.PropsWithChildren> = ({ children }) => (
  <React.Fragment>
    <WorkHeading />

    {children}

    <Card className="flex flex-col items-center justify-center space-y-4 py-12">
      <CheckCircleIcon className="h-20 w-20 text-teal-600" />

      <div className="text-center font-medium">
        <FormattedMessage id={translations.pages.workDetail.completed.title} />
      </div>

      <div className="text-center">
        <FormattedMessage id={translations.pages.workDetail.completed.message} />
      </div>
    </Card>
  </React.Fragment>
);
