import { RejectionCause } from '@binhatch/servicetale-api';
import classnames from 'classnames';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import * as yup from 'yup';

import { translations } from '@/locales';

import { Form } from '@/components/Form';
import { InputWithLabel } from '@/components/Form/InputWithLabel';
import { Radio } from '@/components/Form/Radio';
import { ValidatedField } from '@/components/Form/ValidatedField';
import { InstanceProps, ModalHeader, ModalLayout, ModalPrimaryButton, ModalSecondaryButton } from '@/components/Modal';

export interface Props extends InstanceProps<{ reason: RejectionCause; comment?: string }, { solution?: boolean }> {}

const schema = yup
  .object({
    reason: yup.mixed<RejectionCause>().required().label(translations.pages.workDetail.rejectModal.reason.label),
    comment: yup
      .string()
      .when('reason', {
        is: (reason?: RejectionCause) => reason === RejectionCause.Other,
        then: (s) => s.required(),
        otherwise: (s) => s.strip()
      })
      .label(translations.pages.workDetail.rejectModal.reason.label)
  })
  .required();

export const RejectWorkModal = React.forwardRef<HTMLDivElement, Props>(
  ({ initialFocus, data: { solution }, className, onAction, onClose }, ref) => {
    const intl = useIntl();

    const onSubmit = React.useCallback(
      async ({ reason, comment }: yup.InferType<typeof schema>) => {
        return onAction({ reason, comment });
      },
      [onAction]
    );

    return (
      <div {...{ ref }} className={classnames(className, 'max-w-md space-y-6')}>
        <Form {...{ schema, onSubmit }}>
          {({ values, submitting, handleSubmit }) => (
            <form className="m-0 space-y-4" onSubmit={handleSubmit}>
              <ModalLayout className="flex-1">
                <ModalHeader {...{ onClose }}>
                  <FormattedMessage
                    id={
                      solution
                        ? translations.pages.workDetail.rejectModal.solutionTitle
                        : translations.pages.workDetail.rejectModal.offerTitle
                    }
                  />
                </ModalHeader>

                <div className="text-sm">
                  <FormattedMessage id={translations.pages.workDetail.rejectModal.message} />
                </div>
              </ModalLayout>

              <div className="space-y-1">
                {Object.values(RejectionCause).map((value, index) => (
                  <ValidatedField
                    field={Radio}
                    id={`reason-${index + 1}`}
                    key={value}
                    name="reason"
                    type="radio"
                    {...{ value }}
                    readOnly={!!submitting}
                    validated={false}
                  >
                    <FormattedMessage id={translations.enum.rejectionCause[value]} />
                  </ValidatedField>
                ))}
              </div>

              {values.reason === RejectionCause.Other && (
                <div className="space-y-1">
                  <ValidatedField
                    as="textarea"
                    field={InputWithLabel}
                    id="comment"
                    inputClassName="h-24 resize-none"
                    label={<FormattedMessage id={translations.pages.workDetail.rejectModal.reason.label} />}
                    name="comment"
                    placeholder={intl.formatMessage({
                      id: translations.pages.workDetail.rejectModal.reason.placeholder
                    })}
                    readOnly={!!submitting}
                    ref={initialFocus}
                  />
                </div>
              )}

              <div className="flex flex-row-reverse space-x-2">
                <ModalPrimaryButton appearance="danger" onAction={() => null}>
                  <FormattedMessage id={translations.pages.workDetail.rejectModal.cta} />
                </ModalPrimaryButton>

                <ModalSecondaryButton ref={initialFocus} {...{ onClose }}>
                  <FormattedMessage id={translations.buttons.back} />
                </ModalSecondaryButton>
              </div>
            </form>
          )}
        </Form>
      </div>
    );
  }
);
