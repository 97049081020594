import classNames from 'classnames';
import React from 'react';

import { AsComponent } from '@/components/As';

import { Props as InputProps } from './Input';

type Props<T extends AsComponent> = InputProps<T> & {
  direction?: 'left' | 'right';
};

export const Toggle = React.forwardRef(function Toggle<T extends AsComponent>(
  { direction = 'left', label, invalid, className, children, ...props }: Props<T>,
  ref: React.Ref<any>
) {
  return (
    <div className={classNames('inline-flex', { 'flex-row-reverse': direction === 'right' }, className)}>
      <input {...props} {...{ ref }} className="peer sr-only" />

      <label
        className={classNames(
          'group relative h-6 w-12 cursor-pointer rounded-md transition-colors peer-checked:bg-[#2E4C86] peer-focus:ring-2 peer-disabled:bg-gray-100',
          invalid ? 'bg-red-200 ring-2 ring-red-500' : 'bg-[#D9D9D9] ring-blue-600'
        )}
        htmlFor={props.id}
      >
        <span className="absolute top-0.5 left-0.5 h-5 w-5 rounded bg-white transition-transform peer-checked:group-[]:translate-x-6" />
      </label>

      <label
        className={classNames('cursor-pointer select-none', direction === 'left' ? 'ml-2' : 'mr-2')}
        htmlFor={props.id}
      >
        {children}
      </label>
    </div>
  );
});
