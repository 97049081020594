import classNames from 'classnames';
import React from 'react';

interface Props {
  active: boolean;
  onClick(): void;
}

export const FilterItem: React.FC<React.PropsWithChildren<Props>> = ({ active, children, onClick }) => (
  <button className={classNames('text-sm font-medium', active ? 'text-[#202020]' : 'text-[#7A7A7A]')} {...{ onClick }}>
    <div className={classNames('border-b-2 border-solid', active ? 'border-[#202020]' : 'border-transparent')}>
      {children}
    </div>
  </button>
);
