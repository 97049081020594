import classNames from 'classnames';
import React from 'react';
import { useIntl } from 'react-intl';

import { translations } from '@/locales';

import { Button } from '@/components/Button';

interface Props extends Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'value' | 'onChange'> {
  id?: string;
  value: number;
  className?: string;
  onChange(value: number): void;
}

export const FuelInput: React.FC<Props> = ({ id, className, value, disabled, onChange }) => {
  const intl = useIntl();

  const items = React.useMemo<{ id?: string; value: number; active: boolean; children: React.ReactNode }[]>(() => {
    return [
      { id, value: 0, children: intl.formatMessage({ id: translations.fields.fuelLevel.empty }) },
      ...[25, 50, 75].map((value) => ({ value, children: intl.formatNumber(value / 100, { style: 'percent' }) })),
      { value: 100, children: intl.formatMessage({ id: translations.fields.fuelLevel.full }) }
    ].map((item) => ({ ...item, active: value === item.value }));
  }, [id, intl, value]);

  return (
    <div className={classNames('flex space-x-2 overflow-hidden border-0 p-0', className)}>
      {items.map(({ id, value, active, children }, index) => (
        <Button
          {...{ id, disabled }}
          appearance={active ? 'primary' : 'outline'}
          className="flex-1"
          key={index}
          type="button"
          onClick={() => onChange(value)}
        >
          {children}
        </Button>
      ))}
    </div>
  );
};
