import classNames from 'classnames';
import React from 'react';

interface Props {
  src: string;
  className?: string;
}

export const VideoThumbnail = React.memo<Props>(({ className }) => (
  <div className={classNames('h-full w-full origin-center bg-gray-100 object-cover', className)} />
));

// export const VideoThumbnail = React.memo<Props>(({ src, className }) => (
//   <video className={classNames('h-full w-full origin-center object-cover', className)} preload="metadata">
//     <source src={`${src}#t=2`} />
//   </video>
// ));
