import { Time } from '@binhatch/servicetale-api';
import React from 'react';
import { useIntl } from 'react-intl';
import * as yup from 'yup';

import { translations } from '@/locales';

import { Input } from '@/components/Form/Input';
import { NumberInput } from '@/components/Form/NumberInput';
import { ValidatedField } from '@/components/Form/ValidatedField';

import { InlineForm, Props } from './InlineForm';

const schema = yup
  .object({
    name: yup.string().required().label(translations.fields.time.label),
    quantity: yup.number().min(1).required().label(translations.fields.quantity.label),
    rate: yup.number().required().label(translations.fields.price.label)
  })
  .required();

export const TimeForm: React.FC<Omit<Props<yup.SchemaOf<Time>>, 'schema' | 'children'>> = ({ onSubmit, ...props }) => {
  const intl = useIntl();

  return (
    <InlineForm
      {...props}
      {...{ schema }}
      onSubmit={(values, form) => onSubmit({ ...values, unitType: 'hour', id: '' }, form)}
    >
      <ValidatedField
        autoFocus
        className="col-span-full xl:flex-1"
        field={Input}
        fieldClassName="w-full"
        id="time"
        name="name"
        placeholder={intl.formatMessage({ id: translations.fields.time.placeholder })}
        type="text"
      />

      <ValidatedField
        as={NumberInput}
        field={Input}
        fieldClassName="w-full xl:w-48"
        id="quantity"
        includeThousandsSeparator
        name="quantity"
        placeholder={intl.formatMessage({ id: translations.fields.quantity.placeholder })}
        type="tel"
      />

      <ValidatedField
        allowDecimal
        as={NumberInput}
        field={Input}
        fieldClassName="w-full xl:w-48"
        id="price"
        includeThousandsSeparator
        name="rate"
        placeholder={intl.formatMessage({ id: translations.fields.price.placeholder })}
        type="tel"
      />
    </InlineForm>
  );
};
