import { parsePhoneNumber } from 'awesome-phonenumber';
import * as yup from 'yup';

import { translations } from '@/locales';

type Validation = (value?: string) => boolean;

const phoneValidation: Validation = (value) => {
  if (!value) return true;

  const pn = parsePhoneNumber(value);

  return pn.valid && pn.canBeInternationallyDialled;
};

export const internationalPhone = yup.string().test('phone', translations.validation.mixed.default, phoneValidation);
