import { findClients } from '@/data/client';

import { useRemoteData } from './useRemoteData';
import { useWorkshopId } from './useWorkshopId';

interface UseClientOptions {
  clientId?: string;
}

export const useClient = ({ clientId }: UseClientOptions) => {
  const workshopId = useWorkshopId();

  return useRemoteData(
    { key: 'getClient', workshopId, clientId, skip: !clientId },
    async ({ workshopId, clientId }) => {
      return findClients({ workshopId }).then((r) => r.users.find((u) => u.id === clientId));
    }
  );
};
