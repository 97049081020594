import { getExample, parsePhoneNumber } from 'awesome-phonenumber';
import React from 'react';

import { Input } from '@/components/Form/Input';
import { MaskedInput } from '@/components/Form/MaskedInput';

const generateMaskFromFormat = (format: string) => {
  const letterPattern = /\d/;

  /**
   * make first 0 literal intead of any digit so it's prefilled
   * example:
   * - placeholder = 0712 034 567
   * - patterns = ['0', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/]
   */
  return format.split('').reduce(
    (data, key) => {
      const matched = letterPattern.test(key);
      const pattern = !matched || (key === '0' && !data.matched) ? key : /\d/;

      return {
        patterns: [...data.patterns, pattern],
        matched: matched || data.matched
      };
    },
    { patterns: [] as (RegExp | string)[], matched: false }
  ).patterns;
};

interface Props extends React.ComponentProps<typeof Input> {
  defaultPrefix?: string;
}

export const PhoneInput = React.forwardRef<HTMLInputElement, Props>(({ value, defaultPrefix, ...props }, ref) => {
  const { regionCode, number } = React.useMemo(() => {
    const pn = parsePhoneNumber(value || defaultPrefix);

    const regionCode = pn.regionCode;
    const number = pn.number?.international ?? value;

    return { regionCode, number };
  }, [value, defaultPrefix]);

  const placeholder = React.useMemo(
    () => (regionCode ? getExample(regionCode, 'mobile').number?.international ?? defaultPrefix : defaultPrefix) ?? '',
    [regionCode, defaultPrefix]
  );

  const mask = generateMaskFromFormat(placeholder);

  return <Input as={MaskedInput} {...props} {...{ ref, mask, placeholder }} value={number} />;
});
