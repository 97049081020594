import classnames from 'classnames';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { translations } from '@/locales';

import { InstanceProps, ModalHeader, ModalLayout, ModalPrimaryButton, ModalSecondaryButton } from '@/components/Modal';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';

export interface Props extends InstanceProps<void, { callback?: () => Promise<void> }> {}

export const DeleteConfirmationModal = React.forwardRef<HTMLDivElement, Props>(
  ({ initialFocus, data, className, onAction, onClose }, ref) => {
    const onSubmit = React.useCallback(async () => {
      await data.callback?.();
      onAction();
    }, [data, onAction]);

    return (
      <div {...{ ref }} className={classnames(className, 'max-w-md space-y-6')}>
        <div className="flex gap-4">
          <div className="flex h-12 w-12 items-center justify-center rounded-full bg-red-100">
            <ExclamationTriangleIcon aria-hidden="true" className="h-6 w-6 text-red-600" />
          </div>

          <ModalLayout className="flex-1">
            <ModalHeader {...{ onClose }}>
              <FormattedMessage id={translations.modals.deleteConfirmation.title} />
            </ModalHeader>

            <div className="text-sm">
              <FormattedMessage id={translations.modals.deleteConfirmation.message} />
            </div>
          </ModalLayout>
        </div>

        <div className="flex flex-row-reverse space-x-2">
          <ModalPrimaryButton appearance="danger" ref={initialFocus} onAction={onSubmit}>
            <FormattedMessage id={translations.modals.deleteConfirmation.delete} />
          </ModalPrimaryButton>

          <ModalSecondaryButton {...{ onClose }}>
            <FormattedMessage id={translations.buttons.back} />
          </ModalSecondaryButton>
        </div>
      </div>
    );
  }
);
