import { generatePath } from 'react-router';

interface RouteConfig {
  [x: string]: string | RouteConfig | Function;
}

const createRoutes = <C extends RouteConfig>(base: string, config: C): C => {
  return Object.entries(config).reduce<C>((config, [key, value]) => {
    switch (typeof value) {
      case 'string': {
        // @ts-ignore
        config[key] = `${base}${value}`;
        break;
      }
      case 'function': {
        // @ts-ignore
        config[key] = value;
        break;
      }
      case 'object': {
        // @ts-ignore
        config[key] = createRoutes(base, value);
        break;
      }
    }

    return config;
  }, config);
};

export const signIn = `/login`;
export const register = `/register`;
export const confirmation = `/confirm`;

export const forgotPassword = createRoutes(`/forgot-password`, {
  send: '',
  reset: '/reset'
});

export const dashboard = '/dashboard';

export const appointments = '/appointments';

export const clients = createRoutes('/clients', {
  root: '',
  new: '/new',
  one: '/:clientId',
  getOne: (o: { clientId: string }) => generatePath(clients.one, o)
});

export const work = createRoutes('/work', {
  root: '',
  new: '/new',
  one: '/:taleId',
  getOne: (o: { taleId: string }) => generatePath(work.one, o),
  preview: '/:taleId/preview',
  getPreview: (o: { taleId: string }) => generatePath(work.preview, o)
});

export const settings = createRoutes('/settings', {
  service: '/service',
  application: '/application'
});

export const help = '/help';

export const workshop = createRoutes('/workshop', {
  register: '/register',
  subscription: createRoutes('/subscription', {
    root: '',
    validate: '/validate'
  })
});
