import classNames from 'classnames';
import React from 'react';

interface Props {
  size?: string;
  className?: string;
}

export const Avatar: React.FC<React.PropsWithChildren<Props>> = ({ size = 'h-10 w-10', className, ...props }) => (
  <div {...props} className={classNames('relative overflow-hidden rounded-full', size, className)} />
);
