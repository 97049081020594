import { Chapter, ChapterKind, ChapterState, TaleStatus } from '@binhatch/servicetale-api';
import { PhotoIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import React from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';

import { translations } from '@/locales';

import { SelectedChapter } from '@/pages/WorkDetailPage/useSelectedChapter';

interface AssetCountProps {
  count: number;
}

const AssetCount: React.FC<AssetCountProps> = ({ count }) => (
  <React.Fragment>
    {count > 0 && (
      <div className="flex items-center text-sm">
        {count}
        <PhotoIcon className="ml-2 h-5 w-5" />
      </div>
    )}
  </React.Fragment>
);

interface TimelineEntryProps {
  active?: boolean;
  current?: boolean;
  disabled?: boolean;
  completedAt?: Date;
  onClick?(): void;
}

const TimelineEntry: React.FC<React.PropsWithChildren<TimelineEntryProps>> = ({
  active,
  current,
  completedAt,
  children,
  onClick,
  disabled = !onClick
}) => (
  <li className="group">
    <button
      className={classNames(
        'peer relative flex w-full gap-x-4 rounded-lg py-2 px-4 text-left',
        active ? 'bg-white' : classNames('bg-[#F2F2F2]', disabled ? 'cursor-default' : 'cursor-pointer hover:bg-white')
      )}
      {...{ disabled, onClick }}
    >
      <div
        className={classNames(
          'absolute -bottom-4 left-4 top-0 flex w-6 justify-center group-first:top-4 group-last:h-4'
        )}
      >
        <div className="w-px bg-gray-300" />
      </div>

      <div
        className={classNames(
          'relative flex h-6 w-6 flex-none items-center justify-center',
          active ? 'bg-white' : classNames('bg-[#F2F2F2]', { 'group-hover:bg-white': !disabled })
        )}
      >
        <div
          className={classNames(
            'h-1.5 w-1.5 rounded-full ring-1',
            active
              ? 'bg-[#2E4C86] ring-[#2E4C86]'
              : completedAt
              ? classNames('bg-gray-400 ring-gray-400')
              : classNames('bg-[#F2F2F2] ring-gray-400', { 'group-hover:bg-white': !disabled })
          )}
        />
      </div>

      <div className="text-gray-500">
        <div className={classNames('flex-auto', !active && disabled ? 'text-gray-500' : 'text-gray-900')}>
          {children}
        </div>

        {current && (
          <div className="text-sm">
            <FormattedMessage id={translations.pages.workDetail.timeline.progress} />
          </div>
        )}

        {!!completedAt && (
          <time className="flex-none text-sm" dateTime={completedAt?.toISOString()}>
            <FormattedDate dateStyle="long" timeStyle="short" value={completedAt} />
          </time>
        )}
      </div>
    </button>
  </li>
);

interface TimelineProps {
  completed?: Chapter[];
  incompleted?: Chapter[];
  status?: TaleStatus;
  lastUpdatedAt?: Date;
}

export const Timeline: React.FC<TimelineProps> = ({ incompleted, completed, status, lastUpdatedAt }) => {
  const { selected, current, select } = SelectedChapter.useContainer();

  const aborted = !!status && ([TaleStatus.Canceled, TaleStatus.Refused] as TaleStatus[]).includes(status);

  const selectedChapterId = typeof selected === 'object' ? selected.id : undefined;

  return (
    <React.Fragment>
      <ul className="relative z-0 inline-flex w-full flex-col space-y-2" role="list">
        {completed?.map((chapter) => (
          <TimelineEntry
            active={chapter.id === selectedChapterId}
            completedAt={chapter.completedAt ? new Date(chapter.completedAt) : undefined}
            disabled={chapter.kind === ChapterKind.Dropoff || (aborted && chapter.state === ChapterState.Pending)}
            key={chapter.id}
            onClick={() => select?.(chapter)}
          >
            <div className="font-medium">
              <FormattedMessage id={translations.enum.workStatus[chapter.kind]} />
            </div>

            {chapter.kind === ChapterKind.CarReception && (
              <AssetCount count={chapter.carStatus.damages.reduce((c, d) => c + d.media?.length ?? 0, 0)} />
            )}

            {chapter.kind === ChapterKind.Inspection && (
              <AssetCount count={chapter.findings.reduce((c, d) => c + d.media?.length ?? 0, 0)} />
            )}
          </TimelineEntry>
        ))}

        {incompleted?.map((chapter) => (
          <TimelineEntry
            active={chapter.id === selectedChapterId}
            current={chapter === current}
            disabled={chapter !== current || (aborted && chapter.kind === ChapterKind.Dropoff)}
            key={chapter.id}
            onClick={() => select?.(chapter)}
          >
            <div className="font-medium">
              <FormattedMessage id={translations.enum.workStatus[chapter.kind]} />
            </div>
          </TimelineEntry>
        ))}

        {!!status && ([TaleStatus.InProgress, TaleStatus.Completed] as TaleStatus[]).includes(status) && (
          <TimelineEntry
            active={selected === status}
            completedAt={status === TaleStatus.Completed ? lastUpdatedAt : undefined}
            disabled={status !== TaleStatus.Completed}
            onClick={() => select(TaleStatus.Completed)}
          >
            <div className="font-medium">
              <FormattedMessage id={translations.enum.workStatus.completed} />
            </div>
          </TimelineEntry>
        )}

        {status === TaleStatus.Canceled && (
          <TimelineEntry
            active={selected === status}
            completedAt={lastUpdatedAt}
            onClick={() => select(TaleStatus.Canceled)}
          >
            <div className="font-medium">
              <FormattedMessage id={translations.enum.workStatus.canceled} />
            </div>
          </TimelineEntry>
        )}

        {status === TaleStatus.Refused && (
          <TimelineEntry
            active={selected === status}
            completedAt={lastUpdatedAt}
            onClick={() => select(TaleStatus.Refused)}
          >
            <div className="font-medium">
              <FormattedMessage id={translations.enum.workStatus.refused} />
            </div>
          </TimelineEntry>
        )}
      </ul>
    </React.Fragment>
  );
};
