import classNames from 'classnames';
import React from 'react';

import { AsComponent } from '@/components/As';

import { Input, Props as InputProps } from './Input';

export type Props<T extends AsComponent> = InputProps<T> & {
  description?: React.ReactNode;
  id?: string;
  inputClassName?: string;
};

export const InputWithLabel = React.forwardRef(function InputWithLabel<T extends AsComponent>(
  { description, label, className, inputClassName, id, ...props }: Props<T>,
  ref: React.Ref<any>
) {
  return (
    <div className={classNames('flex flex-col', className)}>
      <div className="mb-1 flex items-center justify-between text-sm leading-5">
        <label htmlFor={id}>{label}</label>

        {!!description && <span className="text-gray-400">{description}</span>}
      </div>

      <Input {...props} {...{ id, ref }} className={inputClassName} />
    </div>
  );
});
