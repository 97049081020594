import { CheckCircleIcon } from '@heroicons/react/24/outline';
import classnames from 'classnames';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { translations } from '@/locales';

import { InstanceProps, ModalHeader, ModalLayout, ModalPrimaryButton, ModalSecondaryButton } from '@/components/Modal';

export interface Props
  extends InstanceProps<void, { title: string; message: string; button: string; callback: () => Promise<void> }> {}

export const ConfirmCompleteModal = React.forwardRef<HTMLDivElement, Props>(
  ({ data: { title, message, button, callback }, initialFocus, className, onAction, onClose }, ref) => (
    <div {...{ ref }} className={classnames(className, 'max-w-sm')}>
      <ModalLayout className="space-y-6">
        <ModalHeader {...{ onClose }}>
          <FormattedMessage id={title} />
        </ModalHeader>

        <div className="flex justify-center">
          <CheckCircleIcon className="h-20 w-20" />
        </div>

        <div className="text-sm">
          <FormattedMessage id={message} />
        </div>

        <div className="flex flex-row-reverse space-x-2">
          <ModalPrimaryButton ref={initialFocus} onAction={() => callback().then(onAction)}>
            <FormattedMessage id={button} />
          </ModalPrimaryButton>

          <ModalSecondaryButton {...{ onClose }}>
            <FormattedMessage id={translations.buttons.back} />
          </ModalSecondaryButton>
        </div>
      </ModalLayout>
    </div>
  )
);
