import {
  ChapterKind,
  ChapterState,
  ChapterStateRequest,
  InspectionChapter,
  InspectionChapterRequest,
  Priority,
  WorkType
} from '@binhatch/servicetale-api';
import { MagnifyingGlassIcon, PlusSmallIcon, XMarkIcon } from '@heroicons/react/24/outline';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import * as yup from 'yup';

import { taleApi } from '@/integrations/api';
import { translations } from '@/locales';

import { Button } from '@/components/Button';
import { Form } from '@/components/Form';
import { Select } from '@/components/Form/Select';
import { ValidatedField } from '@/components/Form/ValidatedField';
import { WorkItemSelect } from '@/components/Form/WorkItemSelect';
import { Modal, useModal } from '@/components/Modal';

import { DeleteConfirmationModal } from '@/modals/DeleteConfirmationModal';

import { StatefulContainer } from '@/components/StatefulContainer';
import { useTale } from '@/hooks/useTale';
import { useWorkshopId } from '@/hooks/useWorkshopId';
import { replaceItemAtIndex } from '@/utils/array';
import { uploadFile } from '@/utils/uploadFile';
import { CheckIcon } from '@heroicons/react/20/solid';
import classNames from 'classnames';
import { Field } from 'react-final-form';
import { Autosave } from './Autosave';
import { CarImageTile } from './CarImageTile';
import { Card } from './Card';
import { ConfirmCompleteModal } from './ConfirmCompleteModal';
import { UploadModal } from './UploadModal';
import { WorkHeading } from './WorkHeading';
import { WorkRiskItem } from './WorkRiskItem';

const findingsSchema = yup.array(
  yup
    .object({
      id: yup.string(),
      name: yup.string().required().label(translations.fields.operations.label),
      media: yup.array().of(yup.string().required()).min(0).required(),
      priority: yup.mixed<Priority>().required(),
      type: yup.mixed<WorkType>().required()
    })
    .required()
);

const schema = yup
  .object({
    findings: findingsSchema.min(0).label(translations.fields.operations.label)
  })
  .required();

const requiredSchema = yup
  .object({
    findings: findingsSchema.min(1).label(translations.fields.operations.label)
  })
  .required();

interface TaleChapter extends InspectionChapter {
  kind: (typeof ChapterKind)['Inspection'];
}

interface Props {
  taleId: string;
  chapter: TaleChapter;
  loading?: boolean;
  readOnly?: boolean;
}

const convertChapterToForm = (chapter: TaleChapter): yup.InferType<typeof schema> => ({
  findings:
    chapter.findings?.map((f) => ({
      ...f,
      id: f.id,
      priority: f.priority ?? Priority.Low,
      type: f.type ?? WorkType.Requested
    })) ?? []
});

const convertFormToChapter = ({ findings }: yup.InferType<typeof schema>): InspectionChapterRequest => ({
  kind: ChapterKind.Inspection,
  state: ChapterStateRequest.Draft,
  findings: findings?.map((o) => ({ ...o, media: o.media ?? [] })) ?? []
});

export const InspectionForm: React.FC<React.PropsWithChildren<Props>> = ({
  taleId,
  chapter,
  readOnly,
  loading,
  children
}) => {
  const intl = useIntl();

  const workshopId = useWorkshopId();

  const deleteModal = useModal(DeleteConfirmationModal);
  const uploadModalModal = useModal(UploadModal);
  const confirmModal = useModal(ConfirmCompleteModal);

  const tale = useTale({ taleId });

  const initialValues = React.useMemo<yup.InferType<typeof schema>>(() => convertChapterToForm(chapter), [chapter]);

  const onSubmit = React.useCallback(
    async (values: yup.InferType<typeof schema>) => {
      await taleApi.updateTaleChapter(taleId, { ...convertFormToChapter(values), kind: ChapterKind.Inspection });
    },
    [taleId]
  );

  return (
    <React.Fragment>
      <StatefulContainer state={chapter.state !== ChapterState.Pending || chapter.createdAt === chapter.updatedAt}>
        {({ state, setState }) => (
          <Form {...{ schema, initialValues, onSubmit }}>
            {({ values, submitting, handleSubmit, form }) => (
              <React.Fragment>
                <WorkHeading loading={loading || submitting}>
                  {chapter.state === ChapterState.Pending && !!workshopId && state && (
                    <Button
                      appearance="primary"
                      className="h-10 px-4"
                      disabled={!requiredSchema.isValidSync(values)}
                      type="button"
                      onClick={() => {
                        confirmModal
                          .open({
                            ...translations.pages.workDetail.confirmInspectionModal,
                            callback: async () => {
                              await taleApi.updateTaleChapter(taleId, {
                                ...convertFormToChapter(values),
                                kind: ChapterKind.Inspection,
                                state: ChapterStateRequest.Final
                              });

                              await tale.mutate();
                            }
                          })
                          .catch(() => null);
                      }}
                    >
                      <CheckIcon className="mr-2 h-4 w-4" />
                      <FormattedMessage id={translations.pages.workDetail.cta.inspect} />
                    </Button>
                  )}
                </WorkHeading>

                {children}

                <React.Fragment>
                  {state ? (
                    <form className="m-0 grid grid-cols-2 gap-6" onSubmit={handleSubmit}>
                      {!readOnly && <Autosave />}

                      <Card className="col-span-2 space-y-4">
                        <h2 className="font-medium">
                          <FormattedMessage id={translations.pages.workDetail.findings.title} />
                        </h2>

                        <Field name="findings" render={() => null} />

                        {!!values.findings?.length && (
                          <ol className="grid gap-2">
                            {values.findings?.map((item, index) => (
                              <li className="group flex gap-2" key={index}>
                                <div className="h-10 w-10 flex-shrink-0 rounded bg-[#F2F2F2] text-center font-bold leading-10">
                                  {index + 1}
                                </div>

                                <div className="flex flex-1 flex-col gap-2">
                                  <div className="flex flex-col flex-wrap justify-end gap-2 xl:flex-row">
                                    <ValidatedField
                                      autoFocus={!item.id}
                                      className="w-full lg:flex-1"
                                      field={WorkItemSelect}
                                      id={`operation-item-${index + 1}`}
                                      name={`findings[${index}].name`}
                                      placeholder={intl.formatMessage({
                                        id: translations.fields.operations.placeholder
                                      })}
                                      {...{ readOnly }}
                                    />

                                    <ValidatedField
                                      className="lg:w-60"
                                      field={Select}
                                      id={`operation-priority-${index + 1}`}
                                      items={[Priority.Low, Priority.Medium, Priority.High].map((value) => ({
                                        value,
                                        name: intl.formatMessage({ id: translations.enum.workRisk[value].name })
                                      }))}
                                      name={`findings[${index}].priority`}
                                      placeholder={intl.formatMessage({ id: translations.fields.risk.placeholder })}
                                      renderItem={({ props: { key, ...props }, item }: any) => (
                                        <WorkRiskItem key={key} {...props} priority={item.value} />
                                      )}
                                      {...{ readOnly }}
                                    />

                                    <div className="flex items-center justify-end">
                                      {!readOnly && (
                                        <React.Fragment>
                                          <Button
                                            appearance="transparent"
                                            className="h-10 px-4"
                                            type="button"
                                            onClick={() =>
                                              uploadModalModal
                                                .open({
                                                  onUpload: ({ file, onUploadProgress }) => {
                                                    return taleApi
                                                      .createUploadURL(taleId, {
                                                        mimeType: file.type,
                                                        contentLength: file.size
                                                      })
                                                      .then(async ({ data: { uploadURL, getURL, formData } }) => {
                                                        await uploadFile({
                                                          url: uploadURL,
                                                          formData,
                                                          file,
                                                          onUploadProgress
                                                        });

                                                        const findings = form.getFieldState('findings')?.value ?? [];

                                                        form.change(
                                                          'findings',
                                                          replaceItemAtIndex(findings, index, {
                                                            ...findings[index],
                                                            media: findings[index].media.concat(getURL)
                                                          })
                                                        );

                                                        return getURL;
                                                      });
                                                  }
                                                })
                                                .catch(() => null)
                                            }
                                          >
                                            <FormattedMessage id={translations.pages.workDetail.findings.file} />
                                          </Button>

                                          <Button
                                            appearance="secondary"
                                            className="h-10 w-10"
                                            type="button"
                                            onClick={() =>
                                              deleteModal
                                                .open({
                                                  callback: async () => {
                                                    form.change(
                                                      'findings',
                                                      values.findings?.filter((o) => o !== item) ?? []
                                                    );
                                                  }
                                                })
                                                .catch(() => null)
                                            }
                                          >
                                            <XMarkIcon className="h-5 w-5" />
                                            <div className="sr-only">
                                              <FormattedMessage id={translations.buttons.delete} />
                                            </div>
                                          </Button>
                                        </React.Fragment>
                                      )}
                                    </div>
                                  </div>

                                  <ValidatedField
                                    field={() => (
                                      <ul className="flex flex-wrap gap-2">
                                        {item.media?.map((media) => (
                                          <li key={media}>
                                            <CarImageTile
                                              className="w-20"
                                              src={media}
                                              onRemove={() => {
                                                deleteModal
                                                  .open({})
                                                  .then(() => {
                                                    const findings = form.getFieldState('findings')?.value ?? [];

                                                    form.change(
                                                      'findings',
                                                      replaceItemAtIndex(findings, index, {
                                                        ...findings[index],
                                                        media: findings[index].media.filter((m) => m !== media)
                                                      })
                                                    );
                                                  })
                                                  .catch(() => null);
                                              }}
                                            />
                                          </li>
                                        ))}
                                      </ul>
                                    )}
                                    id={`finding-${index}-media`}
                                    name={`findings[${index}].media`}
                                  />
                                </div>
                              </li>
                            ))}
                          </ol>
                        )}

                        {!readOnly && (
                          <div>
                            {!values.findings?.length && (
                              <div className="py-4 text-center">
                                <FormattedMessage id={translations.pages.workDetail.findings.empty} />
                              </div>
                            )}

                            <div className={classNames('flex', { 'justify-center': !values.findings?.length })}>
                              <Button
                                appearance="outline"
                                className="h-10 px-4"
                                type="button"
                                onClick={() => {
                                  form.change('findings', [
                                    ...(values.findings ?? []),
                                    {
                                      id: undefined,
                                      name: '',
                                      priority: Priority.Low,
                                      media: [],
                                      type: WorkType.Additional
                                    }
                                  ]);
                                }}
                              >
                                <PlusSmallIcon className="mr-2 h-5 w-5" />
                                <FormattedMessage id={translations.pages.workDetail.findings.add} />
                              </Button>
                            </div>
                          </div>
                        )}
                      </Card>
                    </form>
                  ) : (
                    <Card className="flex flex-col items-center justify-center gap-6 py-20">
                      <div className="h-40 w-40 rounded-full bg-gray-100" />

                      <div className="text-center">
                        <div className="font-medium">Autovehiculul a fost recepționat</div>

                        <div>Clientul va vedea când se schimbă starea lucrării</div>
                      </div>

                      <Button appearance="primary" className="h-10 px-4" onClick={() => setState(true)}>
                        <MagnifyingGlassIcon className="mr-2 h-5 w-5" />
                        Începe constatarea
                      </Button>
                    </Card>
                  )}
                </React.Fragment>
              </React.Fragment>
            )}
          </Form>
        )}
      </StatefulContainer>

      <Modal {...deleteModal.props} />
      <Modal {...uploadModalModal.props} />
      <Modal {...confirmModal.props} />
    </React.Fragment>
  );
};
