import React from 'react';

import { useCar } from '@/hooks/useCar';
import { useClient } from '@/hooks/useClient';
import { useTale } from '@/hooks/useTale';

import { CarIdentifier } from '@/components/CarIdentifier';
import { ClientIdentifier } from '@/components/ClientIdentifier';

import { Card } from './Card';

interface Props {
  taleId: string;
}

export const WorkDependency: React.FC<React.PropsWithChildren<Props>> = ({ taleId }) => {
  const tale = useTale({ taleId });

  const clientId = tale.data?.userId;
  const carId = tale.data?.car?.vin;

  const client = useClient({ clientId });
  const car = useCar({ clientId, carId });

  return (
    <React.Fragment>
      <div className="grid gap-6 xl:grid-cols-2">
        <Card className="flex items-center justify-between">
          <ClientIdentifier client={client.isLoading || client.isValidating ? undefined : client.data} />
        </Card>

        <Card className="flex items-center justify-between">
          <CarIdentifier car={car.isLoading || car.isValidating ? undefined : car.data} className="w-full max-w-sm" />
        </Card>
      </div>
    </React.Fragment>
  );
};
