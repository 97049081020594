import { Priority, WorkType } from '@binhatch/servicetale-api';
import classNames from 'classnames';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { translations } from '@/locales';

const riskColors: Record<Priority, string> = {
  [Priority.Low]: 'bg-[#92E185]',
  [Priority.Medium]: 'bg-[#FFF5BF]',
  [Priority.High]: 'bg-[#BD0D0D] text-white'
};

interface Props {
  priority?: Priority;
  type?: WorkType;
}

export const WorkItemBadge: React.FC<Props> = ({ type, priority }) => (
  <div
    className={classNames(
      'inline-flex rounded px-2 py-0.5 text-sm',
      type === 'requested' || !priority ? 'bg-[#52BFEF] text-[#202020]' : riskColors[priority]
    )}
  >
    {type === 'requested' || !priority ? (
      'Sesizat'
    ) : (
      <FormattedMessage id={translations.enum.workRisk[priority].name} />
    )}
  </div>
);
