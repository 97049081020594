import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Navigate, Link as RouterLink, useLocation } from 'react-router-dom';
import * as yup from 'yup';

import { resetPassword } from '@/integrations/cognito';
import { translations } from '@/locales';
import { strictPassword } from '@/schemas/password';
import { verificationCode, verificationCodeSize } from '@/schemas/verificationCode';
import * as urls from '@/utils/url';

import { Auth } from '@/containers/useAuth';

import { Form } from '@/components/Form';
import { InputWithLabel } from '@/components/Form/InputWithLabel';
import { OtpInput } from '@/components/Form/OtpInput';
import { ValidatedField } from '@/components/Form/ValidatedField';
import { Link } from '@/components/Link';
import { LoadingButton } from '@/components/LoadingButton';
import { PasswordValidity } from '@/components/PasswordValidity';
import { SubmitError } from '@/components/SubmitError';

export const schema = yup
  .object({
    code: verificationCode.required().label(translations.fields.code.label),
    password: strictPassword.required().label(translations.fields.password.label)
  })
  .required();

export const ResetPasswordPage: React.FC = () => {
  const intl = useIntl();
  const { login } = Auth.useContainer();
  const { state } = useLocation();

  const email = typeof state?.email === 'string' ? (state.email as string) : undefined;

  const onSubmit = React.useCallback(
    async ({ code, password }: yup.InferType<typeof schema>) => {
      await resetPassword({ email: email!, code, password });
      await login({ email: email!, password });
    },
    [login, email]
  );

  if (!email) return <Navigate replace to={urls.register} />;

  return (
    <Form {...{ schema, onSubmit }}>
      {({ values, submitting, submitError, handleSubmit }) => (
        <form className="m-0 w-full max-w-sm" onSubmit={handleSubmit}>
          <div className="space-y-6">
            <h1 className="text-xl font-medium">
              <FormattedMessage id={translations.pages.resetPassword.title} />
            </h1>

            <div>
              <FormattedMessage id={translations.pages.resetPassword.message} />
            </div>

            <div className="space-y-6">
              <ValidatedField
                as={OtpInput}
                field={InputWithLabel}
                id="code"
                label={<FormattedMessage id={translations.fields.code.label} />}
                name="code"
                readOnly={submitting}
                size={verificationCodeSize}
                type="tel"
              />

              <div className="space-y-2">
                <ValidatedField
                  field={InputWithLabel}
                  id="password"
                  label={<FormattedMessage id={translations.fields.newPassword.label} />}
                  name="password"
                  placeholder={intl.formatMessage({ id: translations.fields.newPassword.placeholder })}
                  readOnly={submitting}
                  type="password"
                />

                <PasswordValidity password={values.password} />
              </div>

              <SubmitError error={submitError} />

              <LoadingButton appearance="primary" className="h-10 w-full" loading={submitting} type="submit">
                <FormattedMessage id={translations.pages.resetPassword.cta} />
              </LoadingButton>

              <div className="text-center">
                <Link as={RouterLink} state={{ email }} to={urls.signIn}>
                  <FormattedMessage id={translations.pages.resetPassword.remember} />
                </Link>
              </div>
            </div>
          </div>
        </form>
      )}
    </Form>
  );
};
