import { CheckIcon } from '@heroicons/react/20/solid';
import classNames from 'classnames';
import React from 'react';

export interface Props {
  active?: boolean;
  isSelected?: boolean;
  disabled?: boolean;
  className?: string;
  onClick?(): void;
}

export const SelectItem = React.forwardRef<HTMLLIElement, React.PropsWithChildren<Props>>(
  ({ active, isSelected, disabled, className, onClick, children, ...rest }, ref) => (
    <li
      {...rest}
      {...{ ref }}
      className={classNames(
        `group relative select-none py-2 pr-2 pl-9`,
        isSelected ? 'bg-slate-100' : active && 'bg-slate-100',
        disabled ? `cursor-default` : `cursor-pointer`,
        className
      )}
      onClick={disabled ? undefined : onClick}
    >
      {isSelected && (
        <span aria-hidden="true" className="absolute inset-y-0 left-0 flex items-center pl-2">
          <CheckIcon className="h-5 w-5" />
        </span>
      )}

      <span className="block truncate text-sm">{children}</span>
    </li>
  )
);
