import * as React from 'react';

import { Input } from './Input';

export interface Props extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
  value?: string;
  size: number;
  onChange(value: string): void;
}

export const OtpInput: React.FC<Props> = ({ value, size: length, onChange, readOnly, disabled }) => {
  const refs = React.useMemo(() => Array.from({ length }).map(() => React.createRef<HTMLInputElement>()), [length]);

  const inputs = React.useMemo(
    () =>
      refs.map((_, index) => {
        const normalizedValue = (value ?? '').replace(/[^0-9]/, '');

        return {
          ref: refs[index],
          value: normalizedValue.substring(index, index + 1),
          onPaste: (event: React.ClipboardEvent<HTMLInputElement>) => {
            event.preventDefault();

            return onChange(event.clipboardData.getData('text').substring(0, length));
          },
          onKeyDown: (event: React.KeyboardEvent<HTMLInputElement>) => {
            switch (event.key) {
              case 'Backspace':
                if ((event.target as HTMLInputElement).value?.length > 0) return;

                refs[index - 1]?.current?.select();
                event.preventDefault();
                break;
              case 'ArrowLeft':
                refs[index - 1]?.current?.select();
                event.preventDefault();
                break;
              case 'ArrowRight':
                refs[index + 1]?.current?.select();
                event.preventDefault();
                break;
            }
          },
          onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
            const value = event.target.value.replace(/[^0-9]/, '');

            const start = normalizedValue.substring(0, index) ?? '';
            const end = normalizedValue.substring(index + 1, normalizedValue.length);

            if (value.length > 0) refs[index + 1]?.current?.select();

            return onChange(start + value + end);
          }
        };
      }),
    [refs, value, length, onChange]
  );

  return (
    <div>
      <div className="-m-2 flex items-center">
        {inputs.map(({ ref, value, onChange, onPaste, onKeyDown }, index) => (
          <Input
            className="m-2 w-full flex-1 text-center"
            key={index}
            maxLength={1}
            minLength={1}
            placeholder="-"
            type="tel"
            {...{ ref, value, onChange, onPaste, onKeyDown, readOnly, disabled }}
          />
        ))}
      </div>
    </div>
  );
};
