import {
  AdjustmentsVerticalIcon,
  ArrowLeftOnRectangleIcon,
  Bars3Icon,
  CalendarIcon,
  Cog8ToothIcon,
  LifebuoyIcon,
  Squares2X2Icon,
  UserIcon,
  UsersIcon,
  WrenchIcon,
  XMarkIcon
} from '@heroicons/react/24/outline';
import classNames from 'classnames';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { NavLink } from 'react-router-dom';

import { VERSION } from '@/config';
import { translations } from '@/locales';
import * as urls from '@/utils/url';

import { Auth } from '@/containers/useAuth';
import { SideNavigation as NavigationState } from '@/containers/useSideNavigation';

import { useWorkshop } from '@/containers/useWorkshop';
import { Logo } from './Logo';

interface NavigationLinkProps {
  to: string;
  className?: string;
}

export const NavigationLink: React.FC<React.PropsWithChildren<NavigationLinkProps>> = ({ className, ...props }) => (
  <NavLink
    {...props}
    className={({ isActive }) =>
      classNames(
        'block rounded p-4 transition-colors',
        isActive ? 'bg-[#D6F2FF]' : 'bg-white hover:bg-[#F0FAFF]',
        className
      )
    }
  />
);

interface NavigationLinkWithIconProps {
  icon: React.ComponentType<React.SVGProps<SVGSVGElement>>;
  className?: string;
  iconClassName?: string;
}

export const NavigationContentWithIcon: React.FC<React.PropsWithChildren<NavigationLinkWithIconProps>> = ({
  icon: Icon,
  className,
  iconClassName,
  children,
  ...props
}) => {
  return (
    <div {...props} className={classNames('flex items-center lg:justify-center xl:justify-start', className)}>
      <Icon className={classNames('mr-3 h-6 w-6 flex-shrink-0 lg:mr-0 xl:mr-3', iconClassName)} />
      <div className="lg:hidden xl:block">{children}</div>
    </div>
  );
};

interface NavigationButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {}

export const NavigationButton: React.FC<React.PropsWithChildren<NavigationButtonProps>> = ({ className, ...props }) => (
  <button
    {...props}
    className={classNames('w-full rounded bg-white p-4 transition-colors hover:bg-[#F0FAFF]', className)}
  />
);

export const Navigation: React.FC = () => {
  const { workshop } = useWorkshop();
  const { user, logout } = Auth.useContainer();
  const { isOpen, toggle } = NavigationState.useContainer();

  return (
    <aside className="z-10 h-20 w-full lg:h-auto lg:w-24 xl:w-72">
      <div
        className={classNames(
          'fixed top-0 left-0 z-10 w-full bg-white lg:fixed lg:w-24 xl:w-72',
          isOpen ? 'h-full' : 'h-20 lg:h-full'
        )}
      >
        <div className="relative flex h-20 items-center justify-center p-4">
          <Logo size="large" />

          <button
            className={classNames(
              'absolute top-1/2 right-4 flex h-10 w-10 -translate-y-1/2 items-center justify-center rounded border border-gray-200 lg:hidden'
            )}
            onClick={toggle}
          >
            {isOpen ? <XMarkIcon className="h-6 w-6" /> : <Bars3Icon className="h-6 w-6" />}
          </button>
        </div>

        <div
          className={classNames('absolute top-20 bottom-0 left-0 w-full overflow-y-auto px-4 lg:block', {
            hidden: !isOpen
          })}
        >
          <ul>
            <li className="p-4 text-sm lg:hidden xl:block">
              <div className="font-medium">{workshop?.name}</div>
            </li>

            <li>
              <NavigationLink to={urls.dashboard}>
                <NavigationContentWithIcon icon={Squares2X2Icon}>
                  <FormattedMessage id={translations.navigation.dashboard} />
                </NavigationContentWithIcon>
              </NavigationLink>
            </li>

            <li>
              <NavigationLink to={urls.appointments}>
                <NavigationContentWithIcon icon={CalendarIcon}>
                  <FormattedMessage id={translations.navigation.appointments} />
                </NavigationContentWithIcon>
              </NavigationLink>
            </li>

            <li>
              <NavigationLink to={urls.work.root}>
                <NavigationContentWithIcon icon={WrenchIcon}>
                  <FormattedMessage id={translations.navigation.works} />
                </NavigationContentWithIcon>
              </NavigationLink>
            </li>

            <li>
              <NavigationLink to={urls.clients.root}>
                <NavigationContentWithIcon icon={UsersIcon}>
                  <FormattedMessage id={translations.navigation.clients} />
                </NavigationContentWithIcon>
              </NavigationLink>
            </li>

            <li>
              <NavigationLink to={urls.settings.service}>
                <NavigationContentWithIcon icon={AdjustmentsVerticalIcon}>
                  <FormattedMessage id={translations.navigation.settings.service} />
                </NavigationContentWithIcon>
              </NavigationLink>
            </li>

            <li>
              <NavigationLink to={urls.settings.application}>
                <NavigationContentWithIcon icon={Cog8ToothIcon}>
                  <FormattedMessage id={translations.navigation.settings.application} />
                </NavigationContentWithIcon>
              </NavigationLink>
            </li>

            <li>
              <NavigationLink to={urls.help}>
                <NavigationContentWithIcon icon={LifebuoyIcon}>
                  <FormattedMessage id={translations.navigation.help} />
                </NavigationContentWithIcon>
              </NavigationLink>
            </li>

            <li className="p-4">
              <div className="border-t border-gray-100" />
            </li>

            <li className="p-4 text-sm">
              <NavigationContentWithIcon icon={UserIcon} iconClassName="xl:hidden">
                <div className="min-w-0">
                  {(!!user?.firstName || !!user?.lastName) && (
                    <div className="font-medium">
                      <FormattedMessage
                        id={translations.navigation.name}
                        values={{ firstName: user.firstName, lastName: user.lastName }}
                      />
                    </div>
                  )}
                  <div className="truncate text-slate-500">{user?.email}</div>
                </div>
              </NavigationContentWithIcon>
            </li>

            <li>
              <NavigationButton onClick={() => logout()}>
                <NavigationContentWithIcon icon={ArrowLeftOnRectangleIcon}>Sign out</NavigationContentWithIcon>
              </NavigationButton>
            </li>
          </ul>

          <div className="absolute bottom-4 left-0 right-0 text-center text-sm text-gray-300">{VERSION}</div>
        </div>
      </div>
    </aside>
  );
};
