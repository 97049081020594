import { FormState } from 'final-form';
import debounce from 'lodash/debounce';
import React from 'react';
import { FormSpy, useForm } from 'react-final-form';

export const Autosave: React.FC = () => {
  const { submit } = useForm();

  const onSubmit = React.useMemo(() => debounce(submit, 1000), [submit]);

  const onChange = React.useCallback(
    ({ pristine, active, hasValidationErrors, dirtyFieldsSinceLastSubmit }: FormState<unknown>) => {
      if (pristine || active || hasValidationErrors || !Object.keys(dirtyFieldsSinceLastSubmit).length) return;

      onSubmit();
    },
    [onSubmit]
  );

  return (
    <FormSpy
      subscription={{
        pristine: true,
        active: true,
        hasValidationErrors: true,
        dirtyFieldsSinceLastSubmit: true,
        values: true
      }}
      {...{ onChange }}
    />
  );
};
