import { Part } from '@binhatch/servicetale-api';
import React from 'react';
import { useIntl } from 'react-intl';
import * as yup from 'yup';

import { translations } from '@/locales';

import { Input } from '@/components/Form/Input';
import { NumberInput } from '@/components/Form/NumberInput';
import { ValidatedField } from '@/components/Form/ValidatedField';

import { InlineForm, Props } from './InlineForm';

const schema = yup
  .object({
    name: yup.string().required().label(translations.fields.part.label),
    quantity: yup.number().min(1).required().label(translations.fields.quantity.label),
    price: yup.number().required().label(translations.fields.price.label)
  })
  .required();

export const PartForm: React.FC<Omit<Props<yup.SchemaOf<Part>>, 'schema' | 'children'>> = ({ onSubmit, ...props }) => {
  const intl = useIntl();

  return (
    <InlineForm {...props} {...{ schema }} onSubmit={(values, form) => onSubmit({ ...values, id: '' }, form)}>
      <ValidatedField
        autoFocus
        className="col-span-full md:flex-1"
        field={Input}
        fieldClassName="w-full"
        id="time"
        name="name"
        placeholder={intl.formatMessage({ id: translations.fields.part.placeholder })}
        type="text"
      />

      <ValidatedField
        as={NumberInput}
        field={Input}
        id="quantity"
        includeThousandsSeparator
        name="quantity"
        placeholder={intl.formatMessage({ id: translations.fields.quantity.placeholder })}
        type="text"
      />

      <div className="leading-10">x</div>

      <ValidatedField
        allowDecimal
        as={NumberInput}
        field={Input}
        fieldClassName="w-full md:w-48"
        id="price"
        includeThousandsSeparator
        name="price"
        placeholder={intl.formatMessage({ id: translations.fields.price.placeholder })}
        type="text"
      />
    </InlineForm>
  );
};
