import { workshopApi } from '@/integrations/api';

import { useRemoteData } from './useRemoteData';
import { useWorkshopId } from './useWorkshopId';

interface UseCarsOptions {
  clientId?: string;
  search?: string;
  token?: string;
}

export const useCars = ({ clientId, search, token }: UseCarsOptions) => {
  const workshopId = useWorkshopId();

  return useRemoteData(
    { key: 'getCars', workshopId, clientId, search, token },
    async ({ workshopId, clientId, search, token }) => {
      if (!workshopId) throw new Error('No workshop or client were defined.');

      return await workshopApi.listWorkshopCars(workshopId, token, search, clientId).then((r) => r.data);
    }
  );
};
