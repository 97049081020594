import { User } from '@binhatch/servicetale-api';
import classnames from 'classnames';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import * as yup from 'yup';

import { workshopApi } from '@/integrations/api';
import { translations } from '@/locales';

import { useClient } from '@/hooks/useClient';

import { Form } from '@/components/Form';
import { InputWithLabel } from '@/components/Form/InputWithLabel';
import { PhoneInput } from '@/components/Form/PhoneInput';
import { ValidatedField } from '@/components/Form/ValidatedField';
import { InstanceProps, ModalHeader, ModalLayout, ModalPrimaryButton, ModalSecondaryButton } from '@/components/Modal';
import { SubmitError } from '@/components/SubmitError';
import { useWorkshopId } from '@/hooks/useWorkshopId';
import { internationalPhone } from '@/schemas/phone';

export interface Props extends InstanceProps<User, { clientId?: string }> {}

export const schema = yup
  .object({
    firstName: yup.string().required().label(translations.fields.firstName.label),
    lastName: yup.string().required().label(translations.fields.lastName.label),
    email: yup.string().email().required().label(translations.fields.email.label),
    phoneNo: internationalPhone.required().label(translations.fields.phone.label)
  })
  .required();

export const UpdateClientModal = React.forwardRef<HTMLDivElement, Props>(
  ({ data: { clientId }, initialFocus, className, onAction, onClose }, ref) => {
    const intl = useIntl();
    const workshopId = useWorkshopId();

    const client = useClient({ clientId });

    const initialValues = React.useMemo<yup.InferType<typeof schema>>(
      () => ({
        ...client.data,
        firstName: client.data?.firstName ?? '',
        lastName: client.data?.lastName ?? '',
        email: client.data?.email ?? '',
        phoneNo: client.data?.phoneNo ?? ''
      }),
      [client.data]
    );

    const onSubmit = React.useCallback(
      async (values: yup.InferType<typeof schema>) => {
        return await workshopApi
          .createWorkshopUser(workshopId!, values)
          .then((r) => r.data.id)
          .then((id) => onAction({ id, ...values }));
      },
      [workshopId, onAction]
    );

    return (
      <div {...{ ref }} className={classnames(className, 'max-w-sm')}>
        <ModalLayout>
          <ModalHeader {...{ onClose }}>
            <FormattedMessage
              id={clientId ? translations.modals.updateClient.title : translations.modals.createClient.title}
            />
          </ModalHeader>

          <Form {...{ schema, initialValues, onSubmit }}>
            {({ submitting, submitError, handleSubmit }) => (
              <form className="m-0 grid gap-4" onSubmit={handleSubmit}>
                <ValidatedField
                  field={InputWithLabel}
                  id="email"
                  label={<FormattedMessage id={translations.fields.email.label} />}
                  name="email"
                  placeholder={intl.formatMessage({ id: translations.fields.email.placeholder })}
                  readOnly={!!submitting}
                  ref={initialFocus}
                  type="email"
                />

                <ValidatedField
                  as={PhoneInput}
                  defaultPrefix="+40"
                  field={InputWithLabel}
                  id="phone"
                  label={<FormattedMessage id={translations.fields.phone.label} />}
                  name="phoneNo"
                  placeholder={intl.formatMessage({ id: translations.fields.phone.placeholder })}
                  readOnly={!!submitting}
                  type="tel"
                />

                <ValidatedField
                  field={InputWithLabel}
                  id="first-name"
                  label={<FormattedMessage id={translations.fields.firstName.label} />}
                  name="firstName"
                  placeholder={intl.formatMessage({ id: translations.fields.firstName.placeholder })}
                  readOnly={!!submitting}
                />

                <ValidatedField
                  field={InputWithLabel}
                  id="last-name"
                  label={<FormattedMessage id={translations.fields.lastName.label} />}
                  name="lastName"
                  placeholder={intl.formatMessage({ id: translations.fields.lastName.placeholder })}
                  readOnly={!!submitting}
                />

                <SubmitError error={submitError} />

                <div className="flex flex-row-reverse space-x-2">
                  <ModalPrimaryButton onAction={() => handleSubmit()}>
                    <FormattedMessage id={clientId ? translations.buttons.edit : translations.buttons.add} />
                  </ModalPrimaryButton>

                  <ModalSecondaryButton {...{ onClose }}>
                    <FormattedMessage id={translations.buttons.back} />
                  </ModalSecondaryButton>
                </div>
              </form>
            )}
          </Form>
        </ModalLayout>
      </div>
    );
  }
);
