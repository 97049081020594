import { Time } from '@binhatch/servicetale-api';
import { PlusSmallIcon } from '@heroicons/react/20/solid';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { translations } from '@/locales';

import { Button } from '@/components/Button';
import { StatefulContainer } from '@/components/StatefulContainer';

import { TimeForm } from './TimeForm';
import { TimeItem } from './TimeItem';

interface Props {
  times: Time[];
  readOnly?: boolean;
  onCreate?(item: Time): void;
  onUpdate?(item: Time, index: number): void;
}

export const TimeList: React.FC<Props> = ({ times, readOnly, onCreate, onUpdate }) => (
  <StatefulContainer state={-1}>
    {({ state: editableIndex, setState: setEditableIndex }) => (
      <React.Fragment>
        {(!!times.length || editableIndex >= times.length) && (
          <ol className="space-y-2">
            {times.map((time, index) => (
              <li key={index}>
                {editableIndex === index ? (
                  <TimeForm
                    initialValues={times[index]}
                    onCancel={() => setEditableIndex(-1)}
                    onSubmit={(time) => {
                      onUpdate?.(time, index);
                      setEditableIndex(-1);
                    }}
                  />
                ) : (
                  <TimeItem {...{ time, index }} className="pr-2" />
                )}
              </li>
            ))}

            {editableIndex >= times.length && (
              <li>
                <TimeForm
                  initialValues={{} as Time}
                  onCancel={() => setEditableIndex(-1)}
                  onSubmit={(time) => {
                    onCreate?.(time);
                    setEditableIndex(-1);
                  }}
                />
              </li>
            )}
          </ol>
        )}

        {!readOnly && editableIndex < 0 && (
          <div className="flex flex-col items-center space-y-2">
            {!times.length && (
              <div>
                <FormattedMessage id={translations.pages.workDetail.offer.time.empty} />
              </div>
            )}

            <Button appearance="outline" className="h-10 px-4" onClick={() => setEditableIndex(times.length)}>
              <PlusSmallIcon className="mr-2 h-5 w-5" />
              <FormattedMessage id={translations.pages.workDetail.offer.time.create} />
            </Button>
          </div>
        )}
      </React.Fragment>
    )}
  </StatefulContainer>
);
