import * as yup from 'yup';

import { translations } from '@/locales';

type PasswordValidation = (value?: string) => boolean;

const specialCharacters = `^$*.[]{}()?-"!@#%&/\\,><':;|_~\`+=`;
const minLength = 8;

const lengthValidation: PasswordValidation = (value) => !!value && value.length >= minLength;
const specialValidation: PasswordValidation = (value) =>
  !!value && value.split('').some((letter) => specialCharacters.includes(letter));
const uppercaseValidation: PasswordValidation = (value) => !!value && /[A-Z]/.test(value);
const lowercaseValidation: PasswordValidation = (value) => !!value && /[a-z]/.test(value);

export const rules: [(value?: string) => boolean, string, Record<string, number>][] = [
  [lengthValidation, translations.customValidation.passwordRules.length, { min: minLength }],
  [specialValidation, translations.customValidation.passwordRules.specialCharacter, {}],
  [uppercaseValidation, translations.customValidation.passwordRules.uppercaseLetter, {}],
  [lowercaseValidation, translations.customValidation.passwordRules.lowercaseLetter, {}]
];

export const strictPassword = yup
  .string()
  .min(minLength)
  .test('special', translations.customValidation.specialCharacter, specialValidation)
  .test('uppercase', translations.customValidation.uppercaseLetter, uppercaseValidation)
  .test('lowercase', translations.customValidation.lowercaseLetter, lowercaseValidation);
