import classNames from 'classnames';
import React from 'react';

import { GetAssetUrlOptions, getAssetUrl } from '@/integrations/cloudimage';

interface Props extends Partial<GetAssetUrlOptions> {
  src: string;
  className?: string;
}

export const isImage = (src: string) => /\.(jpeg|jpg|webp|avif|png|gif|svg)$/.test(src);

export const Image = React.memo<Props>(
  ({ src, className }) => (
    <img
      className={classNames('h-full w-full origin-center object-cover', className)}
      src={getAssetUrl(src, { width: 200, quality: 80, fit: 'cover' })}
    />
  ),
  (p1, p2) => p1.src === p2.src && p1.className === p2.className
);
