import classNames from 'classnames';
import React from 'react';

import { Button } from '@/components/Button';

export const AddCarImageButton: React.FC<React.ButtonHTMLAttributes<HTMLButtonElement>> = ({ className, ...props }) => (
  <Button
    {...props}
    appearance="outline"
    className={classNames('relative h-36 w-36 overflow-hidden rounded-lg', className)}
  />
);
