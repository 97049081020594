import { findClients } from '@/data/client';

import { useRemoteData } from './useRemoteData';
import { useWorkshopId } from './useWorkshopId';

interface UseClientsOptions {
  clientIds?: string[];
  search?: string;
  token?: string;
}

export const useClients = ({ clientIds, search, token }: UseClientsOptions) => {
  const workshopId = useWorkshopId();

  return useRemoteData(
    {
      key: 'getClients',
      workshopId,
      clientIds,
      search,
      token,
      skip: clientIds !== undefined && clientIds.length === 0
    },
    async ({ workshopId, clientIds, search, token }) => {
      return findClients({ workshopId, clientIds, search, token });
    }
  );
};
