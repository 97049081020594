import React from 'react';
import useSWR from 'swr';
import { createContainer } from 'unstated-next';
// TODO: check if this is required
// import 'cross-fetch/polyfill';
import {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserAttribute,
  CognitoUserSession,
  ISignUpResult,
  UserData
} from 'amazon-cognito-identity-js';

import { apiClient } from '@/integrations/api';
import { pool, promisifyCallback } from '@/integrations/cognito';

interface User {
  id: string;
  email: string;
  firstName?: string;
  lastName?: string;
  confirmed: boolean;
  workshopId?: string;
}

const useAuth = () => {
  const [user, setUser] = React.useState<CognitoUser | undefined>(() => pool.getCurrentUser() ?? undefined);

  const context = useSWR(
    { key: `useSession`, user },
    async ({ user }) => {
      if (!user) throw new Error(`The user is missing.`);

      const session = await promisifyCallback<CognitoUserSession>((callback) => user.getSession(callback));

      const me = await promisifyCallback<UserData>((callback) => user.getUserData(callback)).then((result) => {
        // TODO: check attribute existance
        const id = result.UserAttributes.find((attr) => attr.Name === 'sub')?.Value;
        const email = result.UserAttributes.find((attr) => attr.Name === 'email')?.Value;
        const firstName = result.UserAttributes.find((attr) => attr.Name === 'name')?.Value;
        const lastName = result.UserAttributes.find((attr) => attr.Name === 'family_name')?.Value;
        const confirmed = !!result.UserAttributes.find((attr) => attr.Name === 'email_verified')?.Value;
        const workshopId = result.UserAttributes.find((attr) => attr.Name === 'custom:tenant_id')?.Value;

        if (!id || !email) throw new Error(`Couldn't determine user attributes.`);

        const user: User = { id, email, firstName, lastName, confirmed, workshopId };

        return user;
      });

      return { session, user: me };
    },
    {
      revalidateOnMount: true,
      revalidateIfStale: true,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      shouldRetryOnError: false
    }
  );

  React.useEffect(() => {
    if (!user) return;

    const interceptorId = apiClient.interceptors.request.use(async (config) => {
      const session = await promisifyCallback<CognitoUserSession>((callback) => user.getSession(callback)).catch(
        () => undefined
      );

      const accessToken = session?.getAccessToken();

      return {
        ...config,
        headers: { ...config.headers, ...(accessToken && { authorization: `Bearer ${accessToken.getJwtToken()}` }) }
      };
    });

    return () => apiClient.interceptors.request.eject(interceptorId);
  }, [user]);

  return React.useMemo(
    () => ({
      authenticated: !!user,
      loading: context.isLoading,
      user: context.data?.user,
      login: ({ email, password }: { email: string; password: string }) => {
        return new Promise<CognitoUser>((resolve, reject) => {
          const user = new CognitoUser({ Username: email, Pool: pool });

          user.setAuthenticationFlowType('USER_PASSWORD_AUTH');

          user.authenticateUser(new AuthenticationDetails({ Username: email, Password: password }), {
            onSuccess: () =>
              context
                .mutate()
                .then(() => resolve(user))
                .catch(reject),
            onFailure: reject
          });
        }).then(setUser);
      },
      register: ({ email, password }: { email: string; password: string }) => {
        return promisifyCallback<ISignUpResult>((callback) =>
          pool.signUp(email, password, [new CognitoUserAttribute({ Name: 'email', Value: email })], [], callback)
        ).then((result) => result.user);
      },
      reload: async () => {
        await context.mutate();
      },
      logout: () => new Promise<void>((resolve) => user?.signOut(resolve)).then(() => setUser(undefined))
    }),
    [user, setUser, context]
  );
};

export const Auth = createContainer(useAuth);
