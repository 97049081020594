import { AppointmentApi, CarApi, RatingsApi, TaleApi, UsersApi, VinApi, WorkshopApi } from '@binhatch/servicetale-api';
import axios from 'axios';

import { API_URL } from '@/config';

export const apiClient = axios.create({ baseURL: API_URL });

export const delay = (delay: number) => new Promise((resolve) => setTimeout(resolve, delay));

export const userApi = new UsersApi(undefined, undefined, apiClient);
export const appointmentApi = new AppointmentApi(undefined, undefined, apiClient);
export const workshopApi = new WorkshopApi(undefined, undefined, apiClient);
export const vinApi = new VinApi(undefined, undefined, apiClient);
export const carApi = new CarApi(undefined, undefined, apiClient);
export const ratingApi = new RatingsApi(undefined, undefined, apiClient);
export const taleApi = new TaleApi(undefined, undefined, apiClient);
