import React from 'react';
import { FormattedMessage } from 'react-intl';

import { translations } from '@/locales';

import { Card } from './Card';
import { WorkDependency } from './WorkDependency';
import { WorkHeading } from './WorkHeading';
import { WorkLayout } from './WorkLayout';

interface Props {
  taleId: string;
  loading?: boolean;
}

export const RejectedForm: React.FC<Props> = ({ taleId, loading }) => {
  return (
    <WorkLayout {...{ taleId }}>
      <WorkHeading {...{ loading }} />

      <WorkDependency {...{ taleId }} />

      <Card className="col-span-2 space-y-4">
        <h2 className="font-medium">
          <FormattedMessage id={translations.pages.workDetail.findings.title} />
        </h2>

        <div className="flex gap-2"></div>
      </Card>
    </WorkLayout>
  );
};
