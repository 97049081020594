import { TaleStatus, TalesResponse } from '@binhatch/servicetale-api';

import { userApi, workshopApi } from '@/integrations/api';
import { PaginatedOptions } from './pagination';

export const findTales = async ({
  workshopId,
  status,
  search,
  token
}: {
  workshopId?: string;
  status?: TaleStatus;
  search?: string;
} & PaginatedOptions): Promise<TalesResponse> => {
  const result = workshopId
    ? await workshopApi.listWorkshopTales(workshopId, status, undefined, search, token)
    : await userApi.listUserTales(status, undefined, search, token);

  return result.data;
};
