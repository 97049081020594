import { Part } from '@binhatch/servicetale-api';
import classNames from 'classnames';
import React from 'react';

import { Price } from '@/components/Price';

interface Props {
  part: Part;
  index: number;
  className?: string;
}

export const PartItem: React.FC<Props> = ({ part, index, className }) => (
  <div className={classNames('flex gap-2', className)}>
    <div className="h-6 w-6 rounded bg-[#F2F2F2] text-center text-sm leading-6">{index + 1}</div>

    <div className="flex-1">{part.name}</div>

    <div>{part.quantity}</div>

    <div>x</div>

    <div className="w-32 text-right">
      <Price amount={part.price} currency="RON" />
    </div>
  </div>
);
