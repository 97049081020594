import classNames from 'classnames';
import React from 'react';

import { AsComponent } from '@/components/As';

import { Props as InputProps } from './Input';

export const Radio = React.forwardRef(function Radio<T extends AsComponent>(
  { invalid, className, children, ...props }: React.PropsWithChildren<InputProps<T>>,
  ref: React.Ref<any>
) {
  return (
    <div className={classNames('flex', className)}>
      <input
        {...props}
        {...{ ref }}
        className={classNames(
          'form-radio mt-[2px] h-5 w-5 cursor-pointer rounded-full text-[#2E4C86] focus:ring-[#2E4C86]',
          invalid ? 'border-red-500 bg-red-100' : 'border-gray-300'
        )}
      />

      <label className="ml-2 cursor-pointer select-none" htmlFor={props.id}>
        {children}
      </label>
    </div>
  );
});
