import { ProblemFeedback, Solution } from '@binhatch/servicetale-api';
import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { translations } from '@/locales';
import { getPartSubtotal, getTimeSubtotal } from '@/utils/price';

import { Price } from '@/components/Price';

interface Props {
  solutions: Solution[];
  feedback: ProblemFeedback;
}

export const ProblemFeedbackItem: React.FC<Props> = ({ solutions, feedback }) => {
  const solution = feedback.selectedSolution ? solutions.find((s) => s.id === feedback.selectedSolution) : undefined;

  return (
    <div className="flex items-center space-x-2 text-sm">
      <div
        className={classNames(
          'flex items-center space-x-1 rounded p-1',
          feedback.selectedSolution ? 'bg-[#92E185]' : 'bg-[#BD0D0D] text-white'
        )}
      >
        {feedback.selectedSolution ? <CheckCircleIcon className="h-5 w-5" /> : <XCircleIcon className="h-5 w-5" />}

        <div>
          <FormattedMessage
            id={
              feedback.selectedSolution
                ? translations.pages.workDetail.offer.feedback.accepted
                : translations.pages.workDetail.offer.feedback.rejected
            }
          />
        </div>

        {!!solution && (
          <div>
            <Price amount={getPartSubtotal(solution.parts) + getTimeSubtotal(solution.time)} currency="RON" />
          </div>
        )}
      </div>

      {!!feedback.rejectionCause && (
        <div>
          {feedback.comments ? (
            <FormattedMessage
              id={translations.pages.workDetail.offer.feedback.comment}
              values={{ comment: feedback.comments }}
            />
          ) : (
            <FormattedMessage id={translations.enum.rejectionCause[feedback.rejectionCause]} />
          )}
        </div>
      )}
    </div>
  );
};
