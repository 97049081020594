import { Car, ChapterKind, User } from '@binhatch/servicetale-api';
import qs from 'query-string';
import React from 'react';
import { useNavigate } from 'react-router';

import { taleApi } from '@/integrations/api';
import { handleApiError } from '@/utils/apiError';
import * as urls from '@/utils/url';

import { QueryParamConfig, decodeSingleQueryParam, useQueryParams } from '@/hooks/useQueryParams';

import { Modal, useModal } from '@/components/Modal';
import { UpdateCarModal } from '@/modals/UpdateCarModal';
import { UpdateClientModal } from '@/modals/UpdateClientModal';

import { CarList } from '../CarList';
import { ClientList } from '../ClientList';
import { WorkLayout } from './WorkLayout';

const clientId: QueryParamConfig<string | undefined> = {
  decode: (value) => decodeSingleQueryParam(value, undefined),
  encode: (value) => (value ? value : undefined)
};

const carId: QueryParamConfig<string | undefined> = {
  decode: (value) => decodeSingleQueryParam(value, undefined),
  encode: (value) => (value ? value : undefined)
};

const config = { clientId, carId };

export const CreateWorkPage: React.FC = () => {
  const [query, updateQuery] = useQueryParams({ config });
  const navigate = useNavigate();

  const updateClientModal = useModal(UpdateClientModal);
  const updateCarModal = useModal(UpdateCarModal);

  const clientId = query.clientId;
  const carId = query.carId;

  const onClientChanged = React.useCallback((client: User) => updateQuery({ clientId: client.id }), [updateQuery]);
  const onCarChanged = React.useCallback((car: Car) => updateQuery({ carId: car.vin }), [updateQuery]);

  React.useEffect(() => {
    if (!clientId || !carId) return;

    taleApi
      .createTale({
        userId: clientId,
        carId,
        chapters: [
          { kind: ChapterKind.Dropoff },
          { kind: ChapterKind.CarReception },
          { kind: ChapterKind.Inspection },
          { kind: ChapterKind.Offer },
          { kind: ChapterKind.Work },
          { kind: ChapterKind.Handover }
        ]
      })
      .then((r) => r.data)
      .then(({ id: taleId }) => navigate(urls.work.getOne({ taleId })))
      .catch((error) =>
        handleApiError(error, {
          status: {
            402: () =>
              navigate(urls.workshop.subscription.root, {
                state: { redirect: qs.stringifyUrl({ url: urls.work.new, query: { clientId, carId } }) },
                replace: true
              })
          }
        })
      );
  }, [clientId, carId, navigate]);

  return (
    <React.Fragment>
      <WorkLayout>
        {!clientId ? (
          <ClientList
            onCreate={() =>
              updateClientModal
                .open({})
                .then(onClientChanged)
                .catch(() => null)
            }
            onSelect={async (client) => onClientChanged(client)}
          />
        ) : !carId ? (
          <CarList
            {...{ clientId }}
            onCreate={() =>
              updateCarModal
                .open({ clientId })
                .then(onCarChanged)
                .catch(() => null)
            }
            onSelect={onCarChanged}
          />
        ) : null}
      </WorkLayout>

      <Modal {...updateClientModal.props} />
      <Modal {...updateCarModal.props} />
    </React.Fragment>
  );
};
