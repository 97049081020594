import React from 'react';

import { FormattedValidationMessage } from '@/components/Form/FormattedValidationMessage';
import { translations } from '@/locales';

interface CognitoErrorResult {
  name: string;
  code: string;
  statusCode?: number;
}

export enum CognitoErrorCode {
  NotAuthorizedException = 'NotAuthorizedException',
  UserNotFoundException = 'UserNotFoundException',
  UsernameExistsException = 'UsernameExistsException',
  UserNotConfirmedException = 'UserNotConfirmedException',
  ExpiredCodeException = 'ExpiredCodeException',
  CodeMismatchException = 'CodeMismatchException'
}

export const isCognitoError = (error: unknown): error is CognitoErrorResult => {
  return !!(error as CognitoErrorResult)?.['code'];
};

interface Props {
  error: Error;
  className?: string;
}

const getMessageId = (error: CognitoErrorResult): string | undefined => {
  return translations.errors.cognito[error.code as keyof typeof translations.errors.cognito];
};

export const CognitoError: React.FC<Props> = ({ error, className }) => {
  if (!error) return null;

  const id = isCognitoError(error)
    ? getMessageId(error) ?? translations.errors.unhandled
    : translations.errors.unhandled;

  return <FormattedValidationMessage {...{ id, className }} values={{}} visible />;
};
