import { Part } from '@binhatch/servicetale-api';
import { PlusSmallIcon } from '@heroicons/react/20/solid';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { translations } from '@/locales';

import { Button } from '@/components/Button';
import { StatefulContainer } from '@/components/StatefulContainer';

import { PartForm } from './PartForm';
import { PartItem } from './PartItem';

interface Props {
  parts: Part[];
  readOnly?: boolean;
  onCreate?(item: Part): void;
  onUpdate?(item: Part, index: number): void;
}

export const PartList: React.FC<Props> = ({ parts, readOnly, onCreate, onUpdate }) => (
  <StatefulContainer state={-1}>
    {({ state: editableIndex, setState: setEditableIndex }) => (
      <React.Fragment>
        {(!!parts.length || editableIndex >= parts.length) && (
          <ol className="space-y-2">
            {parts.map((part, index) => (
              <li key={index}>
                {editableIndex === index ? (
                  <PartForm
                    initialValues={parts[index]}
                    onCancel={() => setEditableIndex(-1)}
                    onSubmit={(part) => {
                      onUpdate?.(part, index);
                      setEditableIndex(-1);
                    }}
                  />
                ) : (
                  <PartItem {...{ part, index }} className="pr-2" />
                )}
              </li>
            ))}

            {editableIndex >= parts.length && (
              <li>
                <PartForm
                  initialValues={{} as Part}
                  onCancel={() => setEditableIndex(-1)}
                  onSubmit={(part) => {
                    onCreate?.(part);
                    setEditableIndex(-1);
                  }}
                />
              </li>
            )}
          </ol>
        )}

        {!readOnly && editableIndex < 0 && (
          <div className="flex flex-col items-center space-y-2">
            {!parts.length && (
              <div>
                <FormattedMessage id={translations.pages.workDetail.offer.part.empty} />
              </div>
            )}

            <Button appearance="outline" className="h-10 px-4" onClick={() => setEditableIndex(parts.length)}>
              <PlusSmallIcon className="mr-2 h-5 w-5" />
              <FormattedMessage id={translations.pages.workDetail.offer.part.create} />
            </Button>
          </div>
        )}
      </React.Fragment>
    )}
  </StatefulContainer>
);
