import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import { translations } from '@/locales';
import { strictPassword } from '@/schemas/password';
import * as urls from '@/utils/url';

import { Auth } from '@/containers/useAuth';

import { Form } from '@/components/Form';
import { InputWithLabel } from '@/components/Form/InputWithLabel';
import { ValidatedField } from '@/components/Form/ValidatedField';
import { Link } from '@/components/Link';
import { LoadingButton } from '@/components/LoadingButton';
import { PasswordValidity } from '@/components/PasswordValidity';
import { SubmitError } from '@/components/SubmitError';

export const schema = yup
  .object({
    email: yup.string().email().required().label(translations.fields.email.label),
    password: strictPassword.required().label(translations.fields.password.label)
  })
  .required();

export const RegisterPage: React.FC = () => {
  const { register } = Auth.useContainer();
  const intl = useIntl();
  const { state } = useLocation();
  const navigate = useNavigate();

  const initialValues = React.useMemo(() => ({ email: state?.email, password: '' }), [state]);

  const onSubmit = React.useCallback(
    async ({ email, password }: yup.InferType<typeof schema>) => {
      await register({ email, password });
      navigate(urls.confirmation, { state: { email, password } });
    },
    [register, navigate]
  );

  return (
    <Form {...{ initialValues, schema, onSubmit }}>
      {({ values, submitting, submitError, handleSubmit }) => (
        <form className="m-0 w-full max-w-sm" onSubmit={handleSubmit}>
          <div className="space-y-6">
            <h1 className="text-xl font-medium">
              <FormattedMessage id={translations.pages.register.title} />
            </h1>

            <div className="rounded-lg border border-yellow-300 bg-yellow-200 p-4 text-center text-yellow-700">
              <FormattedMessage
                id={translations.pages.register.service}
                values={{
                  a: (children) => (
                    <Link
                      as={RouterLink}
                      state={{ email: values.email, password: values.password }}
                      to={urls.workshop.register}
                    >
                      {children}
                    </Link>
                  )
                }}
              />
            </div>

            <div className="space-y-6">
              <ValidatedField
                field={InputWithLabel}
                id="email"
                label={<FormattedMessage id={translations.fields.email.label} />}
                name="email"
                placeholder={intl.formatMessage({ id: translations.fields.email.placeholder })}
                readOnly={submitting}
                type="email"
              />

              <div className="space-y-2">
                <ValidatedField
                  field={InputWithLabel}
                  id="password"
                  label={<FormattedMessage id={translations.fields.password.label} />}
                  name="password"
                  placeholder={intl.formatMessage({ id: translations.fields.password.placeholder })}
                  readOnly={submitting}
                  type="password"
                />

                <PasswordValidity password={values.password} />
              </div>

              <SubmitError error={submitError} />

              <LoadingButton appearance="primary" className="h-10 w-full" loading={submitting} type="submit">
                <FormattedMessage id={translations.pages.register.cta} />
              </LoadingButton>

              <div className="text-center">
                <FormattedMessage
                  id={translations.pages.register.login}
                  values={{
                    a: (children) => (
                      <Link as={RouterLink} state={{ email: values.email }} to={urls.signIn}>
                        {children}
                      </Link>
                    )
                  }}
                />
              </div>
            </div>
          </div>
        </form>
      )}
    </Form>
  );
};
