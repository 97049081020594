import classNames from 'classnames';
import React from 'react';

interface Props {
  className?: string;
}

export const Container: React.FC<React.PropsWithChildren<Props>> = ({ className, ...props }) => (
  <div {...props} className={classNames('max-w-7xl', className)} />
);
