import { taleApi } from '@/integrations/api';

import { useRemoteData } from './useRemoteData';

interface UseTaleOptions {
  taleId?: string;
}

export const useTale = ({ taleId }: UseTaleOptions) => {
  return useRemoteData({ key: 'getWorkById', taleId, skip: !taleId }, async ({ taleId }) =>
    taleApi.getTaleByID(taleId!).then((r) => r.data)
  );
};
