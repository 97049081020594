import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import classnames from 'classnames';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import * as yup from 'yup';

import { translations } from '@/locales';

import { Form } from '@/components/Form';
import { InputWithLabel } from '@/components/Form/InputWithLabel';
import { ValidatedField } from '@/components/Form/ValidatedField';
import {
  InstanceProps,
  ModalHeader,
  ModalIconLayout,
  ModalLayout,
  ModalPrimaryButton,
  ModalSecondaryButton
} from '@/components/Modal';

export interface Props extends InstanceProps<{ reason: string }> {}

const schema = yup
  .object({
    reason: yup.string().required().label(translations.fields.cancelReason.label)
  })
  .required();

export const CloseWorkModal = React.forwardRef<HTMLDivElement, Props>(
  ({ initialFocus, className, onAction, onClose }, ref) => {
    const intl = useIntl();

    const onSubmit = React.useCallback(
      async (reason: yup.InferType<typeof schema>) => {
        onAction(reason);
      },
      [onAction]
    );

    return (
      <div {...{ ref }} className={classnames(className, 'max-w-xl space-y-6')}>
        <ModalIconLayout
          icon={
            <div className="flex h-12 w-12 items-center justify-center rounded-full bg-red-100">
              <ExclamationTriangleIcon aria-hidden="true" className="h-6 w-6 text-red-600" />
            </div>
          }
        >
          <Form {...{ schema, onSubmit }}>
            {({ submitting, handleSubmit }) => (
              <form className="m-0 space-y-4" onSubmit={handleSubmit}>
                <ModalLayout className="flex-1">
                  <ModalHeader {...{ onClose }}>
                    <FormattedMessage id={translations.pages.workDetail.closeModal.title} />
                  </ModalHeader>

                  <div className="text-sm">
                    <FormattedMessage id={translations.pages.workDetail.closeModal.message} />
                  </div>
                </ModalLayout>

                <ValidatedField
                  as="textarea"
                  field={InputWithLabel}
                  id="reason"
                  inputClassName="h-24 resize-none"
                  label={<FormattedMessage id={translations.fields.cancelReason.label} />}
                  name="reason"
                  placeholder={intl.formatMessage({ id: translations.fields.cancelReason.placeholder })}
                  readOnly={!!submitting}
                  ref={initialFocus}
                />

                <div className="flex flex-row-reverse space-x-2">
                  <ModalPrimaryButton appearance="danger" onAction={() => null}>
                    <FormattedMessage id={translations.pages.workDetail.closeModal.cta} />
                  </ModalPrimaryButton>

                  <ModalSecondaryButton {...{ onClose }}>
                    <FormattedMessage id={translations.buttons.back} />
                  </ModalSecondaryButton>
                </div>
              </form>
            )}
          </Form>
        </ModalIconLayout>
      </div>
    );
  }
);
