import React from 'react';

import { Auth } from '@/containers/useAuth';
import { Workshop } from '@/containers/useWorkshop';

import { AnonymousApp } from './AnonymousApp';
import { WorkshopApp } from './WorkshopApp';

export const App: React.FC = () => {
  const { authenticated, user } = Auth.useContainer();

  if (!authenticated) return <AnonymousApp />;

  if (!user) return null;

  return (
    <Workshop.Provider>
      <WorkshopApp />
    </Workshop.Provider>
  );
};
