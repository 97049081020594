import { Priority } from '@binhatch/servicetale-api';
import classNames from 'classnames';
import React from 'react';

import { Props as SelectItemProps } from '@/components/Form/Select/SelectItem';
import { translations } from '@/locales';
import { FormattedMessage } from 'react-intl';

export interface Props extends SelectItemProps {
  priority: Priority;
}

const colors: Record<Priority, string> = {
  [Priority.Low]: 'bg-[#92E185]',
  [Priority.Medium]: 'bg-[#FFF5BF]',
  [Priority.High]: 'bg-[#BD0D0D]'
};

export const WorkRiskItem = React.forwardRef<HTMLLIElement, React.PropsWithChildren<Props>>(
  ({ priority, isSelected, active, disabled, className, onClick, ...props }, ref) => (
    <li
      {...props}
      {...{ ref }}
      className={classNames(
        `group relative flex select-none p-2 text-sm`,
        isSelected ? 'bg-slate-100' : active && 'bg-slate-100',
        disabled ? `cursor-default` : `cursor-pointer`,
        className
      )}
      onClick={disabled ? undefined : onClick}
    >
      <div className={classNames('mr-2 mt-1 h-5 w-5 flex-shrink-0 rounded-full', colors[priority])} />

      <div>
        <div className="font-medium">
          <FormattedMessage id={translations.enum.workRisk[priority].name} />
        </div>
        <div>
          <FormattedMessage id={translations.enum.workRisk[priority].description} />
        </div>
      </div>
    </li>
  )
);
