import { XMarkIcon } from '@heroicons/react/24/outline';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { translations } from '@/locales';

interface Props {
  onClose?(): void;
}

export const ModalHeader: React.FC<React.PropsWithChildren<Props>> = ({ children, onClose }) => (
  <header className="relative">
    <h1 className="font-medium">{children}</h1>

    {!!onClose && (
      <button
        className="absolute top-1/2 -right-2 -translate-y-1/2 rounded p-2 hover:bg-gray-100"
        type="button"
        onClick={onClose}
      >
        <span className="sr-only">
          <FormattedMessage id={translations.buttons.close} />
        </span>
        <XMarkIcon aria-hidden="true" className="h-5 w-5" />
      </button>
    )}
  </header>
);
