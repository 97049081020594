import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Navigate, useLocation } from 'react-router';
import * as yup from 'yup';

import { translations } from '@/locales';
import * as urls from '@/utils/url';

import { Auth } from '@/containers/useAuth';
import { confirmCode, resendCode } from '@/integrations/cognito';
import { verificationCode, verificationCodeSize } from '@/schemas/verificationCode';

import { AsyncButton } from '@/components/AsyncButton';
import { Form } from '@/components/Form';
import { InputWithLabel } from '@/components/Form/InputWithLabel';
import { OtpInput } from '@/components/Form/OtpInput';
import { ValidatedField } from '@/components/Form/ValidatedField';
import { LoadingButton } from '@/components/LoadingButton';
import { SubmitError } from '@/components/SubmitError';

const schema = yup.object({ code: verificationCode.required().label(translations.fields.code.label) }).required();

export const ConfirmationPage: React.FC = () => {
  const { login } = Auth.useContainer();
  const { state } = useLocation();

  const email = typeof state?.email === 'string' ? (state.email as string) : undefined;
  const password = typeof state?.password === 'string' ? (state.password as string) : undefined;

  const onSubmit = React.useCallback(
    async ({ code }: yup.InferType<typeof schema>) => {
      await confirmCode({ email: email!, code });
      await login({ email: email!, password: password! });
    },
    [login, email, password]
  );

  if (!email || !password) return <Navigate replace to={urls.register} />;

  return (
    <Form {...{ schema, onSubmit }}>
      {({ submitting, submitError, handleSubmit }) => (
        <form className="m-0 w-full max-w-sm" onSubmit={handleSubmit}>
          <div className="space-y-6">
            <h1 className="text-xl font-medium">
              <FormattedMessage id={translations.pages.confirm.title} />
            </h1>

            <div>
              <FormattedMessage id={translations.pages.confirm.message} />
            </div>

            <div className="space-y-6">
              <ValidatedField
                as={OtpInput}
                field={InputWithLabel}
                id="code"
                label={<FormattedMessage id={translations.fields.code.label} />}
                name="code"
                readOnly={submitting}
                size={verificationCodeSize}
                type="tel"
              />

              <SubmitError error={submitError} />

              <div className="space-y-2">
                <LoadingButton appearance="primary" className="h-10 w-full" loading={submitting} type="submit">
                  <FormattedMessage id={translations.pages.confirm.cta} />
                </LoadingButton>

                <AsyncButton
                  appearance="secondary"
                  className="h-10 w-full"
                  once
                  type="button"
                  onClick={() => resendCode({ email })}
                >
                  <FormattedMessage id={translations.pages.confirm.send} />
                </AsyncButton>
              </div>
            </div>
          </div>
        </form>
      )}
    </Form>
  );
};
