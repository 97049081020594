import { Car, TalePreviewCar } from '@binhatch/servicetale-api';
import { TruckIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import React from 'react';

import { BlueAvatar } from './BlueAvatar';
import { LicensePlate } from './LicensePlate';
import { Skeleton } from './Skeleton';

interface Props {
  appearance?: 'simple';
  car?: Car | TalePreviewCar;
  className?: string;
}

export const CarIdentifier: React.FC<Props> = ({ appearance, car, className }) => (
  <div className={classNames('flex items-center', className)}>
    {appearance !== 'simple' && (
      <BlueAvatar className="mr-4 shrink-0">
        <TruckIcon className="h-5 w-5" />
      </BlueAvatar>
    )}

    <div className="flex-1">
      <Skeleton className="mb-2 w-32" size="h-5" visible={!car}>
        <div className="flex items-center space-x-2 font-medium leading-5">
          {[car?.make, car?.model, car?.year].filter(Boolean).join(' ')}
        </div>
      </Skeleton>

      <Skeleton className="w-20" visible={!car}>
        <div className="flex items-center space-x-2">
          {!!car?.registrationNumber && <LicensePlate>{car?.registrationNumber}</LicensePlate>}
          {!!car?.registrationNumber && <div className="text-sm text-slate-400">/</div>}
          <div className="text-sm">{car?.vin}</div>
        </div>
      </Skeleton>
    </div>
  </div>
);
