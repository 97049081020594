import qs from 'query-string';

const extractPath = (src: string) =>
  src
    // remove protocol
    .replace(/.+:\/\//, '')
    // remove domain
    .replace(/^.+?(\/.+)/, '$1')
    // remove query string
    .replace(/\?.+$/, '');

export interface GetAssetUrlOptions {
  width: number;
  height?: number;
  quality?: number;
  fit?: 'cover';
}

export const getAssetUrl = (src: string, { width: w, height: h = w, quality: q = 90, fit }: GetAssetUrlOptions) => {
  return qs.stringifyUrl({ url: `https://clxcxjnpia.cloudimg.io/_stg_/${extractPath(src)}`, query: { w, h, q, fit } });
};
