import React from 'react';
import { FormattedMessage } from 'react-intl';

import { translations } from '@/locales';

import { SkeletonPage } from './SkeletonPage';

window.hsConversationsSettings = {
  loadImmediately: false
};

export const HelpPage: React.FC = () => {
  React.useEffect(() => {
    function onConversationsAPIReady(hubspot: HubSpotConversations) {
      hubspot.widget.load({ widgetOpen: true });
    }

    if (window.HubSpotConversations) {
      onConversationsAPIReady(window.HubSpotConversations);
    } else {
      window.hsConversationsOnReady = [() => onConversationsAPIReady(window.HubSpotConversations!)];
    }

    return () => window.HubSpotConversations?.widget.remove();
  }, []);

  return (
    <SkeletonPage>
      <FormattedMessage id={translations.navigation.help} />
    </SkeletonPage>
  );
};
