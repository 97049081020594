import React from 'react';
import { FormattedNumber } from 'react-intl';

interface Props {
  amount: number;
  currency: string;
  zero?: React.ReactNode;
}

export const Price: React.FC<Props> = ({ currency, amount, zero }) => (
  <React.Fragment>
    {zero && amount === 0 ? (
      zero
    ) : (
      <FormattedNumber
        {...{ currency }}
        maximumFractionDigits={2}
        minimumFractionDigits={2}
        style="currency"
        value={amount}
      />
    )}
  </React.Fragment>
);
