import { workshopApi } from '@/integrations/api';

import { useRemoteData } from './useRemoteData';
import { useWorkshopId } from './useWorkshopId';

interface UseCarOptions {
  clientId?: string;
  carId?: string;
}

export const useCar = ({ clientId, carId }: UseCarOptions) => {
  const workshopId = useWorkshopId();

  return useRemoteData(
    { key: 'getCar', workshopId, clientId, carId, skip: !clientId || !carId },
    async ({ workshopId, clientId, carId }) => {
      if (!workshopId || !clientId) throw new Error('No workshop or client were defiend.');

      return await workshopApi
        .listWorkshopCars(workshopId, undefined, undefined, clientId, carId)
        .then((r) => r.data.cars[0]);
    }
  );
};
