import { CognitoUser, CognitoUserPool, NodeCallback } from 'amazon-cognito-identity-js';

import { COGNITO_CLIENT_ID, COGNITO_USER_POOL_ID } from '@/config';

export const pool = new CognitoUserPool({
  UserPoolId: COGNITO_USER_POOL_ID,
  ClientId: COGNITO_CLIENT_ID
});

export const promisifyCallback = <T>(callback: (callback: NodeCallback<any, T>) => void) => {
  return new Promise<T>((resolve, reject) => {
    callback((error, result) => {
      if (error) return reject(error);
      if (!result) return reject(new Error(`Result is missing.`));
      return resolve(result);
    });
  });
};

export const confirmCode = ({ email, code }: { email: string; code: string }) => {
  const user = new CognitoUser({ Username: email, Pool: pool });

  return promisifyCallback((callback) => user.confirmRegistration(code, false, callback));
};

export const resendCode = ({ email }: { email: string }) => {
  const user = new CognitoUser({ Username: email, Pool: pool });

  return promisifyCallback((callback) => user.resendConfirmationCode(callback));
};

export const requestResetPassword = ({ email }: { email: string }) => {
  const user = new CognitoUser({ Username: email, Pool: pool });

  return new Promise<void>((resolve) => {
    user.forgotPassword({ onSuccess: () => resolve(), onFailure: () => resolve() });
  });
};

export const resetPassword = ({ email, code, password }: { email: string; code: string; password: string }) => {
  const user = new CognitoUser({ Username: email, Pool: pool });

  return new Promise((resolve, reject) => {
    user.confirmPassword(code, password, { onSuccess: resolve, onFailure: reject });
  });
};
