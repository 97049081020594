import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import { translations } from '@/locales';
import * as urls from '@/utils/url';

import { Auth } from '@/containers/useAuth';

import { CognitoErrorCode, isCognitoError } from '@/components/CognitoError';
import { Form } from '@/components/Form';
import { InputWithLabel } from '@/components/Form/InputWithLabel';
import { NumberInput } from '@/components/Form/NumberInput';
import { PhoneInput } from '@/components/Form/PhoneInput';
import { Select } from '@/components/Form/Select';
import { ValidatedField } from '@/components/Form/ValidatedField';
import { Link } from '@/components/Link';
import { LoadingButton } from '@/components/LoadingButton';
import { PasswordValidity } from '@/components/PasswordValidity';
import { SubmitError } from '@/components/SubmitError';
import { workshopApi } from '@/integrations/api';
import { strictPassword } from '@/schemas/password';
import { internationalPhone } from '@/schemas/phone';

const addressSchema = yup.object({
  addressLine1: yup.string().required().label(translations.fields.addressLine1.label),
  addressLine2: yup.string().label(translations.fields.addressLine2.label),
  country: yup.string().required().label(translations.fields.country.label),
  city: yup.string().required().label(translations.fields.city.label),
  state: yup.string().required().label(translations.fields.state.label),
  postalCode: yup.string().required().label(translations.fields.postalCode.label)
});

const userSchema = yup.object({
  firstName: yup.string().required().label(translations.fields.firstName.label),
  lastName: yup.string().required().label(translations.fields.lastName.label),
  email: yup.string().email().required().label(translations.fields.email.label),
  password: strictPassword.required().label(translations.fields.password.label),
  phoneNo: internationalPhone.required().label(translations.fields.phone.label)
});

export const schema = yup
  .object({
    name: yup.string().required().label(translations.fields.workshopName.label),
    address: addressSchema.required(),
    userDetails: userSchema.required()
  })
  .required();

export const WorkshopRegisterPage: React.FC = () => {
  const { login } = Auth.useContainer();
  const intl = useIntl();
  const { state } = useLocation();
  const navigate = useNavigate();

  const initialValues = React.useMemo<yup.InferType<typeof schema>>(
    () => ({
      name: '',
      userDetails: { firstName: '', lastName: '', email: state?.email, password: '', phoneNo: '' },
      address: { addressLine1: '', addressLine2: '', country: 'RO', city: '', state: '', postalCode: '' }
    }),
    [state]
  );

  const onSubmit = React.useCallback(
    async (values: yup.InferType<typeof schema>) => {
      await workshopApi.createWorkshop(values);

      const credentials = { email: values.userDetails.email, password: values.userDetails.password };

      await login(credentials).catch((error) => {
        if (!isCognitoError(error)) throw error;
        if (error.code !== CognitoErrorCode.UserNotConfirmedException) throw error;

        navigate(urls.confirmation, { state: credentials });
      });
    },
    [login, navigate]
  );

  return (
    <Form {...{ initialValues, schema, onSubmit }}>
      {({ values, submitting, submitError, handleSubmit }) => (
        <form className="m-0 w-full max-w-md" onSubmit={handleSubmit}>
          <div className="space-y-6">
            <h1 className="text-xl font-medium">
              <FormattedMessage id={translations.pages.workshop.register.title} />
            </h1>

            <div className="grid gap-4 md:grid-cols-2">
              <ValidatedField
                field={InputWithLabel}
                id="firstName"
                label={<FormattedMessage id={translations.fields.firstName.label} />}
                name="userDetails.firstName"
                placeholder={intl.formatMessage({ id: translations.fields.firstName.placeholder })}
                readOnly={submitting}
                type="text"
              />

              <ValidatedField
                field={InputWithLabel}
                id="lastName"
                label={<FormattedMessage id={translations.fields.lastName.label} />}
                name="userDetails.lastName"
                placeholder={intl.formatMessage({ id: translations.fields.lastName.placeholder })}
                readOnly={submitting}
                type="text"
              />

              <ValidatedField
                as={PhoneInput}
                className="col-span-full"
                defaultPrefix="+40"
                field={InputWithLabel}
                id="phone"
                label={<FormattedMessage id={translations.fields.phone.label} />}
                name="userDetails.phoneNo"
                placeholder={intl.formatMessage({ id: translations.fields.phone.placeholder })}
                readOnly={submitting}
                type="tel"
              />

              <ValidatedField
                className="col-span-full"
                field={InputWithLabel}
                id="email"
                label={<FormattedMessage id={translations.fields.email.label} />}
                name="userDetails.email"
                placeholder={intl.formatMessage({ id: translations.fields.email.placeholder })}
                readOnly={submitting}
                type="email"
              />

              <div className="col-span-full space-y-2">
                <ValidatedField
                  field={InputWithLabel}
                  id="password"
                  label={<FormattedMessage id={translations.fields.password.label} />}
                  name="userDetails.password"
                  placeholder={intl.formatMessage({ id: translations.fields.password.placeholder })}
                  readOnly={submitting}
                  type="password"
                />

                <PasswordValidity password={values.userDetails?.password} />
              </div>
            </div>

            <h1 className="text-xl font-medium">
              <FormattedMessage id={translations.pages.workshop.register.workshop} />
            </h1>

            <div className="grid gap-4 md:grid-cols-2">
              <ValidatedField
                className="col-span-full"
                field={InputWithLabel}
                id="workshopName"
                label={<FormattedMessage id={translations.fields.workshopName.label} />}
                name="name"
                placeholder={intl.formatMessage({ id: translations.fields.workshopName.placeholder })}
                readOnly={submitting}
                type="text"
              />

              <ValidatedField
                className="col-span-full"
                field={InputWithLabel}
                id="addressLine1"
                label={<FormattedMessage id={translations.fields.addressLine1.label} />}
                name="address.addressLine1"
                placeholder={intl.formatMessage({ id: translations.fields.addressLine1.placeholder })}
                readOnly={submitting}
                type="text"
              />

              <ValidatedField
                className="col-span-full"
                description={<FormattedMessage id={translations.fields.optional} />}
                field={InputWithLabel}
                id="addressLine2"
                label={<FormattedMessage id={translations.fields.addressLine2.label} />}
                name="address.addressLine2"
                placeholder={intl.formatMessage({ id: translations.fields.addressLine2.placeholder })}
                readOnly={submitting}
                type="text"
              />

              <ValidatedField
                as={Select}
                className="col-span-full"
                field={InputWithLabel}
                id="county"
                items={Object.entries(translations.country).map(([value, id]) => ({
                  value,
                  name: intl.formatMessage({ id })
                }))}
                label={<FormattedMessage id={translations.fields.country.label} />}
                name="address.country"
                placeholder={intl.formatMessage({ id: translations.fields.country.placeholder })}
                readOnly={submitting}
                type="text"
              />

              <ValidatedField
                field={InputWithLabel}
                id="city"
                label={<FormattedMessage id={translations.fields.city.label} />}
                name="address.city"
                placeholder={intl.formatMessage({ id: translations.fields.city.placeholder })}
                readOnly={submitting}
                type="text"
              />

              <ValidatedField
                field={InputWithLabel}
                id="state"
                label={<FormattedMessage id={translations.fields.state.label} />}
                name="address.state"
                placeholder={intl.formatMessage({ id: translations.fields.state.placeholder })}
                readOnly={submitting}
                type="text"
              />

              <ValidatedField
                as={NumberInput}
                className="col-span-full"
                field={InputWithLabel}
                id="postalCode"
                label={<FormattedMessage id={translations.fields.postalCode.label} />}
                name="address.postalCode"
                placeholder={intl.formatMessage({ id: translations.fields.postalCode.placeholder })}
                readOnly={submitting}
                type="text"
              />
            </div>

            <SubmitError error={submitError} />

            <LoadingButton appearance="primary" className="h-10 w-full" loading={submitting} type="submit">
              <FormattedMessage id={translations.pages.register.cta} />
            </LoadingButton>

            <div className="text-center">
              <FormattedMessage
                id={translations.pages.register.login}
                values={{
                  a: (children) => (
                    <Link as={RouterLink} state={{ email: values.userDetails?.email }} to={urls.signIn}>
                      {children}
                    </Link>
                  )
                }}
              />
            </div>
          </div>
        </form>
      )}
    </Form>
  );
};
