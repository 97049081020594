import { Chapter, ChapterKind, ChapterState, Tale, TaleStatus } from '@binhatch/servicetale-api';
import {
  CheckIcon,
  ClipboardDocumentCheckIcon,
  DocumentIcon,
  MagnifyingGlassIcon,
  PlusSmallIcon,
  TagIcon,
  TruckIcon,
  WrenchIcon,
  XMarkIcon
} from '@heroicons/react/24/outline';
import classNames from 'classnames';
import React from 'react';
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import { findTales } from '@/data/tale';
import { translations } from '@/locales';
import * as urls from '@/utils/url';

import { QueryParamConfig, decodeSingleQueryParam, useQueryParams } from '@/hooks/useQueryParams';
import { useRemoteData } from '@/hooks/useRemoteData';

import { Button } from '@/components/Button';
import { CarIdentifier } from '@/components/CarIdentifier';
import { Container } from '@/components/Container';
import { FilterItem } from '@/components/FilterItem';
import { SearchInput } from '@/components/Form/SearchInput';
import { LoadingState } from '@/components/LoadingState';
import { PageLoaderHeading } from '@/components/PageLoaderHeading';
import { Pagination } from '@/components/Pagination';
import { Auth } from '@/containers/useAuth';
import { useWorkshopId } from '@/hooks/useWorkshopId';

const statuses = ['active', 'completed', 'all'] as const;

const page: QueryParamConfig<string | undefined> = {
  decode: (value) => decodeSingleQueryParam(value, undefined),
  encode: (value) => value
};

const status: QueryParamConfig<(typeof statuses)[number]> = {
  decode: (value) => {
    value = decodeSingleQueryParam(value, 'active');
    if (value === 'active') return 'active';
    if (value === 'completed') return 'completed';
    return 'all';
  },
  encode: (value) => value
};

const search: QueryParamConfig<string | undefined> = {
  decode: (value) => decodeSingleQueryParam(value, undefined),
  encode: (value) => (value ? value : undefined)
};

const config = { page, status, search };

const workStatusInfo: Record<
  keyof typeof translations.enum.workStatus,
  { color: string; background: string; Icon: React.ComponentType<React.SVGProps<SVGSVGElement>> }
> = {
  [ChapterKind.Dropoff]: { color: 'text-[#2E4C86]', background: 'bg-[#E0EBFF]', Icon: TruckIcon },
  [ChapterKind.CarReception]: { color: 'text-[#2E4C86]', background: 'bg-[#E0EBFF]', Icon: ClipboardDocumentCheckIcon },
  [ChapterKind.Inspection]: { color: 'text-[#2E4C86]', background: 'bg-[#E0EBFF]', Icon: MagnifyingGlassIcon },
  [ChapterKind.Offer]: { color: 'text-[#2E4C86]', background: 'bg-[#E0EBFF]', Icon: TagIcon },
  [ChapterKind.Work]: { color: 'text-[#04464A]', background: 'bg-[#C1E3E5]', Icon: WrenchIcon },
  [ChapterKind.WorkReception]: { color: 'text-[#04464A]', background: 'bg-[#C1E3E5]', Icon: WrenchIcon },
  [ChapterKind.Payment]: { color: 'text-[#04464A]', background: 'bg-[#C1E3E5]', Icon: DocumentIcon },
  [ChapterKind.Handover]: { color: 'text-[#04464A]', background: 'bg-[#C1E3E5]', Icon: CheckIcon },
  [TaleStatus.Completed]: { color: 'text-[#04464A]', background: 'bg-[#C1E3E5]', Icon: CheckIcon },
  [TaleStatus.Canceled]: { color: 'text-red-600', background: 'bg-red-100', Icon: XMarkIcon },
  [TaleStatus.Refused]: { color: 'text-red-600', background: 'bg-red-100', Icon: XMarkIcon }
};

const useMostRecentTaleChapter = () => {
  const workshopId = useWorkshopId();

  const fallback = <T extends any, T1, T2>(value: T | undefined, getter: (v: T) => T1, fallback: () => T2) =>
    value ? getter(value) : fallback();

  const getFromTale = (tale: Tale) => ({
    status: tale.status === TaleStatus.InProgress ? ChapterKind.CarReception : tale.status,
    updatedAt: tale.updatedAt
  });

  const getFromChapter = (chapter: Chapter) => ({
    status: chapter.kind,
    updatedAt: chapter.completedAt ?? chapter.updatedAt ?? chapter.createdAt
  });

  return React.useCallback(
    (tale: Tale) => {
      if (tale.status !== TaleStatus.InProgress) return getFromTale(tale);

      const chapters = [...(tale.completed ?? []), ...(tale.incomplete ?? [])];

      if (workshopId)
        return fallback(
          chapters.find((c) => c.state !== ChapterState.Final),
          getFromChapter,
          () => getFromTale(tale)
        );

      return fallback(
        chapters
          .slice()
          .reverse()
          .find((c) => [ChapterState.Final, ChapterState.WaitingUserIntervention].includes(c.state as any)),
        getFromChapter,
        () => getFromTale(tale)
      );
    },
    [workshopId]
  );
};

export const WorkListPage: React.FC = () => {
  const intl = useIntl();
  const { user } = Auth.useContainer();
  const [query, updateQuery] = useQueryParams({ config });

  const status = React.useMemo((): TaleStatus | undefined => {
    switch (query.status) {
      case 'active':
        return TaleStatus.InProgress;
      case 'completed':
        return TaleStatus.Completed;
    }
  }, [query.status]);

  const tales = useRemoteData(
    { key: `useTales`, workshopId: user?.workshopId, status, search: query.search, page: query.page },
    ({ workshopId, search, status, page: token }) => findTales({ workshopId, search, status, token })
  );

  const getMostRecentChapter = useMostRecentTaleChapter();

  return (
    <Container className="space-y-6">
      <PageLoaderHeading loading={tales.isLoading || tales.isValidating}>
        <FormattedMessage id={translations.navigation.works} />
      </PageLoaderHeading>

      <div className="flex flex-col justify-between gap-4 md:flex-row md:items-center">
        <ul className="flex items-center gap-4">
          {statuses.map((status) => (
            <li key={status}>
              <FilterItem active={query.status === status} onClick={() => updateQuery({ page: undefined, status })}>
                <FormattedMessage id={translations.enum.workFilters[status]} />
              </FilterItem>
            </li>
          ))}
        </ul>

        <div className="flex items-center gap-4">
          <SearchInput
            className="w-full md:w-72"
            placeholder={intl.formatMessage({ id: translations.pages.workList.search })}
            value={query.search}
            onChange={(search: string) => updateQuery({ search })}
          />

          <Button appearance="primary" as={Link} className="h-10 space-x-2 px-3 md:px-8" to={urls.work.new}>
            <PlusSmallIcon className="h-5 w-5" />
            <div className="hidden md:block">
              <FormattedMessage id={translations.pages.workList.cta} />
            </div>
          </Button>
        </div>
      </div>

      <LoadingState loading={tales.isLoading || tales.isValidating}>
        <div className="grid min-h-[7rem] w-full gap-4 rounded-lg md:table md:bg-white">
          {tales.data?.tales
            .map((tale) => ({ tale, ...getMostRecentChapter(tale) }))
            .map((item) => ({
              ...item,
              status:
                item.tale.status === TaleStatus.Canceled
                  ? ('canceled' as const)
                  : item.tale.status === TaleStatus.Refused
                  ? 'refused'
                  : item.status
            }))
            .map((item) => ({ ...item, ...workStatusInfo[item.status], lastUpdatedAt: new Date(item.updatedAt) }))
            .map(({ tale, lastUpdatedAt, status, color, background, Icon }) => (
              <Link
                className="relative flex w-full overflow-hidden bg-white hover:bg-[#F0FAFF] md:table-row md:first:rounded-t-lg md:last:rounded-b-lg"
                key={tale.id}
                to={urls.work.getOne({ taleId: tale.id })}
              >
                <div className="w-10 align-middle md:table-cell md:w-48 md:p-4">
                  <div
                    className={classNames(
                      'flex h-full flex-col items-center justify-center py-6 px-2 text-sm md:h-auto md:rounded-lg',
                      color,
                      background
                    )}
                  >
                    <Icon className="mb-2 h-6 w-6" />
                    <div className="hidden md:block">
                      <FormattedMessage id={translations.enum.workStatus[status]} />
                    </div>
                  </div>
                </div>

                <div className="space-y-2 p-4 align-middle md:table-cell md:w-3/12">
                  <CarIdentifier appearance="simple" car={tale.car} />

                  <div className="text-sm md:block">
                    <FormattedDate dateStyle="long" timeStyle="short" value={lastUpdatedAt} />
                  </div>
                </div>

                <div className="hidden p-4 text-right align-middle text-sm md:table-cell">
                  <FormattedDate dateStyle="long" timeStyle="short" value={lastUpdatedAt} />
                </div>
              </Link>
            ))}
        </div>
      </LoadingState>

      <Pagination
        hasNext={!!tales.data?.nextToken}
        // hasPrevious={!!tales.data?.previousToken}
        onNext={() => updateQuery({ page: tales.data?.nextToken })}
        // onPrevious={() => updateQuery({ page: tales.data?.previousToken })}
      />
    </Container>
  );
};
