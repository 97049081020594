import { FORM_ERROR, FormApi } from 'final-form';
import set from 'lodash/set';
import React from 'react';
import { FormProps, FormRenderProps, Form as OriginalForm } from 'react-final-form';
import * as yup from 'yup';

export interface RenderProps<T> extends FormRenderProps<T> {}

export type FormSubmitReturn = Object | null | undefined | void;

export type Props<S extends yup.ObjectSchema<any>> = Omit<FormProps<yup.InferType<S>>, 'initialValues' | 'onSubmit'> & {
  initialValues?: yup.InferType<S>;
  schema?: S;
  children(form: RenderProps<yup.InferType<S>>): React.ReactNode;
  onSubmit(values: yup.InferType<S>, form: FormApi): Promise<FormSubmitReturn> | FormSubmitReturn;
};

export function Form<S extends yup.ObjectSchema<any>>({ schema, children: render, ...rest }: Props<S>) {
  const getValue = React.useCallback((values: yup.InferType<S>) => (schema ? schema.cast(values) : values), [schema]);

  const validate = React.useCallback(
    (values: yup.InferType<S>) => {
      if (!schema) return undefined;

      return schema
        .validate(values, { abortEarly: false, stripUnknown: true })
        .then(() => null)
        .catch((error: yup.ValidationError) => {
          return error.inner.reduce<Record<string, yup.ValidationError>>((fields, error) => {
            // let's pass both the error message (which is the key to localize) and
            // the params so it can be formatted where it's displayed
            set(fields, error.path!, { id: error.message, values: error.params as any });
            return fields;
          }, {});
        });
    },
    [schema]
  );

  const onSubmit = React.useCallback(
    (values: yup.InferType<S>, form: FormApi): Promise<Object | null> => {
      return Promise.resolve()
        .then(() => rest.onSubmit(getValue(values), form))
        .catch((error) => error)
        .then((result: any) => {
          if (!(result instanceof Error)) return result;

          return { [FORM_ERROR]: result };
        });
    },
    [rest, getValue]
  );

  return (
    <OriginalForm {...rest} {...{ validate, onSubmit }}>
      {render}
    </OriginalForm>
  );
}
