import classNames from 'classnames';
import React from 'react';

interface Props {
  className?: string;
}

export const LicensePlate: React.FC<React.PropsWithChildren<Props>> = ({ className, ...props }) => (
  <div
    {...props}
    className={classNames(
      'flex h-5 items-center rounded border border-solid border-[#202020] px-2 text-sm leading-5',
      className
    )}
  />
);
