import { WorkItem } from '@binhatch/servicetale-api';
import React from 'react';

import { WorkItemBadge } from './WorkItemBadge';

interface Props {
  item: WorkItem;
}

export const WorkItemListItem: React.FC<Props> = ({ item: { priority, name, type } }) => (
  <div>
    <WorkItemBadge {...{ priority, type }} />
    <div>{name}</div>
  </div>
);
