import React from 'react';
import { FormattedMessage } from 'react-intl';
import * as yup from 'yup';

import { translations } from '@/locales';

import { Button } from '@/components/Button';
import { Form, Props as FormProps } from '@/components/Form';

export interface Props<S extends yup.ObjectSchema<any>> {
  initialValues: FormProps<S>['initialValues'];
  schema: S;
  onSubmit: FormProps<S>['onSubmit'];
  onCancel(): void;
}

export function InlineForm<S extends yup.ObjectSchema<any>>({
  onCancel,
  children,
  ...props
}: React.PropsWithChildren<Props<S>>) {
  return (
    <Form {...props}>
      {({ handleSubmit }) => (
        <div className="grid grid-cols-2 gap-2 xl:flex">
          {children}

          <Button
            appearance="primary"
            className="order-last h-10 px-4 xl:order-none"
            type="submit"
            onClick={handleSubmit}
          >
            <FormattedMessage id={translations.buttons.add} />
          </Button>

          <Button appearance="transparent" className="h-10 px-4" onClick={onCancel}>
            <FormattedMessage id={translations.buttons.back} />
          </Button>
        </div>
      )}
    </Form>
  );
}
