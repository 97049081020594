import { XCircleIcon } from '@heroicons/react/24/outline';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { translations } from '@/locales';

import { InputWithLabel } from '@/components/Form/InputWithLabel';

import { Card } from './Card';
import { WorkHeading } from './WorkHeading';

interface Props {
  reason?: string;
}

export const CanceledForm: React.FC<React.PropsWithChildren<Props>> = ({ reason, children }) => (
  <React.Fragment>
    <WorkHeading />

    {children}

    <Card className="space-y-4 py-12">
      <div className="flex flex-col items-center justify-center space-y-4">
        <XCircleIcon className="h-20 w-20 text-red-600" />

        <div className="text-center font-medium">
          <FormattedMessage id={translations.pages.workDetail.canceled.title} />
        </div>
      </div>

      {!!reason && (
        <InputWithLabel
          as="div"
          inputClassName="min-h-[6rem]"
          label={<FormattedMessage id={translations.fields.cancelReason.label} />}
        >
          {reason}
        </InputWithLabel>
      )}
    </Card>
  </React.Fragment>
);
