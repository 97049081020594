import React from 'react';

import { Avatar } from './Avatar';
import { BlueAvatar } from './BlueAvatar';

interface Props extends React.ComponentProps<typeof Avatar> {
  name?: string;
  fallback?: React.ReactNode;
}

export const NicknameAvatar: React.FC<Props> = ({ name, fallback = '?', ...props }) => {
  const nickname = React.useMemo(
    () =>
      name
        ?.split(/\s+/)
        .slice(0, 2)
        .map((part) => part[0])
        .join('')
        .toUpperCase(),
    [name]
  );

  return <BlueAvatar {...props}>{nickname || fallback}</BlueAvatar>;
};
