import { AnalyticsUser } from '../user';
import { UserProperties } from '../user-properties';
import { FilteredProps, UserTracker } from '../user-tracker';

interface HubSpottUserTrackerOptions extends AnalyticsUser, Partial<UserProperties> {}

export class HubSpottUserTracker implements UserTracker {
  constructor(private props: HubSpottUserTrackerOptions) {}

  set<T extends keyof UserProperties>(key: T, value: UserProperties[T]): this {
    this.props[key] = value;
    return this;
  }

  setOnce<T extends keyof UserProperties>(key: T, value: UserProperties[T]): this {
    // HubSpot doesn't support this so we fallback to set
    return this.set(key, value);
  }

  increment<T extends keyof FilteredProps<UserProperties, number>>(key: T, value: UserProperties[T]): this {
    // HubSpot doesn't support this so we fallback to set
    return this.set(key, value);
  }

  toIdentify() {
    return this.props;
  }
}
