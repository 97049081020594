import React from 'react';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';

import * as urls from '@/utils/url';

import { Logo } from '@/components/Logo';

import { ConfirmationPage } from '@/pages/ConfirmationPage';
import { ForgotPasswordPage } from '@/pages/ForgotPasswordPage';
import { LoginPage } from '@/pages/LoginPage';
import { RegisterPage } from '@/pages/RegisterPage';
import { ResetPasswordPage } from '@/pages/ResetPasswordPage';
import { WorkPreviewPage } from '@/pages/WorkPreviewPage';
import { WorkshopRegisterPage } from '@/pages/WorkshopRegisterPage';

export const AnonymousApp = React.memo(() => (
  <Routes>
    <Route element={<WorkPreviewPage />} path={urls.work.preview} />

    <Route
      element={
        <div className="flex flex-1 flex-col items-center justify-center space-y-10 p-4 md:p-8">
          <header className="text-center">
            <Logo size="large" />
          </header>

          <Outlet />
        </div>
      }
      path="/"
    >
      <Route element={<WorkshopRegisterPage />} path={urls.workshop.register} />
      <Route element={<LoginPage />} path={urls.signIn} />
      <Route element={<RegisterPage />} path={urls.register} />
      <Route element={<ConfirmationPage />} path={urls.confirmation} />
      <Route element={<ForgotPasswordPage />} path={urls.forgotPassword.send} />
      <Route element={<ResetPasswordPage />} path={urls.forgotPassword.reset} />

      <Route element={<Navigate replace to={urls.signIn} />} path="*" />
      <Route element={<Navigate replace to={urls.signIn} />} index />
    </Route>
  </Routes>
));
