import React from 'react';
import { FormattedMessage } from 'react-intl';

import { translations } from '@/locales';

import { Price } from '@/components/Price';

interface Props {
  amount: number;
}

export const SolutionListGroup: React.FC<React.PropsWithChildren<Props>> = ({ amount, children }) => (
  <div className="flex items-center justify-between rounded bg-[#D6F2FF] py-1 px-2">
    <h2 className="font-medium">{children}</h2>

    <div>
      <FormattedMessage
        id={translations.pages.workDetail.offer.subtotal}
        values={{
          amount: (
            <span className="font-medium">
              <Price {...{ amount }} currency="RON" />
            </span>
          )
        }}
      />
    </div>
  </div>
);
