import React from 'react';

import { Modal, useModal } from '@/components/Modal';
import { UpdateClientModal } from '@/modals/UpdateClientModal';

import { ClientList } from './ClientList';

export const ClientListPage: React.FC = () => {
  const updateModal = useModal(UpdateClientModal);

  return (
    <React.Fragment>
      <ClientList
        onCreate={() => updateModal.open({}).catch(() => null)}
        onSelect={(client) => updateModal.open({ clientId: client.id }).catch(() => null)}
      />

      <Modal {...updateModal.props} />
    </React.Fragment>
  );
};
