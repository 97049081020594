import {
  ApprovalStatus,
  ChapterKind,
  ChapterState,
  ChapterStateRequest,
  OfferApprovalDetails,
  OfferChapter,
  OfferChapterRequest,
  ProblemFeedback,
  Solution,
  TaleStatus,
  WorkItem,
  WorkType
} from '@binhatch/servicetale-api';
import { Tab } from '@headlessui/react';
import { PlusIcon } from '@heroicons/react/20/solid';
import { BellIcon, TagIcon, XCircleIcon } from '@heroicons/react/24/outline';
import React from 'react';
import { Field } from 'react-final-form';
import { FormattedMessage } from 'react-intl';
import * as yup from 'yup';

import { taleApi } from '@/integrations/api';
import { translations } from '@/locales';
import { replaceItemAtIndex } from '@/utils/array';

import { AsyncButton } from '@/components/AsyncButton';
import { Button } from '@/components/Button';
import { Form } from '@/components/Form';
import { Checkbox } from '@/components/Form/Checkbox';
import { Modal, useModal } from '@/components/Modal';
import { StatefulContainer } from '@/components/StatefulContainer';
import { DeleteConfirmationModal } from '@/modals/DeleteConfirmationModal';

import { Price } from '@/components/Price';
import { PriceWithVAT } from '@/components/PriceWithVAT';
import { useWorkshopId } from '@/hooks/useWorkshopId';
import { getPartSubtotal, getTimeSubtotal } from '@/utils/price';
import { Autosave } from './Autosave';
import { CarImageTile } from './CarImageTile';
import { Card } from './Card';
import { ConfirmCompleteModal } from './ConfirmCompleteModal';
import { OfferSolutionTab } from './OfferSolutionTab';
import { OfferTab } from './OfferTab';
import { OfferTabPanel } from './OfferTabPanel';
import { PartList } from './PartList';
import { ProblemFeedbackItem } from './ProblemFeedbackItem';
import { RejectWorkModal } from './RejectWorkModal';
import { SolutionListGroup } from './SolutionListGroup';
import { TimeList } from './TimeList';
import { WorkHeading } from './WorkHeading';
import { WorkItemIndex } from './WorkItemIndex';
import { WorkItemListItem } from './WorkItemListItem';

const requiredSchema = yup
  .object({
    problems: yup
      .array(
        yup.object({
          item: yup.mixed<WorkItem>().required(),
          type: yup.mixed<WorkType>().required(),
          solutions: yup
            .array()
            .of(yup.mixed<Solution>().required())
            .min(1)
            .test({ test: (value) => !!value?.every((s) => s && s.time.length + s.parts.length > 0) })
            .required()
        })
      )
      .min(1)
      .required()
  })
  .required();

const schema = yup
  .object({
    problems: yup
      .array(
        yup.object({
          item: yup.mixed<WorkItem>().required(),
          solutions: yup.array().of(yup.mixed<Solution>().required()).min(1).required(),
          feedback: yup.mixed<ProblemFeedback>()
        })
      )
      .min(1)
      .required()
  })
  .required();

interface TaleChapter extends OfferChapter {
  kind: (typeof ChapterKind)['Offer'];
}

interface Props {
  taleId: string;
  chapter: TaleChapter;
  loading?: boolean;
  readOnly: boolean;
  canProceed: boolean;
  onReload(): Promise<void>;
}

const convertFormToChapter = ({ problems }: yup.InferType<typeof schema>): OfferChapterRequest => ({
  kind: ChapterKind.Offer,
  state: ChapterStateRequest.Draft,
  problems
});

export const OfferForm: React.FC<React.PropsWithChildren<Props>> = ({
  taleId,
  chapter,
  readOnly,
  loading,
  canProceed,
  children,
  onReload
}) => {
  const workshopId = useWorkshopId();

  const deleteModal = useModal(DeleteConfirmationModal);
  const rejectModal = useModal(RejectWorkModal);
  const confirmModal = useModal(ConfirmCompleteModal);

  const initialValues = React.useMemo<yup.InferType<typeof schema>>(
    () => ({
      problems:
        chapter.problems.map((p) => ({
          ...p,
          feedback: p.feedback ?? undefined,
          type: p.item.type ?? 'requested',
          solutions: p.solutions.length === 0 ? [{ time: [], parts: [], recommended: false }] : p.solutions
        })) ?? []
    }),
    [chapter]
  );

  const onSubmit = React.useCallback(
    async (values: yup.InferType<typeof schema>) => {
      await taleApi.updateTaleChapter(taleId, { ...convertFormToChapter(values), kind: ChapterKind.Offer });
    },
    [taleId]
  );

  return (
    <React.Fragment>
      <Form {...{ schema, initialValues, onSubmit }}>
        {({ values, submitting, handleSubmit, form }) => (
          <React.Fragment>
            <WorkHeading loading={loading || submitting}>
              {chapter.state === ChapterState.Pending && !!workshopId && requiredSchema.isValidSync(values) && (
                <Button
                  appearance="primary"
                  className="h-10 px-4"
                  type="button"
                  onClick={() => {
                    confirmModal
                      .open({
                        ...translations.pages.workDetail.confirmOfferModal,
                        callback: async () => {
                          await taleApi.updateTaleChapter(taleId, {
                            ...convertFormToChapter(values),
                            kind: ChapterKind.Offer,
                            state: ChapterStateRequest.Final
                          });

                          await onReload();
                        }
                      })
                      .catch(() => null);
                  }}
                >
                  <BellIcon className="mr-2 h-4 w-4" />
                  <FormattedMessage id={translations.pages.workDetail.cta.offer} />
                </Button>
              )}
            </WorkHeading>

            {children}

            <form className="m-0 grid grid-cols-2 gap-6" onSubmit={handleSubmit}>
              {!readOnly && <Autosave />}

              {chapter.state === ChapterState.WaitingUserIntervention && !!workshopId && (
                <div className="col-span-full flex items-center justify-between rounded-lg bg-yellow-200 p-4 font-medium">
                  <div className="flex items-center">
                    <TagIcon className="mr-2 h-6 w-6 shrink-0" />
                    <FormattedMessage id={translations.pages.workDetail.offer.sent.title} />
                  </div>

                  <AsyncButton
                    appearance="transparent"
                    className="h-10 px-4 text-current"
                    type="button"
                    onClick={() => taleApi.notifyTaleSubscribers(taleId)}
                  >
                    <FormattedMessage id={translations.pages.workDetail.offer.sent.resend} />
                  </AsyncButton>
                </div>
              )}

              {chapter.userApproval === ApprovalStatus.Rejected && !!canProceed && !!workshopId && (
                <Card className="col-span-full flex flex-col items-center justify-center space-y-4">
                  <div className="flex flex-col items-center">
                    <XCircleIcon className="mb-4 h-20 w-20 text-[#BD0D0D]" />
                    <FormattedMessage id={translations.pages.workDetail.offer.rejected.title} />
                  </div>

                  <div className="flex gap-2">
                    <AsyncButton
                      appearance="primary"
                      className="h-10 px-4"
                      type="button"
                      onClick={async () => {
                        await taleApi.copyTaleChapter(taleId);

                        await onReload();
                      }}
                    >
                      <FormattedMessage id={translations.pages.workDetail.offer.rejected.continue} />
                    </AsyncButton>

                    <AsyncButton
                      appearance="outline"
                      className="h-10 px-4"
                      type="button"
                      onClick={async () => {
                        await taleApi.updateTale(taleId, { status: TaleStatus.Refused });

                        await onReload();
                      }}
                    >
                      <FormattedMessage id={translations.pages.workDetail.offer.rejected.close} />
                    </AsyncButton>
                  </div>
                </Card>
              )}

              {chapter.state === ChapterState.WaitingUserIntervention &&
                !workshopId &&
                chapter.problems.some((p) => p.solutions.some((s) => s.recommended)) && (
                  <Card className="col-span-full items-center gap-8 md:flex">
                    <div className="flex-1 items-center md:flex md:justify-between">
                      <h2 className="font-medium">
                        <FormattedMessage id={translations.pages.workDetail.offer.accept.recommended.title} />
                      </h2>

                      <PriceWithVAT
                        amount={values.problems.reduce(
                          (t, p) =>
                            t +
                            p.solutions
                              .filter((s) => s.recommended)
                              .reduce((t, s) => t + getTimeSubtotal(s.time) + getPartSubtotal(s.parts), t),
                          0
                        )}
                        currency="RON"
                      />
                    </div>

                    <Button
                      appearance="outline"
                      className="h-10 w-full px-4 text-current"
                      type="button"
                      onClick={() => {
                        form.change(
                          'problems',
                          values.problems.map((p) => {
                            const selectedSolution = p.solutions.find((s) => s.recommended)?.id;
                            const feedback = selectedSolution ? { selectedSolution } : p.feedback;

                            return { ...p, feedback };
                          })
                        );
                      }}
                    >
                      <FormattedMessage id={translations.pages.workDetail.offer.accept.recommended.accept} />
                    </Button>
                  </Card>
                )}

              <Card className="col-span-2 space-y-4">
                <h2 className="font-medium">
                  <FormattedMessage id={translations.pages.workDetail.findings.title} />
                </h2>

                <Field name="problems" render={() => null} />

                {!!values.problems?.length && (
                  <ol className="grid gap-6">
                    {values.problems?.map(({ item, solutions, feedback }, problemIndex) => (
                      <StatefulContainer key={problemIndex} state={0}>
                        {({ state, setState }) => (
                          <li className="group flex gap-2">
                            <div className="flex-1 space-y-4">
                              <div>
                                <WorkItemIndex>{problemIndex + 1}</WorkItemIndex>

                                <WorkItemListItem {...{ item }} />

                                {!!feedback && <ProblemFeedbackItem {...{ solutions, feedback }} />}
                              </div>

                              {!!item.media?.length && (
                                <ul className="flex flex-wrap gap-4">
                                  {item.media?.map((media, index) => (
                                    <li key={index}>
                                      <CarImageTile className="w-16" src={media} />
                                    </li>
                                  ))}
                                </ul>
                              )}

                              <div>
                                <Tab.Group selectedIndex={state} onChange={setState}>
                                  <Tab.List className="flex gap-2">
                                    {solutions.map((solution, solutionIndex) => (
                                      <Tab as={React.Fragment} key={solutionIndex}>
                                        {({ selected }) => (
                                          <OfferSolutionTab
                                            {...{ selected }}
                                            recommended={solution.recommended}
                                            total={getTimeSubtotal(solution.time) + getPartSubtotal(solution.parts)}
                                            onClick={() => setState(solutionIndex)}
                                          >
                                            <FormattedMessage
                                              id={translations.pages.workDetail.offer.solution.name}
                                              values={{ index: solutionIndex + 1 }}
                                            />
                                          </OfferSolutionTab>
                                        )}
                                      </Tab>
                                    ))}

                                    {chapter.state === ChapterState.WaitingUserIntervention && !workshopId && (
                                      <OfferTab
                                        className="font-medium"
                                        onClick={() => {
                                          rejectModal
                                            .open({})
                                            .then(async ({ reason, comment }) => {
                                              form.change(
                                                'problems',
                                                values.problems.map((p) => ({
                                                  ...p,
                                                  feedback:
                                                    p.item.id === item.id
                                                      ? { rejectionCause: reason, comments: comment }
                                                      : p.feedback
                                                }))
                                              );
                                            })
                                            .catch(() => undefined);
                                        }}
                                      >
                                        <FormattedMessage id={translations.pages.workDetail.offer.solution.reject} />
                                      </OfferTab>
                                    )}

                                    {!readOnly && (
                                      <OfferTab
                                        className="font-medium"
                                        onClick={() => {
                                          const problems = values.problems.slice();

                                          problems[problemIndex].solutions = [
                                            ...problems[problemIndex].solutions,
                                            { time: [], parts: [], recommended: false }
                                          ];

                                          form.change('problems', problems);

                                          setState(problems[problemIndex].solutions.length - 1);
                                        }}
                                      >
                                        <PlusIcon className="mb-2 h-5 w-5" />
                                        <FormattedMessage id={translations.pages.workDetail.offer.solution.create} />
                                      </OfferTab>
                                    )}
                                  </Tab.List>

                                  <Tab.Panels as={OfferTabPanel} first={state === 0}>
                                    {solutions.map((solution, solutionIndex) => (
                                      <Tab.Panel className="space-y-4" key={solutionIndex}>
                                        {chapter.state === ChapterState.WaitingUserIntervention &&
                                          !workshopId &&
                                          feedback?.selectedSolution !== solution.id && (
                                            <div className="flex justify-end">
                                              <Button
                                                appearance="primary"
                                                className="h-10 px-4"
                                                type="button"
                                                onClick={() => {
                                                  form.change(
                                                    'problems',
                                                    values.problems.map((p) => ({
                                                      ...p,
                                                      feedback:
                                                        p.item.id === item.id
                                                          ? { selectedSolution: solution.id! }
                                                          : p.feedback
                                                    }))
                                                  );
                                                }}
                                              >
                                                <FormattedMessage
                                                  id={translations.pages.workDetail.offer.solution.accept}
                                                />
                                              </Button>
                                            </div>
                                          )}

                                        {!readOnly && (
                                          <div className="flex justify-between">
                                            <Checkbox
                                              checked={solution.recommended}
                                              disabled={readOnly}
                                              id={`recommended-${problemIndex}-${solutionIndex}`}
                                              type="checkbox"
                                              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                const problems = values.problems.slice();

                                                problems[problemIndex].solutions = problems[problemIndex].solutions.map(
                                                  (s) => ({ ...s, recommended: event.target.checked && s === solution })
                                                );
                                                form.change('problems', problems);

                                                setState(
                                                  Math.min(solutionIndex, problems[problemIndex].solutions.length - 1)
                                                );
                                              }}
                                            >
                                              <FormattedMessage id={translations.fields.recommended.label} />
                                              <div className="text-sm">
                                                <FormattedMessage id={translations.fields.recommended.placeholder} />
                                              </div>
                                            </Checkbox>

                                            <Button
                                              appearance="outline"
                                              className="h-10 px-4"
                                              type="button"
                                              onClick={() => {
                                                deleteModal
                                                  .open({
                                                    callback: async () => {
                                                      const problems = values.problems.slice();

                                                      problems[problemIndex].solutions = replaceItemAtIndex(
                                                        problems[problemIndex].solutions,
                                                        solutionIndex
                                                      );

                                                      form.change('problems', problems);
                                                    }
                                                  })
                                                  .catch(() => null);
                                              }}
                                            >
                                              <FormattedMessage id={translations.buttons.delete} />
                                            </Button>
                                          </div>
                                        )}

                                        {(!!solution.time.length || !!workshopId) && (
                                          <SolutionListGroup amount={getTimeSubtotal(solution.time)}>
                                            <FormattedMessage
                                              id={translations.pages.workDetail.offer.time.title}
                                              values={{ count: solution.time.length }}
                                            />
                                          </SolutionListGroup>
                                        )}

                                        <TimeList
                                          {...{ readOnly }}
                                          times={solution.time}
                                          onCreate={(time) => {
                                            const problems = values.problems.slice();

                                            problems[problemIndex].solutions[solutionIndex].time = [
                                              ...solution.time,
                                              time
                                            ];

                                            form.change('problems', problems);
                                          }}
                                          onUpdate={(time, index) => {
                                            const problems = values.problems.slice();

                                            problems[problemIndex].solutions[solutionIndex].time = replaceItemAtIndex(
                                              problems[problemIndex].solutions[solutionIndex].time,
                                              index,
                                              time
                                            );

                                            form.change('problems', problems);
                                          }}
                                        />

                                        {(!!solution.parts.length || !!workshopId) && (
                                          <SolutionListGroup amount={getPartSubtotal(solution.parts)}>
                                            <FormattedMessage
                                              id={translations.pages.workDetail.offer.part.title}
                                              values={{ count: solution.parts.length }}
                                            />
                                          </SolutionListGroup>
                                        )}

                                        <PartList
                                          {...{ readOnly }}
                                          parts={solution.parts}
                                          onCreate={(part) => {
                                            const problems = values.problems.slice();

                                            problems[problemIndex].solutions[solutionIndex].parts = [
                                              ...solution.parts,
                                              part
                                            ];

                                            form.change('problems', problems);
                                          }}
                                          onUpdate={(part, index) => {
                                            const problems = values.problems.slice();

                                            problems[problemIndex].solutions[solutionIndex].parts = replaceItemAtIndex(
                                              problems[problemIndex].solutions[solutionIndex].parts,
                                              index,
                                              part
                                            );

                                            form.change('problems', problems);
                                          }}
                                        />

                                        <div className="border-t-2 border-gray-200 p-2 text-right">
                                          <FormattedMessage
                                            id={translations.pages.workDetail.offer.solution.total}
                                            values={{
                                              price: (
                                                <span className="font-medium">
                                                  <Price
                                                    amount={
                                                      getPartSubtotal(solution.parts) + getTimeSubtotal(solution.time)
                                                    }
                                                    currency="RON"
                                                  />
                                                </span>
                                              )
                                            }}
                                          />
                                        </div>
                                      </Tab.Panel>
                                    ))}
                                  </Tab.Panels>
                                </Tab.Group>
                              </div>
                            </div>
                          </li>
                        )}
                      </StatefulContainer>
                    ))}
                  </ol>
                )}
              </Card>
            </form>

            {!workshopId && chapter.state === ChapterState.WaitingUserIntervention && (
              <Card className="sticky bottom-0 flex flex-col gap-2 border border-gray-100 shadow md:gap-8">
                <div className="flex flex-1 justify-between">
                  <FormattedMessage id={translations.pages.workDetail.offer.accept.banner.total} />

                  <PriceWithVAT
                    amount={values.problems
                      .map(({ feedback, solutions }) => solutions.find((s) => s.id === feedback?.selectedSolution))
                      .filter((s): s is Solution => !!s)
                      .reduce((t, s) => t + getTimeSubtotal(s.time) + getPartSubtotal(s.parts), 0)}
                    currency="RON"
                  />
                </div>

                <AsyncButton
                  appearance="primary"
                  className="h-10 px-4"
                  disabled={values.problems.some((p) => !p.feedback)}
                  type="button"
                  onClick={async () => {
                    await taleApi.approveTaleChapterPublic(taleId, {
                      status: values.problems.some((p) => p.feedback?.rejectionCause)
                        ? ApprovalStatus.Rejected
                        : ApprovalStatus.Approved,
                      details: {
                        problems: values.problems.reduce<OfferApprovalDetails['problems']>(
                          (data, { item, feedback }) => {
                            if (!item.id || !feedback) return data;

                            data[item.id] = {
                              solution: feedback.selectedSolution,
                              rejectionCause: feedback.rejectionCause,
                              comments: feedback.comments
                            };

                            return data;
                          },
                          {}
                        )
                      }
                    });

                    await onReload();
                  }}
                >
                  <FormattedMessage id={translations.pages.workDetail.offer.accept.banner.accept} />
                </AsyncButton>
              </Card>
            )}
          </React.Fragment>
        )}
      </Form>

      <Modal {...deleteModal.props} />
      <Modal {...rejectModal.props} />
      <Modal {...confirmModal.props} />
    </React.Fragment>
  );
};
